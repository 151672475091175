import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Container, Link, Box} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import TitleBarWhite from './Layouts/TitleBarWhite';

export default function TermOfUse() {
    const { t} = useTranslation();

    return (
        <Box>
            <div style={{position:'fixed', width:600, maxWidth:'100%'}}>
                <TitleBarWhite height={0} title={t('support.privacyAgreement')} back  displayInfo={false} />
            </div>
            <div style={{ textAlign: 'center', padding: '60px 0 0' }}>
                <Typography style={{ fontSize: '28px', color: '#777' }}>{t('support.privacyAgreement')}</Typography>
            </div>

            <div style={{ backgroundColor: '#fff', padding: '5px 0', color: '#666', lineHeight: '1.7' }}>
                <Container>
                    <p>Atoms Wellness(also known as "Mose Net System Solution Sdn Bhd") and its affiliates (individually and collectively, "Atoms Wellness", "we", "us" or "our") take our responsibilities under applicable privacy laws and regulations ("Privacy Laws") seriously and is committed to complying with all data protection/privacy laws which are applicable to us. This privacy policy ("Privacy Policy") shall govern the usage by any users of website or applications ("Website") of AtomsWellness.com. References to this Privacy Policy shall include all subsequent variation and amendments made thereto.</p>
                    <p>By accessing and using the Website, you hereby consent to the processing of your personal data (if so provided by you) in accordance with the terms of set out in this Privacy Policy and the following:</p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>a.	the <Link underline='none' to="/termOfUse" component={RouterLink} >Terms of Use</Link>;</li>
                        <li>c.	the <Link underline='none' to="/refundAndReturnAgreement" component={RouterLink} >Refund and Return Policy</Link>; and</li>
                        <li>d.	the <Link underline='none' to="/merchantServiceAgreement" component={RouterLink} >Merchant Services Agreement</Link>;</li>
                    </ul>
                    <p>(collectively, "Terms"). </p>

                    <p><b style={{ color: '#333' }}>General Information</b><br/>
                    AtomsWellness protects your personal information by complying with the operative legislation concerning personal data protection in Malaysia. This Privacy Policy details how AtomsWellness collects and uses your personal data, and informs you about the personal data protection measures we have put in place. When you use the Website, you consent to the collection, transfer, manipulation, storage, disclosure and other uses of your information in the manner described in this Privacy Policy.</p>

                    <p><b style={{ color: '#333' }}>What Information Do We Collect About You?</b><br/>
                    Unless you have registered an account ("AtomsWellness Account"); whether as a seller, or buyer; with us, your personal identity remain anonymous when you visit our Website.</p>
                    <p>Atoms Wellness collects personal information from you only when you choose to register for an Atoms Wellness Account along with the information we learn from your usage of the Website. The personal information may include your personal identification details such as your name, contact number and other information which identifies you. We may also collect information about you when you contact us whether by way of email, telephone or other means of communication including social media.</p>
                    <p>By volunteering personal information, you undertake to communicate accurate information that does not prejudice our interest or the interest of any third parties.</p>
                    <p>Personal information that we collected will only be disclosed within our corporate groups and to third parties for the purposes for which it was collected, as authorised and consented by you. For example, the conduct of a sales transaction of a product of your choice on our Website. For more details, please refer below:</p>
                    <ul style={{listStyle:'none'}}>
                        <li>A.	<b style={{color:'#333'}}>Upon Registration (Seller)</b>  
                            <ul style={{listStyle:'none'}}>
                                <li>a.	Your Name</li>
                                <li>b.	Your Email</li>
                                <li>c.	Mobile Number</li>
                                <li>d.	Business Name</li>
                                <li>e.	Business Registration No.</li>
                                <li>f.	Business Address</li>
                            </ul>
                        </li>

                        <li style={{marginTop:'10px'}}>B.	<b style={{color:'#333'}}>Upon a Purchase and/or Transaction on the Website, we may collect additional information:</b>  
                            <ul style={{listStyle:'none'}}>
                                <li>a.	Telephone Number</li>
                                <li>b.	Delivery Information</li>
                                <li>c.	Payment Methodology</li>
                            </ul>
                        </li>
                    </ul>
                    <p>The list of personal data stated above is not exhaustive and may include other personal data depending on the nature of dealings or transaction. The request for these other personal data will be indicated on the relevant form that you need to fill in, depending on the nature of the dealings or transactions, and for which you would need to give consent to prior to us processing the same.</p>
                    <p>By submitting information to us and registering for an Atoms Wellness Account, you are agreeing to us collecting, using, processing and disclosing your personal data in accordance with this Privacy Policy.</p>

                    <p><b style={{ color: '#333' }}>How Do We Use the Information?</b><br/>
                    Your personal information that Atoms Wellness collects allows us to process the necessary transaction in relation to Atoms Wellness. Upon giving us your consent, we may collect, use, disclose and otherwise process your personal data for the following purposes, depending on the nature of our relationship with you:</p>
                    <ul style={{listStyle:'none'}}>
                        <li>•	To verify your identity;</li>
                        <li>•	To verify your eligibility to register as a user of the Website;</li>
                        <li>•	To process your registration as a user, providing you with a log-in ID for the Website and maintaining and managing your registration;</li>
                        <li>•	To providing you with customer service and responding to your queries, feedback, claims or disputes;</li>
                        <li>•	To facilitate communication between buyers and sellers on the Website;</li>
                        <li>•	To perform research or statistical analysis in order to improve the content and layout of the Website, to improve our product offerings and services and for marketing and promotional purposes;</li>
                        <li>•	To optimize and customize your user experience with our Website;</li>
                        <li>•	We may use your name, phone number, residential address, email address and fax number ("Marketing Data") to provide notices, surveys, product alerts, communications and other marketing materials to you relating to goods and services offered by us on the Website from time to time to members of the Website;</li>
                        <li>•	to provide our products and services to you;</li>
                        <li>•	to communicate with you;</li>
                        <li>•	to verify your identity for purposes of receiving our products and services;</li>
                        <li>•	to notify you of any changes to our Terms or the products and services offered to you;</li>
                        <li>•	For complying with any applicable rules, laws and regulations, codes of practice or guidelines or to assist in law enforcement and investigations by relevant authorities</li>
                    </ul>
                    <p>All provision of personal information is on a voluntary basis. However, if you decide not to provide us with any of your personal information, our ability to deliver optimised user experience service to you may be affected. In addition, failure to provide certain essential personal data may result in us being unable to provide you with the services and/or products that you have opted for.</p>

                    <p><b style={{ color: '#333' }}>Disclosure and Safeguarding the Information</b><br/>
                    All information collected by Atoms Wellness will be generally be kept confidential but you hereby consent and authorize us to provide or disclose your personal data to the following categories of parties:</p>
                    <ul style={{listStyle:'none'}}>
                        <li>•	any person or authority to whom we are compelled or required to do so under law, court order or in response to a competent or government agency;</li>
                        <li>•	our business partners and online affiliates that provide the services that you have opted for or related services or products in connection with the Website;</li>
                        <li>•	members of our group companies and their affiliates;</li>
                        <li>•	government agencies and industry regulators;</li>
                        <li>•	our auditors, consultants, accountants, lawyers or other financial or professional advisers; and</li>
                        <li>•	such sub-contractors or third-party service or product providers as we may determine to be necessary or appropriate.</li>
                    </ul>

                    <p><b style={{ color: '#333' }}>Retention of Personal Data</b><br/>
                    Collected information is retained for the period taken to fulfil the many different purposes outlined in this Privacy Policy unless a longer retention period is permitted or required by law. During this period, Atoms Wellness is committed to all reasonable efforts and practical steps to ensure that collected data is protected against any loss, misuse, modification, unauthorized or accidental access or disclosure, alteration or destruction. We undertake to protect the confidentiality of your data to the purposes set out in this policy, and therefore to ensure your data privacy. We will ensure that your personal data is permanently deleted once it is no longer required to fulfil its original purpose and any other ancillary purpose as you have consented to in this policy, or as is required by the law.</p>

                    <p><b style={{ color: '#333' }}>Collection of Computer Data not necessarily Personal Information</b><br/>
                    When you visit AtomsWellness.com, our company servers will automatically record information that your computer browser sends whenever you visit the Website. The information may include:</p>
                    <ul style={{listStyle:'none'}}>
                        <li>•	Your computer's IP address</li>
                        <li>•	Browser type</li>
                        <li>•	Webpage you were visiting before you came to our Website</li>
                        <li>•	The pages within AtomsWellness.com that you visit</li>
                        <li>•	The time spent on those pages, items and information searched for on our site, access times and dates, and other statistics</li>
                        <li>•	This information is collected for analysis and evaluation in order to help us improve our Website and the services and products we provide. This information will not be used in association with any other personal information.</li>
                    </ul>

                    <p><b style={{ color: '#333' }}>Cookies</b><br/>
                    To enhance your experience with our Website, some of our web pages may contain cookies ("Cookies"). Cookies are small text files which we place in your computer's browser to store your preferences. Cookies, by themselves, do not tell us your e-mail address or other personally identifiable information unless you choose to provide this information to us. For example, you provide such information by registering on our Website. However, once you choose to supply this site with personally identifiable information, this information may be linked to the data stored in the cookie. We use cookies to understand site usage and to improve the content and offerings on our site to you. For example, we may use cookies to personalize your experience at our web pages (e.g. to recognize you by name when you return to our site). We also may use cookies to offer you relevant products, programs, and/or services.</p>

                    <p><b style={{ color: '#333' }}>Changes to the Privacy Policy</b><br/>
                    Atoms Wellness reserves the right to modify and change the Privacy Policy at any time. Any changes to this Privacy Policy will be published on our Website. Any such modification will be effective upon our posting of the revised Privacy Policy on the Website. You understand and agree that your continued access to or use of the Website after the effective date of modifications to the Privacy Policy will constitute your agreement and acceptance of the modified and changed Privacy Policy, and your use of the Website will be bound by such modified and changed policy.</p>

                    <p><b style={{ color: '#333' }}>Minors & Age Consent</b><br/>
                    The Website and its contents are not targeted to minors (those under the age of 18) and we do not intend to provide any of our products or services to minors. We do not knowingly collect any personal information relating to minors.</p>
                    <p>As a parent or legal guardian, please do not allow minors under your care to submit personal information to Atoms Wellness. In the event that such personal information of a minor is disclosed to Atoms Wellness, you hereby consent to the processing of the minor's personal data and accept and agree to be bound by this Privacy Policy and take responsibility for his or her actions.</p>
                    <p>We will not be responsible for any unauthorised use of the services on the Website by yourself, users who act on your behalf or any unauthorized users. If a minor has provided us with personal information without parental or guardian consent, the parent or guardian should immediately contact our Legal Department at the <Link>support@atomswellness.com</Link>.</p>

                    <p><b style={{ color: '#333' }}>Security Measures</b><br/>
                    For registered users of the Website, your registration information and account information (if any) can be viewed and edited through your account, which is protected by a password. Please do not divulge your password to anyone. Our personnel will never ask you for your password in an unsolicited phone call or email. If you share a computer with others, you should not choose to save your log-in information (e.g., user ID and password) on that shared computer.</p>
                    <p>Remember to sign out of your account and close your browser window when you have finished your session. No data transmission over the internet or any wireless network can be guaranteed to be perfectly secure. As a result, while we try to protect the information we hold for you, we cannot guarantee the security of any information you transmit to us and you do so at your own risk.</p>

                    <p><b style={{ color: '#333' }}>Prohibited Items</b><br/>
                    Regardless of whether you are a seller or a buyer, you and/or your company are not allowed to purchase, sell, trade and to do with anything in relation to any product that is restricted and/or prohibited by a federal, state or local law in any country or jurisdiction. Products (including but not limited to) those that below, will not be listed in our Website:</p>
                    <ul style={{listStyle:'none'}}>
                        <li>•	Products that promote hatred, violence, racial or religious intolerance</li>
                        <li>•	Drugs and narcotics</li>
                        <li>•	Medicine and prescription based drugs</li>
                        <li>•	Human parts, organs and related products</li>
                        <li>•	Animals and animal parts and related products</li>
                        <li>•	Pornographic content, products and material</li>
                        <li>•	Weapons, guns, arms, firearms, military arms, ammunition and related products</li>
                        <li>•	Hazardous and dangerous material</li>
                        <li>•	Stocks, currencies and securities</li>
                        <li>•	Transfer from one person to another of personal credit and debit and any type of payment card details</li>
                        <li>•	Fake IDs</li>
                        <li>•	Counterfeit products</li>
                        <li>•	Software (unless owner of the software is authorized to do so).</li>
                    </ul>
                    <p>This list stated above is not exhaustive and Atoms Wellness in its sole and exclusive discretion, reserves the right to impose additional restrictions and prohibitions.</p>

                    <p><b style={{ color: '#333' }}>Third Party Information</b><br/>
                    In respect of personal data of third parties such as your spouse and/or any of your family members and/or the directors and/or shareholders of your company that you have provided to us, you warrant that you have obtained their consent allowing us to process their personal data and you will advise them with regards to our Privacy Policy.</p>

                    <p><b style={{ color: '#333' }}>Right to Access and Correction of Personal Data</b><br/>
                    We want to ensure that the information we have is accurate and up to date for us in order for it to be consistent with the purposes for which the information was collected. All users of the Website, including you, have the right to access, update or correct any information by updating your registration details on the Website or writing to us at <Link>support@atomswellness.com</Link>.</p>
                    <p>If you have any queries, complaints or otherwise relating to the misuse or suspected misuse of your information, you may contact us at the above mentioned email address.</p>
                    

                    
                </Container>
            </div>
        </Box>
    );
}


