import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useNotificationLoading from '../helper/useNotificationLoading';
import _ from 'lodash';

import { makeStyles, useTheme, createMuiTheme, ThemeProvider, FormControlLabel, FormControl, Radio, RadioGroup, Checkbox, Typography,  Avatar, Link, Box, Accordion, AccordionDetails, AccordionSummary  } from '@material-ui/core';
import { PersonAdd, PersonOutlineRounded, MeetingRoomOutlined, PublicOutlined, ExpandMoreOutlined, NotificationsNoneRounded,  LinkOutlined, ChevronRightSharp, CheckCircle, AccountBalanceOutlined, AssignmentInd, ContactMailOutlined, LocalMall, AssignmentRounded, HomeWorkRounded, LinkRounded, AccountTreeOutlined } from '@material-ui/icons';
import { FiChevronRight, FiKey, FiPhone } from 'react-icons/fi';
import { FaTruck, FaUserEdit, FaDoorOpen, FaBoxOpen } from 'react-icons/fa';
import {  BsInfoLg,BsFillFileTextFill } from 'react-icons/bs';
import { AiFillFileText, AiOutlineFileText} from 'react-icons/ai';
import {  MdCall, MdLocationOn} from 'react-icons/md';

import { changeLanguage as reduxChangeLanguage } from '../actions';
import { getUrl, logoutUser } from '../helper/ApiAction';
import TitleBar from './Layouts/TitleBar';
import theme from '../Theme';
  
export default function Setting() {
    const { t, i18n } = useTranslation();
    const [valueLanguage, setLanguage] = useState(i18n.language);

    const { accessToken } = useSelector(state => state.general);
    const { fr_id, max_rank } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = useStyles();
    const history = useHistory();

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    const goToIshopping = () => {
        if(accessToken){
            getUrl(`/login-to/ishopping`).then(response => {
                setLoading(false);
                if(response.status === 1 && response.url){
                    window.open(response.url, '_blank').focus();
                }else{
                    addAlert(t('general.loginRedirectError'));
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    return (

        <Box paddingBottom={5} style={{background: theme.palette.white.mobileBkg, paddingBottom:80, minHeight: '100%' }}>{/* className={styles.root} style={{ padding: '0 0 80px',}} */}
            <TitleBar height="99px" title={t('profile.settings')} displayQr/>

                {/* //Account */}
                <Box style={{padding:'18px 15px 0'}}>
                    <Typography style={{paddingBottom:15, color:theme.palette.silver.text}}>{t('title.account')}</Typography>
                    {
                        <Link underline='none' to="/recruit" component={RouterLink} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <PersonAdd fontSize="default" />
                                    </Avatar>
                                    <Typography style={{color:theme.palette.silver.text}}>{t('profile.recruit')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Link>
                    }
                    {/* <Box onClick={() => goToIshopping()} >
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <LocalMall fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.goToIshopping')}</Typography>
                            </div>
                            <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                        </Box>
                    </Box> */}
                    {/* <Link underline='none' to="/profile-linking" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <LinkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.accountLink')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}
                    <Link underline='none' to="/order" component={RouterLink} >
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    {/* <AssignmentRounded fontSize="default" /> */}
                                    <AssignmentRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('title.order')}</Typography>
                            </div>
                            <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                        </Box>
                    </Link>
                    {/* {
                        fr_id !=null && fr_id?
                        null:
                        <Link underline='none' to="/bindSubAccount" component={RouterLink} >
                             <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                                <div style={{display:'flex', alignItems:'center',}}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <LinkOutlined fontSize="default" />
                                    </Avatar>
                                    <Typography style={{color:theme.palette.silver.text}}>{t('profile.bindSubAccount')}</Typography>
                                </div>
                                <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                            </Box>
                        </Link>
                    } */}
                    {/* <Link underline='none' to="/kyc" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <AssignmentInd fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('title.kyc')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}
                </Box>

                {/* //Profile */}
                <Box style={{padding:'18px 15px 0', color:theme.palette.silver.text}}>
                    <Typography style={{paddingBottom:15}}>{t('profile.profile')}</Typography>
                    <Link underline='none' to="/profile" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <FaUserEdit fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.editProfile')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                    <Link underline='none' to="/placement-setting" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <AccountTreeOutlined fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.placementSetting')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                    <Link underline='none' to="/profile-bank-info" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <AccountBalanceOutlined fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.editBankInfo')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                    <Link underline='none' to="/profile-password" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <FiKey fontSize="default" style={{width:21}}/>
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.changePassword')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                    <Link underline='none' to="/profile-security-password" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <FiKey fontSize="default" style={{width:21}}/>
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.changeSecurityPassword')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                    <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    {/* <HomeWorkRounded fontSize="default" /> */}
                                    <MdLocationOn fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.address')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                </Box>
                
                {/* //Annoucements */}
                {/* <Box style={{padding:'18px 15px 0', color:theme.palette.silver.text}}>
                    <Typography style={{paddingBottom:15}}>{t('title.announcements')}</Typography>

                   
                    <Link underline='none' to="/announcement" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <NotificationsNoneRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('title.announcements')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                  
                </Box> */}

                {/* //Support */}
                <Box style={{padding:'18px 15px 0', color:theme.palette.silver.text}}>
                    <Typography style={{paddingBottom:15}}>{t('support.support')}</Typography>  
                    <Link underline='none' to="/policies" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <BsFillFileTextFill fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.policies')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>         
                    {/* <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.tof')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>

                    <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.refundAgreement')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>

                    <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.privacyAgreement')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>

                    <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.merchantServicesAgreement')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}

                    <Link underline='none' to="/contactUs" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <MdCall fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.contactUs')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
             
                </Box>
         
                {/* //Setting */}
                <Box style={{padding:'18px 15px 0', color:theme.palette.silver.text}}>
                    <Typography style={{paddingBottom:15}}>{t('profile.settings')}</Typography>           
                
                <ThemeProvider theme={accordingStyles}>
                    <Accordion style={{ boxShadow: 'none', backgroundColor: 'transparent', padding: 0, margin: 0 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ padding: 0, margin: 0 }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <PublicOutlined fontSize="default" />
                                </Avatar>
                                <Typography style={{ color: theme.palette.silver.text }}>{t('home.language')}</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{padding:'8px 55px 16px',}}>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="language" name="language" value={valueLanguage}>
                                    
                                {/* <FormControlLabel
                                    value="end"
                                    label="English"
                                    control={<Checkbox color="primary" />}
                                    labelPlacement="end"
                                    onClick={() => changeLanguage('en')}
                                />
                                <FormControlLabel
                                    value="end"
                                    label="中文"
                                    control={<Checkbox color="primary" />}
                                    labelPlacement="end"
                                    onClick={() => changeLanguage('cn')}
                                /> */}
                                <FormControlLabel
                                    onChange={() => changeLanguage('en')}
                                    value="en"
                                    control={<Radio color="primary"  checkedIcon={<CheckCircle color="secondary" />}/>}
                                    label="English"
                                />
                                <FormControlLabel
                                    onChange={() => changeLanguage('cn')}
                                    value="cn"
                                    control={<Radio color="primary"  checkedIcon={<CheckCircle color="secondary" />} />}
                                    label="中文"
                                />
                                </RadioGroup>
                                
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>
                </ThemeProvider>
                {/* <Link underline='none' to="/contactUs" component={RouterLink} >
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                <ContactMailOutlined fontSize="default" />
                            </Avatar>
                            <Typography style={{ color: theme.palette.silver.text }}>{t('footer.contactUs')}</Typography>
                        </div>
                        <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                    </Box>
                </Link> */}

                    {
                accessToken ?
                    <Link underline='none'  component="button"  onClick={() => {
                        logoutUser(dispatch);
                        // handleMenuClose();
                        history.replace('/login');
                    }}
                    >
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                            <Box style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    {/* <MeetingRoomOutlined fontSize="default" /> */}
                                    <FaDoorOpen fontSize="default" />
                                    
                                </Avatar>
                                <Typography style={{ color: theme.palette.silver.text }}>{t('button.logout')}</Typography>
                            </Box>
                            {/* <FiChevronRight style={{color:theme.palette.secondary.main, marginLeft:15}}/> */}
                        </Box>
                    </Link>
                     :
                     <Link underline='none' to="/login" component={RouterLink} >
                     <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                            <Box style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <MeetingRoomOutlined fontSize="default" />
                                </Avatar>
                                <Typography style={{ color: theme.palette.silver.text }}>{t('button.login')}</Typography>
                            </Box>
                            {/* <FiChevronRight style={{color:theme.palette.secondary.main, marginLeft:15}}/> */}
                        </Box>
                        </Link>
                }
                </Box>
        </Box>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
       
    },
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
          backgroundColor: "transparent"
        }
      },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    iconColor: {
        padding: 5,
        // backgroundColor: theme.palette.profile.user,
        // backgroundColor:'#d7d7d7',
        backgroundColor:'#c9c9c9',
        color:"#fff"
    },
   

}));


  const accordingStyles = createMuiTheme({
    overrides: {
        MuiAccordion: {
            root: {
                '&::before':{
                    backgroundColor:'transparent'
                }
                
            }
        },
        MuiAccordionSummary:{
            content:{
                marginTop:0,
                "&$expanded":{
                    marginTop:0,
                    marginBottom:5,
                } 
            },  
            root:{
                "&$expanded":{
                    minHeight:'unset',
                } 
            },                  
        },
        MuiIconButton:{
            root:{
                color: theme.palette.secondary.main,
            }
            
        },
    }
});

