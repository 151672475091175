import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Container, Box } from '@material-ui/core';
import TitleBarWhite from './Layouts/TitleBarWhite';

export default function TermOfUse() {
    const { t } = useTranslation();

    return (
        <Box>
            <div style={{position:'fixed', width:600, maxWidth:'100%'}}>
                <TitleBarWhite height={0} title={t('support.refundAgreement')} back  displayInfo={false} />
            </div>
            <div style={{ textAlign: 'center', padding: '60px 0 0' }}>
                <Typography style={{ fontSize: '28px', color: '#777' }}>{t('support.refundAgreement')}</Typography>
            </div>

            <div style={{ backgroundColor: '#fff', padding: '5px 0', color: '#666', lineHeight: '1.7' }}>
                <Container>

                    <p style={{marginTop:'30px'}}><b style={{ color: '#333' }}>Application for Refund and/or Return of an Item</b><br/>Buyer may only apply for refund by and/or return of an item ("Item") to the Seller in the following circumstances:</p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>•	The Item has not been received by Buyer;</li>
                        <li>•	The Item received is incomplete (missing quantity or accessories);</li>
                        <li>•	Seller has delivered an Item that does not match the agreed specification (e.g. wrong size, colour, etc.) to Buyer;</li>
                        <li>•	The Item delivered to Buyer is materially different from the description provided by Seller in the listing of the Item;</li>
                        <li>•	The Item received has physical damage (e.g. dented, scratched, shattered);</li>
                        <li>•	The Item received is faulty (e.g. malfunction, does not work as intended);</li>
                        <li>•	By way of private agreement with Seller and Seller must send his/her confirmation to Atoms Wellness confirming such agreement;</li>
                    </ul>

                    <p>The Buyer's application must be submitted via the Atoms Wellness Website/Apps.</p>

                    <p>Atoms Wellness will review each Buyer's application on a case-by-case basis and, if Atoms Wellness deems necessary, Atoms Wellness may consult the Seller in the process of reviewing the application. Atoms Wellness shall in its sole discretion, determine whether the Buyer's application shall be successful. The Seller shall be fully responsible for all direct and/or indirect losses, claims, damages, costs and expenses successfully claimed by the Buyer arising from such application for refund and/or return of any Item.</p>

                    <p>In addition thereto, the Seller shall compensate, defend, indemnify and hold Atoms Wellness harmless from and against any losses which Atoms Wellness may suffer or incur, directly or indirectly arising out of or resulting from or in connection with such application for refund and/or return of an Item. In this regard, Atoms Wellness reserves all rights and entitlement to deduct and set-off the requisite sum of monies retained in the Seller's accounts maintained with Atoms Wellness.</p>

                    <p>Both the Buyer and the Seller accept and agree that Atoms Wellness is merely a platform through which the Buyer and Seller undertake commercial transactions between the Buyer and the Seller. Atoms Wellness is not and shall not be responsible for any products sold or purchased between the Buyer and the Seller. Atoms Wellness therefore shall not under any circumstances be responsible in any manner whatsoever for any losses, claims, damages, costs and expenses incurred or to be incurred by the Seller or the Buyer in connection with any application for refund and/or return of the Item.</p>

                    <p style={{marginTop:'30px'}}><b style={{ color: '#333' }}>Seller's Obligations</b><br/>
                    When Atoms Wellness receives an application from Buyer for the refund by and/or return of the Item to the Seller, Atoms Wellness will notify Seller in writing. Seller may respond to Buyer's application according to the steps provided by Atoms Wellness in the written notification. Seller must respond with proposed measures and steps to deal with such application within 5 days from the date of the written notification from Atoms Wellness. Should Atoms Wellness not hear from Seller within the stipulated period, Atoms Wellness shall be entitled to proceed to assess, process, reject and/or approve Buyer's application without further notice to Seller.</p>
                    <p>Atoms Wellness will review each Seller's response on a case-by-case basis and, in its sole discretion, determine whether Buyer's application may be successful against the circumstances stated by Seller. In the event that Atoms Wellness approves the Buyer's application, Atoms Wellness shall with no further notification to the Seller, proceed to process the cash refund to and return of the Item by the Buyer at the Seller's costs and expenses.</p>

                    <p style={{marginTop:'30px'}}><b style={{ color: '#333' }}>Condition of Returning Item</b><br/>
                    To enjoy a hassle-free experience when returning the Item, Buyer should ensure that the Item, including any complimentary items such as accessories that come with the Item, must be returned to Seller in the condition received by Buyer on delivery. We recommend that the Buyer takes a photo of the Item upon receipt.</p>

                    <p style={{marginTop:'30px'}}><b style={{ color: '#333' }}>Liability of Return Shipping Fee</b><br/>
                    In the scenario of an unforeseen error from the Seller's end (i.e - damaged, faulty or wrong Item delivered to the Buyer), the Seller will bear the Buyer's return shipping fee.</p>
                    <p>In the scenario of the Buyer's change of mind, Buyer shall get Seller's consent prior to the return request and Buyer will bear the return shipping fee.</p>
                    <p>In the scenario where both Seller-Buyer disputing the party liable for the return shipping fee, Atoms Wellness at its sole discretion will determine whether the Buyer or the Seller shall be liable for the return shipping fee.</p>

                    <p style={{marginTop:'30px'}}><b style={{ color: '#333' }}>Refunds</b><br/>
                    Buyer will only be refunded after Atoms Wellness has received the confirmation from Seller that Seller has received the returned Item in condition acceptable to Atoms Wellness. In the event where Atoms Wellness does not hear from Seller within 7 days from the date of Atoms Wellness's request for such confirmation, Atoms Wellness will be at liberty to refund the applicable sum to Buyer without further notice to Seller. The refund will be made to Buyer's credit/debit card or designated bank account, whichever is applicable.</p>
                    {/* <p>Seller's subscription fee is non-refundable.</p> */}

                    <p style={{marginTop:'30px'}}><b style={{ color: '#333' }}>Communication Between Buyer and Seller</b><br/>
                    Atoms Wellness encourages both Buyer and Seller to communicate with each other in the event where a problem arises in a transaction. Since Atoms Wellness is a platform for users to conduct trading, Buyer should contact Seller directly for any issue relating to the Item purchased.</p>


                </Container>
            </div>
        </Box>
    );
}
