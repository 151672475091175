import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Card, CardContent, TextField, Divider, InputAdornment, Input, InputLabel, FormControl, IconButton, Link } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';

export default function GenealogySponsor(props) {
    const { t, i18n } = useTranslation();
    const { id } = useSelector(state => state.user);
    const styles = useStyles();
    const [tree, setTree] = useState([]);
    const [state, setState] = useState({ totalDirectDownlines: 0, totalDownlines: 0, username: '' });
    
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const urlUsername = useQuery().get('username');
    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        const newUsername = urlUsername ? urlUsername : '';
        getUrl(`genealogy/sponsor`, { username: newUsername }).then(response => {
            if (isMountedRef.current) {
                if (response.status) {
                    setTree(response.data.tree_data);
                    setState({ ...state, totalDirectDownlines: response.data.total_direct_downlines, totalDownlines: response.data.total_downlines });
                }
                setLoading(false);
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    const searchUsername = () => {
        setLoading(true);
        getUrl(`genealogy/sponsor`, {username: state.username}).then(response => {
            if (isMountedRef.current) {
                let {status, message, error, data} = response;

                if (status) {
                    setTree(data.tree_data);
                    setState({ ...state, totalDirectDownlines: data.total_direct_downlines, totalDownlines: data.total_downlines });
                }else{
                    if(error || message){
                        if(_.size(error) > 0){
                            _.map(error, (value, key) => {
                                message += "\n "+value[0];
                            })
                        }
                        addAlert(message);
                    }
                }
                setLoading(false);
            }
            
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });
    }

    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={`${nodes.id}`} 
        // label={nodes.username}
        label={
            <Link underline='none' color="inherit" href={`/community/genealogySponsor?username=${nodes.username}`} >
            <div className={styles.labelRoot}>
                <PersonIcon />
                <Typography variant="body2" className={styles.labelText}>{nodes.username}</Typography>
                <Typography variant="caption" color="inherit" className={styles.labelSubText}>{new Date(nodes.user.created_at).toLocaleDateString()}</Typography>
                <Typography variant="caption" color="inherit" className={styles.labelSubText}>{nodes.user.max_rank_display ? '['+ nodes.user.max_rank_display +']':''}</Typography>
                <Typography variant="caption" color="inherit" className={styles.labelSubText}>{nodes.user.mobile!==null && nodes.referral_id == id ? '['+ nodes.user.mobile +']':''}</Typography>
                <Typography variant="caption" color="inherit" className={styles.labelSubText}>{nodes.user.email!==null && nodes.referral_id == id ? '['+ nodes.user.email +']':''}</Typography>
            </div>
            </Link>
          }
        >
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    );

    return (
        <Grid container spacing={1} justify="center" style={{ minHeight: 450, }}>
            <Grid item xs={12} className={styles.root} >
                {
                    _.size(tree) > 0 ?
                        <Card style={{border:'none', marginBottom: 10 }} variant="outlined">
                            <CardContent style={{ borderRadius: 0 }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth >
                                            <InputLabel htmlFor="username-search">{t('genealogy.username')}</InputLabel>
                                            <Input
                                                id="username-search"
                                                type="text"
                                                value={state.username}
                                                onChange={(event) => setState({...state, username: event.target.value})}
                                                onKeyDown={(e) => (e.keyCode == 13 && searchUsername())}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="search button"
                                                            onClick={searchUsername}
                                                        >
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={12} container justify="center" alignItems="center" style={{ marginTop: 10 }}>
                                        <Grid item xs={6} style={{ textAlign: "center", flex: 1 }}>
                                            <Typography variant="caption" display="block">{t('genealogy.totalDirectDownlines')}</Typography>
                                            <Typography>{state.totalDirectDownlines}</Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem />
                                        <Grid item xs={6} style={{ textAlign: "center", flex: 1 }}>
                                            <Typography variant="caption" display="block">{t('genealogy.totalDownlines')}</Typography>
                                            <Typography>{state.totalDownlines}</Typography>
                                        </Grid>
                                    </Grid> */}
                                    <Grid item xs={12} style={{ marginTop: 10, overflowX: "auto" }}>
                                        <TreeView
                                            className={styles.tree}
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpanded={[`${id}`]}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                        >
                                            {renderTree(tree)}
                                        </TreeView>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    :
                    <Card style={{ border:'none'}} variant="outlined">
                        <CardContent style={{ height: 400, borderRadius: 0 }}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <Typography variant="h5">...</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                }
                
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    tree: {
        // height: 110,
        flexGrow: 1,
        // maxWidth: 400,
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelText: {
        fontWeight: 'inherit',
        marginLeft: 10,
        textTransform: "lowercase"
    },
    labelSubText: {
        marginLeft: 10, 
        fontStyle: 'italic'
    },
}));