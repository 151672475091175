import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { PayPalButton } from "react-paypal-button-v2";
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Container, Grid, Box, useTheme, List, ListItem, Dialog, DialogContent, Breadcrumbs, DialogTitle, TextField, Zoom, Radio, MenuItem, Select, FormControl, RadioGroup, FormLabel, FormControlLabel, Divider, Link } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ErrorIcon from '@material-ui/icons/Error';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { UPAY_URL, PAYPAL_URL } from '../configs/Config';
import { useInterval } from '../helper/Tools';
import WebpImg from './Layouts/WebpImg';

import TitleBar from './Layouts/TitleBar';

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

// stripe payment library
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";


const PAYPAL_AVAILABLE = false;
const UPAY_AVAILABLE = false;
const MOBI_BOOST_AVAILABLE = false;
const MOBI_CREDITCARD_AVAILABLE = false;
const MOBI_FPX_AVAILABLE = false;
const BIGOPAY_AVAILABLE = false;
const STRIPE_AVAILABLE = false;
const IPAYMU_AVAILABLE = false;
const IPAY88_AVAILABLE = false;
const GKASH_AVAILABLE = false;
const REVENUEMONSTER_AVAILABLE = false;

export default function Payment() {
    const { i18n, t } = useTranslation();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    let history = useHistory();
    const { id, name, email, mobile } = useSelector(state => state.user);
    // const stripe = useStripe();
    // const elements = useElements();

    let { oid } = useParams();

    const [order, setOrder] = useState([]);
    const [totalPrice, setTotalPrice] = useState({}); // product fee
    const [currencyData, setCurrencyData] = useState(null);
    const [totalShippingPrice, setTotalShippingPrice] = useState([]);
    const [dialog, setDialog] = useState(false);
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const [wallets, setWallets] = useState([]);
    const [validBalance, setValidBalance] = useState(false);
    const [invalidMsg, setInvalidMsg] = useState();
    // const [shippingWallet, setShippingWallet] = useState({
    //     id: 0,
    //     prefix: '',
    //     decimal: 2,
    // });
    const [totalPayment, setTotalPayment] = useState([]); // include shipping fee

    // UPAY
    const paymentForm = useRef();
    const mobiCreditCardPaymentForm = useRef();
    const mobiFpxPaymentForm = useRef();
    const bigopayPaymentForm = useRef();
    const ipay88PaymentForm = useRef();
    const ipaymuPaymentForm = useRef();
    const gkashPaymentForm = useRef();

    const [mobiCreditCardUrl, setMobiCreditCardUrl] = useState();
    const [mobiFpxUrl, setMobiFpxUrl] = useState();
    const [bigopayUrl, setBigopayUrl] = useState();
    const [bigoSessionId, setBigoSessionId] = useState();

    const [generatePaymentForm, setGeneratePaymentForm] = useState(false);
    const [paymentInput, setPaymentInput] = useState({
        'MERCHANT_IDENTIFIER': "",
        'AMOUNT': "",
        'TXN_DESC': "",
        'CALLBACK_URL': "",
        'CUSTOMER_ID': id,
        'ORDER_ID': "",
        'INSTALLMENT': 0,
        'CUSTOMER_NAME': name,
        'CUSTOMER_EMAIL': email,
        'CUSTOMER_MOBILE': mobile,
        'TXN_SIGNATURE': "",
        'PAYMENT_METHOD': "",
        'SOURCE_FROM': "",
        'IS_TEST': "",
        'CURRENCY': "",
    });
    const [fpxPaymentInput, setFpxPaymentInput] = useState({
        service: "",
        merchantName: "",
        mid: "",
        tid: "",
        mobiLink: "",
        redirectUrl: "",
        sellerOrderNo: "",
        amount: "",
        email: "",
        productDesc: "",
        bankType: "",
        bank: "",
    });

    const [ipaymuUrl, setIpaymuUrl] = useState("");

    const [ipay88Url, setIpay88Url] = useState("");
    const [ipay88PaymentInput, setIpay88PaymentInput] = useState([]);

    const [gkashUrl, setGkashUrl] = useState("");
    const [gkashPaymentInput, setGkashPaymentInput] = useState([]);

    // MOBI CREDIT CARD
    const [cardState, setCardState] = useState({
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
        mapNumber: '',
        mapExpiry: '',
    });
    const [showCreditCard, setShowCreditCard] = useState(false);

    // MOBI BOOST
    const [isRunning, setIsRunning] = useState(false);
    useInterval(() => {
        getUrl(`mobiBoost/validate/${oid}`)
        .then(response => {
            if(response.data && response.data.responseCode === '0000') { // success
                setIsRunning(false);
                setDialog(true);
            }
        })
        .catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }, isRunning ? 5000 : null);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = PAYPAL_URL;
        script.async = true;
        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        getUrl('wallets').then(response => {
            if (isMountedRef.current && response.status === 1) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }, []);

    // useEffect(() => {
    //     getUrl('shipping_fee/wallet').then(response => {
    //         if(response.status){
    //             let shippingFeePrefix = '';
    //             if(currencyData){
    //                 shippingFeePrefix = i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en;
    //             }
    //             if(_.size(response.data) > 0){
    //                 let name = _.split(response.data.name, '|');
    //                 shippingFeePrefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
    //                 setShippingWallet({id: response.data.id, prefix: shippingFeePrefix, decimal: response.data.decimal});
    //             }else{
    //                 setShippingWallet({id: 0, prefix: shippingFeePrefix, decimal: 2});
    //             }
    //         }else{
    //             addAlert(JSON.stringify(response.data));
    //         }
    //     }).catch(error => {
    //         addAlert(JSON.stringify(error.message));
    //     });
    //     return ;
    // }, [i18n.language, addAlert, currencyData]);

    useEffect(() => {
        let tempWallet = {};
        _.map(totalPrice, (walletTotal, walletType) => {
            tempWallet[walletType] = walletTotal;
        });
        _.map(totalShippingPrice, (walletTotal, walletType) => {
            if(walletTotal > 0){
                let decimal = 2;
                if(walletType !== 'cash'){
                    _.map(wallets, wallet => {
                        let name = _.split(wallet.name, '|');
                        let walletName = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                        /* if(walletName === walletType){
                            decimal = wallet.decimal;
                        } */
                    })
                }
                if(_.isUndefined(tempWallet[walletType])){
                    tempWallet[walletType] = 0;
                }
                tempWallet[walletType] += walletTotal;
            }
        });
        // if(shippingWallet.id > 0){
        //     if(parseFloat(totalShippingPrice) > 0){
        //         const old_amount = parseFloat(tempWallet[shippingWallet.id] ? tempWallet[shippingWallet.id] : 0);
        //         tempWallet[shippingWallet.id] = (old_amount + parseFloat(totalShippingPrice)).toFixed(shippingWallet.decimal);
        //     }
        // }else{
        //     const old_amount = parseFloat(tempWallet['cash'] ? tempWallet['cash'] : 0);
        //     tempWallet['cash'] = old_amount + parseFloat(totalShippingPrice);
        // }
        setTotalPayment(tempWallet);
    }, [totalPrice, totalShippingPrice, wallets]);

    // PAYPAL & UPAY
    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        if(oid) {
            getUrl(`payment/${oid}`)
            .then(orderList => {
                // console.log("orderList", orderList);
                if(isMountedRef.current) {
                    setLoading(false);
                    setCurrencyData(orderList.currency_data);
                    if(orderList.status === 1 && _.size(orderList.data) > 0) {
                        setPaymentInput(paymentInput => ({ ...paymentInput, CUSTOMER_NAME: orderList.data[0]['buyer_name'], CUSTOMER_EMAIL: orderList.data[0]['buyer_email'], CUSTOMER_MOBILE: orderList.data[0]['buyer_mobile'] }));
                        // setState({ customer_name: orderList.data[0]['buyer_name'] });
                        let txn_desc = 'Transaction for order ';
                        let allow_payment = true;
                        setOrder(orderList.data);
                        _.map(orderList.data, (orderItem, orderIndex) => { // loop purchases to get order
                            txn_desc += `${orderItem.order_no},`;
                            // console.log("asd", orderItem);
                            if(orderItem.status === 20) {
                                _.map(orderItem.order_shippings, shippingItem => {
                                    let countShipping = false;
                                    _.map(shippingItem.order_shipping_details, shippingDetails => {
                                        _.map(orderItem.order_details, detailItem => {
                                            if(shippingDetails.order_detail_id === detailItem.id){
                                                if(detailItem.status === 1){
                                                    countShipping = true;
                                                }
                                            }
                                        })
                                    })
                                    if(countShipping){
                                        let walletType = 'cash';
                                        if(shippingItem.wallet_id > 0){
                                            walletType = shippingItem.wallet_id;
                                        }
                                        if(_.isUndefined(totalShippingPrice[walletType])) {
                                            totalShippingPrice[walletType] = 0;
                                            setTotalShippingPrice(totalShippingPrice => ({ ...totalShippingPrice, [walletType]: 0 }));
                                        }
                                        setTotalShippingPrice(totalShippingPrice => ({ ...totalShippingPrice, [walletType]: totalShippingPrice[walletType] + parseFloat(shippingItem.fee) }));
                                        totalShippingPrice[walletType] += parseFloat(shippingItem.fee);
                                    }
                                })
                                // setTotalShippingPrice(totalShippingPrice => parseFloat(totalShippingPrice) + parseFloat(orderItem.shipping_fee));
                            }
                            // console.log("orderItem", orderItem);
                            _.map(orderItem.order_details, detailItem => { // loop order to get order details
                                // console.log(detailItem)
                                if(detailItem.status === 1){
                                    _.map(detailItem.payment, (paymentItem, paymentIndex) => { // loop order details to get payment
                                        if(detailItem.error === false) {
                                            if(paymentIndex == "multi_wallet"){
                                                let multiP = JSON.parse(detailItem.multi_pricing);
                                                paymentIndex = multiP.multi_wallet_detail.pricing_name;
                                            }
                                            if(_.isUndefined(totalPrice[paymentIndex])) {
                                                totalPrice[paymentIndex] = 0;
                                                setTotalPrice(totalPrice => ({ ...totalPrice, [paymentIndex]: 0 }));
                                            }
                                            setTotalPrice(totalPrice => ({ ...totalPrice, [paymentIndex]: totalPrice[paymentIndex] + parseFloat(paymentItem) }));
                                            totalPrice[paymentIndex] += parseFloat(paymentItem);
                                        }else{
                                            allow_payment = false;
                                        } 
                                    })
                                }
                            })
                        });
                        if(totalPrice['cash']) {
                            setPaymentInput(paymentInput => ({ ...paymentInput, TXN_DESC: txn_desc }));
                        }
                        if(allow_payment)
                            setValidBalance(orderList.valid_balance);
                        if(orderList.valid_balance === false){
                            setInvalidMsg(t('payment.invalidWalletBalance')); 
                        }
                        if(orderList.max_rp !== null){
                            setValidBalance(false);
                            setInvalidMsg(t('payment.reached_max_rp_'+orderList.max_rp)); 
                        }
                    } else {
                        addAlert(orderList.message);
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        } else {
            history.push('/cart');
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [oid, addAlert, history]);

    // MOBI FPX
    const [showFpx, setShowFpx] = useState(false);
    const [fpxState, setFpxState] = useState({
        bankType: '01',
        bank: ''
    });
    const [bankList, setBankList] = useState();

    useEffect(() => {
        isMountedRef.current = true;
        if(MOBI_FPX_AVAILABLE) {
            setLoading(true);
            getUrl(`mobiFpx/bank`)
            .then(result => {
                if(result.status === 1 && isMountedRef.current) {
                    setBankList(result.data);
                    setLoading(false);
                    
                    setFpxState(fpxState => ({ ...fpxState, bank: result.data?.['01']?.list?.[0]?.BankCode }))
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
    }, [setLoading, addAlert])

    // PAYPAL / STRIPE
    const paymentReceived = (type, returnData) => {
        const data = {
            payType: type,
            paymentReturn: returnData,
        };

        postUrl(`${type}/${oid}`, data)
        .then(result => {
            // console.log("result", result)
            history.push(`/paymentresult/${oid}?source=${type}`);
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    // UPAY & MOBI
    const requestPayment = async (type) => {
        const data = { payType: type, purchaseNo: oid };

        return postUrl(`payment`, data)
        .then(result => {
            if(result.status == 0 && result.error && result.error.validate_error){
                window.location.reload();
            }else{
                return result.status;
            }
            
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
            return false;
        });
    }

    // UPAY & MOBI
    const makePayment = async type => {
        setLoading(true);

        const paymentStatus = await requestPayment(type);

        if(paymentStatus) {
            if(_.includes(['upay', 'mobiBoost', 'bigopay', 'revenuemonster'], type)) {
                getUrl(`${type}/generate/${oid}`)
                .then(async response => {
                    if(response.status === 1) {
                        setLoading(false);
                        if (type === 'upay') {
                            const { CURRENCY, IS_TEST, MERCHANT_IDENTIFIER, PAYMENT_METHOD, SOURCE_FROM, TXN_SIGNATURE, CALLBACK_URL, ORDER_ID, CUSTOMER_IP, AMOUNT } = response.data;
                            setPaymentInput(paymentInput => ({ ...paymentInput, CURRENCY, IS_TEST, MERCHANT_IDENTIFIER, PAYMENT_METHOD, SOURCE_FROM, TXN_SIGNATURE, CALLBACK_URL, ORDER_ID, CUSTOMER_IP, AMOUNT }));
                            await setGeneratePaymentForm(true);
                            await paymentForm.current.submit();
                            await setLoading(true);
                        } else if (type === 'mobiBoost') {
                            const { responseCode, responseData, responseMessage } = response.data;
    
                            if(responseCode === '0000' && responseMessage === 'SUCCESS' && responseData.url) {
                                // window.location.href = responseData.url; 
                                window.open(responseData.url, '_blank');
                                setIsRunning(true);
                            } else {
                                addAlert(responseMessage);
                            }
                        } else if (type === 'bigopay') {
                            if(response.data.status === true && response.data.url) {
                                await setBigopayUrl(response.data.url);
                                await setBigoSessionId(response.data.session_id);
                                await bigopayPaymentForm.current.submit();
                                await setLoading(true);
                            } else {
                                addAlert(JSON.stringify(response.data.message));
                            }
                        } else if (type === 'revenuemonster') {
                            const { code, item } = response.data;
    
                            if(code === 'SUCCESS' && item.url) {
                                window.location.href = item.url;
                            }else{
                                addAlert(response.message);
                            }
                        }
                    }else{
                        setLoading(false);
                        addAlert(response.message);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    addAlert(JSON.stringify(error.message));
                });
            } else if(type === 'mobiCreditCard') {
                const { number, name, expiry, cvc } = cardState;
                if(number.length !== 16) {
                    setLoading(false);
                    addAlert(t('payment.creditCardNumberLengthError'));
                } else if (expiry.length !== 4) {
                    setLoading(false);
                    addAlert(t('payment.creditCardExpiryLengthError'));
                } else if (cvc.length !== 3) {
                    setLoading(false);
                    addAlert(t('payment.creditCardNCvcLengthError'));
                } else {
                    getUrl(`${type}/generate/${oid}?ccNumber=${number}&ccName=${name}&ccExpiry=${expiry}&ccCvc=${cvc}`)
                    .then(async response => {
                        if(response.status === 1) {
                            // console.log('response', response);
                            const { action_url, carddetails, email, firstName, ip, lastName, mobiApiKey, murl, orderId, postalCode, service, shippingState, umResponseUrl, loginId } = response.data;

                            const actionUrl = `${action_url}?mobiApiKey=${mobiApiKey}&service=${service}&ip=${ip}&mUrl=${murl}&firstName=${firstName}&nameOnCard=${cardState.name}&lastName=${lastName}&postalCode=${postalCode}&shippingState=${shippingState}&orderId=${orderId}&email=${email}&carddetails=${carddetails}&loginId=${loginId}&umResponseUrl=${umResponseUrl}`;
                            await setMobiCreditCardUrl(actionUrl);
                            setLoading(false);
                            await mobiCreditCardPaymentForm.current.submit();
                            await setLoading(true);
                            // console.log("actionUrl", actionUrl);
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        addAlert(JSON.stringify(error.message));
                    });
                }
            } else if(type === 'mobiFpx') {
                const { bankType, bank } = fpxState;
                if(!bankType) {
                    setLoading(false);
                    addAlert(t('payment.fpxNoBankTypeSelect'));
                } else if (!bank) {
                    setLoading(false);
                    addAlert(t('payment.fpxNoBankSelect'));
                } else {
                    getUrl(`${type}/generate/${oid}?bankType=${bankType}&bank=${bank}`)
                    .then(async response => {
                        if(response.status === 1) {
                            setLoading(false);
                            // console.log('response', response);
                            const { action_url, amount, bank, bankType, buyerName, email, merchantName, mid, mobiLink, productDesc, redirectUrl, sellerOrderNo, service, tid } = response.data;
                            setFpxPaymentInput(fpxPaymentInput => ({ ...fpxPaymentInput, service,  merchantName, mid, tid, mobiLink, redirectUrl, sellerOrderNo, amount, email, productDesc, bankType,  bank, buyerName }));
                            await setMobiFpxUrl(action_url);
                            // await setGeneratePaymentForm(true);
                            await mobiFpxPaymentForm.current.submit();
                            await setLoading(true);
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        addAlert(JSON.stringify(error.message));
                    });
                }
            } else if(type === 'stripe') {
                /* if(STRIPE_AVAILABLE) {
                    // Create PaymentIntent as soon as the page loads
                    getUrl(`stripe/generate/${oid}`)
                    .then(async(response) => {
                        // console.log("clientSecret", response);
                        if(isMountedRef.current) {
                            if(response.status === 1) {
                                setStripeClientSecret(stripeClientSecret => response.data.clientSecret);

                                // console.log("stripeClientSecret", stripeClientSecret)
                                const payload = await stripe.confirmCardPayment(response.data.clientSecret, {
                                    payment_method: {
                                        card: elements.getElement(CardElement)
                                    }
                                });
                                if (payload.error) {
                                    addAlert(`Payment failed ${payload.error.message}`, 'error');
                                    setLoading(false);
                                } else {
                                    // console.log("payload", payload.paymentIntent);
                                    setLoading(false);
                                    paymentReceived("stripe", payload.paymentIntent);
                                }
                            }
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        addAlert(JSON.stringify(error.message));
                    });
                } */
            } else if(type === 'ipay88') {
                if (IPAY88_AVAILABLE) {
                    getUrl(`ipay88/generate/${oid}`)
                    .then( async (response) => {
                        // console.log("ipay88_response", response);
                        if(isMountedRef.current) {
                            if(response.status === 1) {
                                setLoading(false);
                                // console.log('response', response);
                                await setIpay88Url(response.data.url);
                                delete response.data.url;
                                setIpay88PaymentInput(response.data);
                                await ipay88PaymentForm.current.submit();
                                await setLoading(true);
                            }
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        addAlert(JSON.stringify(error.message));
                    });
                }
            } else if(type === 'ipaymu') {
                if (IPAYMU_AVAILABLE) {
                    getUrl(`gateway/ipaymu/generate/${oid}`)
                    .then( async (response) => {
                        // console.log("ipaymu_response", response);
                        if(isMountedRef.current) {
                            if(response.status === 1 && _.size(response.data) > 0) {
                                setLoading(false);
                                if(response.data.Status === 200) {
                                    await setIpaymuUrl(response.data.Data.Url);
                                    await ipaymuPaymentForm.current.submit();
                                } else {
                                    addAlert(JSON.stringify(response));
                                }
                            }
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        addAlert(JSON.stringify(error.message));
                    });
                }
            } else if(type === 'gkash') {
                if (GKASH_AVAILABLE) {
                    getUrl(`gkash/generate/${oid}`)
                    .then( async (response) => {
                        // console.log("gkash_response", response);
                        if(isMountedRef.current) {
                            if(response.status === 1) {
                                setLoading(false);
                                // console.log('response', response);
                                await setGkashUrl(response.data.url);
                                delete response.data.url;
                                setGkashPaymentInput(response.data);
                                await gkashPaymentForm.current.submit();
                                await setLoading(true);
                            }
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        addAlert(JSON.stringify(error.message));
                    });
                }
            }
        }
    }

    const walletPayment = () => {
        setLoading(true);
        postUrl(`wallet/${oid}`).then((response) => {
            setLoading(false);
            if(response.status === 1) {
                history.push(`/paymentresult/${oid}?source=wallet`);
            }else{
                addAlert(response.message);
            }
        })
        .catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    // MOBI CREDIT CARD
    const handleCardChange = (event) => {
        const { name, value } = event.target;
        if(_.includes(['number', 'expiry', 'cvc'], name)) {

            if(name === 'number') {
                let filterValue = value.replace(/-/g, "");
                filterValue = value.replace(/[^0-9]/g, '');
                let parts = [];
                for(var i = 0, ilen = filterValue.length; i < ilen; i+=4) {
                    parts.push(filterValue.substring(i, i + 4));
                }

                const mapValue = parts.join('-');
                setCardState({ ...cardState, 'number': filterValue, 'mapNumber': mapValue });
            } else if (name === 'expiry') {
                
                let filterValue = value.replace(/\//g, "");
                filterValue = value.replace(/[^0-9]/g, '');
                // console.log("filterValue", filterValue);
                let parts = [];
                for(var j = 0, jlen = filterValue.length; j < jlen; j+=2) {
                    parts.push(filterValue.substring(j, j + 2));
                }

                const mapValue = parts.join('/');
                setCardState({ ...cardState, 'expiry': filterValue, 'mapExpiry': mapValue });
            } else {
                setCardState({ ...cardState, [name]: value.replace(/[^0-9]/g, '') });
            }
        } else {
            setCardState({ ...cardState, [name]: value.replace(/[^a-zA-Z ]/g, '').toUpperCase() });
        }
    }

    // STRIPE PAYMENT
    const [stripeClientSecret, setStripeClientSecret] = useState('');
    const [showStripe, setShowStripe] = useState(false);

    const handleStripeChange = async (event) => {
        // console.log("event", event)
        if(_.size(event.error) > 0) {
            addAlert(event.error.message, 'error');
        }
    };

    const priceDisplay = (paymentItem, paymentIndex, orderDetails) => {
        let prefix = '';
        let decimal = 2;

        if(paymentIndex === 'multi_wallet'){
            let multiPricing = orderDetails ? JSON.parse(orderDetails.multi_pricing) : "";
            if(multiPricing){
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix =  i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];

            }else{
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix =  i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                prefix = "Other wallets";
            }
        }else
        if(paymentIndex !== 'cash' && !isNaN(paymentIndex)){
            _.map(wallets, wallet => {
                if(parseInt(wallet.id) === parseInt(paymentIndex)){
                    let name = _.split(wallet.name, '|');
                    prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                    // decimal = wallet.decimal;
                }
            })
        }else if(paymentIndex === 'cash'){
            prefix = i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en;
        }else{
            let name = _.split(paymentIndex, '|');
            prefix =  i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
        }
        return (
            <NumberFormat value={parseFloat(paymentItem).toFixed(decimal)} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={prefix ? prefix+' ' : ''} />
        )
    }

    const renderDisplay = () => {
        return (
            <Box>
                <Dialog open={dialog} style={{ padding: 20 }}>
                    <DialogTitle>
                        <Typography variant="caption">{ t('payment.boostReceived') }</Typography>
                    </DialogTitle>
                    <DialogContent style={{ paddingBottom: 10 }}>
                        <Button fullWidth variant="contained" onClick={ () => { history.push(`/paymentresult/${oid}?source=mobiBoost`) } }>
                            <Typography variant="button">{ t('general.ok') }</Typography>
                        </Button>
                    </DialogContent>
                </Dialog>

                {/* <Breadcrumbs separator="›" maxItems={2} aria-label="breadcrumb" style={{ fontSize: 12, padding: '0 10px' }}>
                    <Typography>
                        {t('title.home')}
                    </Typography>
                    <Typography>
                        {t('title.payment')}
                    </Typography>
                </Breadcrumbs> */}

                <Box className={styles.root} style={{ padding: 15 }}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <LocationOnIcon style={{ color: '#f72b60', marginRight: '8px' }} />
                        <Typography variant="h6" style={{ fontSize: '14px', color: '#f72b60' }}>{t('checkout.shippingAddress')}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center">

                        <Box display="flex" flexDirection="column" style={{ paddingLeft: 10 }}>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography variant="subtitle2" style={{ fontSize: 12 }}>{_.isUndefined(order?.[0]?.recipient) ? '-' : `${order?.[0]?.recipient} `}</Typography>
                                {
                                    !_.isUndefined(order[0]) ?
                                        <Typography variant="subtitle2" style={{ paddingLeft: 5 }}>{_.isUndefined(order?.[0]?.mobile) ? '-' : ` | ${order?.[0]?.mobile}`}</Typography>
                                        : null
                                }
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography variant="caption">{_.isUndefined(order?.[0]?.address) ? '-' : order?.[0]?.address}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {
                    !validBalance ?
                    <Box className={ styles.root } style={{padding:10, color:"red"}}>
                        {invalidMsg}
                    </Box>
                    : null
                }
                <Box className={ styles.root } style={{padding:10}}>
                    <Grid container spacing={1} justify="center">
                        <Grid item xs={12} sm={12}>
                            { _.map(order, orderItem => {
                                let orderPrice = {};
                                let showSummary = false;
                                let showOutlets =false;
                                let outletDetail={};

                                return (

                                    <List key={orderItem.id} style={{ position: 'relative' }}>
                                        {
                                            orderItem.status === 20 ?
                                            <Box border={1} classes={{ root: styles.itemBoxStyle }} style={{paddingTop:20, backgroundColor:'#fff', boxShadow:'#d5d5d5 0px 0px 11px'}}>
                                                { _.map(orderItem.order_details, detailItem => {
                                                    const split_title = detailItem['title'].split("|");
                                                    const title_translate = i18n.language === 'cn' ? (_.isUndefined(split_title[1]) ? split_title[0] : split_title[1]) : split_title[0];
                                                    
                                                    if(detailItem.status) {
                                                        showSummary = true;
                                                        _.map(detailItem.payment, (paymentItem, paymentIndex) => {
                                                            if(paymentIndex ==="multi_wallet"){
                                                                let multiP = JSON.parse(detailItem.multi_pricing);
                                                                paymentIndex = multiP.multi_wallet_detail.pricing_name;
                                                            }
                                                            if(_.isUndefined(orderPrice[paymentIndex])) {
                                                                orderPrice[paymentIndex] = 0;
                                                            }
                
                                                            if(detailItem.error === false) {
                                                                orderPrice[paymentIndex] += parseFloat(paymentItem);
                                                            } else {
                                                                orderPrice[paymentIndex] += 0;
                                                            }
                                                        })
                                                    }

                                                    if(_.size(detailItem.outlet)>0){
                                                        showOutlets=true;
                                                        outletDetail=detailItem.outlet;
                                                    }

                                                    return (
                                                        <Box key={detailItem.id}>
                                                            { detailItem.status ?
                                                                <>
                                                                    <Box className={ styles.titleBoxStyle }>
                                                                        <Typography variant="button" style={{ fontWeight: 'bold' }}>{ orderItem.order_no }</Typography>
                                                                    </Box>
                                                                    <Box style={{backgroundColor: 'white', padding:'15px 0', borderBottom:'1px dashed #c1c1c1'}}>
                                                                        <ListItem  style={{  padding:'0 3%' }}>
                                                                            <Grid container style={{ paddingTop: 10 }}>
                                                                                <Box style={{display:'flex', alignItems:'flex-start', width:'100%'}}>
                                                                                    <Box border={1} borderColor="#dee2e6">
                                                                                        <WebpImg style={{ width:100, height:100, minWidth:100, objectFit:'cover' }} alt={detailItem.product_id} src={detailItem.image ? detailItem.image : '/images/no-image.png'} />
                                                                                    </Box>
                                                                                    <Box style={{padding:'0 12px', width:'100%'}}>
                                                                                        <Typography variant="caption" className={styles.textline2} style={{ fontSize:14 }}>{ title_translate }</Typography>
                                                                                        <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                                                                                            <Typography variant="button" style={{ color: theme.palette.gray.main }}>{`x ${detailItem.quantity}`}</Typography>
                                                                                            {
                                                                                                _.size(detailItem.payment) > 0
                                                                                                    ?
                                                                                                    _.map(detailItem.payment, (paymentItem, paymentIndex) => {
                                                                                                        if (parseFloat(paymentItem) > 0) {
                                                                                                            return (
                                                                                                                <Box key={paymentIndex}>
                                                                                                                    <Typography variant="button" style={{ color: theme.palette.price.main }}>
                                                                                                                        {priceDisplay(paymentItem, paymentIndex, detailItem)}
                                                                                                                    </Typography>
                                                                                                                </Box>
                                                                                                            );
                                                                                                        }
                                                                                                    })
                                                                                                    :
                                                                                                    <Typography variant="button" style={{ color: theme.palette.price.main }}>
                                                                                                        <NumberFormat value={detailItem.price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} />
                                                                                                    </Typography>
                                                                                            }
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                                
                                                                                
                                                                                {detailItem.error ? 
                                                                                    <Grid item xs={12} style={{ padding: 10, textAlign: 'left', alignSelf: 'center' }}>
                                                                                        <Typography variant="button" style={{ color: 'red' }}><ErrorIcon style={{ verticalAlign: 'middle'}}/>{ `${detailItem.message}` }</Typography>
                                                                                    </Grid>
                                                                                : null}
                                                                            </Grid>
                                                                        </ListItem>
                                                                    </Box>
                                                                </>
                                                            : null }
                                                        </Box>
                                                    )
                                                }) }
                                                    {
                                                        showOutlets ?
                                                            <Box style={{ backgroundColor: '#ececec', padding: '15px 0', borderBottom: '1px dashed #c1c1c1',padding: 10 }}>
                                                                <Box display="flex" flexDirection="column" >
                                                                    <Typography style={{ flex: 1 }} variant="caption">{t('checkout.pickUpAt')}:</Typography>
                                                                    <Typography style={{ flex: 1}} variant="caption">
                                                                        {outletDetail.pic_name}
                                                                    </Typography>
                                                                    <Typography style={{ flex: 1}} variant="caption">
                                                                        {outletDetail.pic_mobile}
                                                                    </Typography>
                                                                    <Typography style={{ flex: 1,paddingTop: 10}}  variant="subtitle2">
                                                                        {outletDetail.outlet_name}
                                                                    </Typography>
                                                                    <Typography style={{ flex: 1}} variant="caption">
                                                                        {outletDetail.address}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            : null
                                                    }
                                                { showSummary ?
                                                    <Box style={{ backgroundColor: '#ececec', padding: 10}}>
                                                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                            <Typography style={{ flex: 1}} variant="caption">{ t('checkout.remark') }:</Typography>
                                                            <Typography style={{ flex: 1, textAlign: 'right'}}  variant="caption">
                                                                { orderItem.remark }
                                                            </Typography>
                                                        </Box>
                                                        <Box display="flex" flexDirection="row" justifyContent="space-between" style={{padding:'5px 0'}}>
                                                            <Typography style={{ flex: 1}} variant="caption">{ t('payment.subtotal') }:</Typography>
                                                            <Typography style={{ flex: 1, textAlign: 'right'}}  variant="caption">
                                                                {
                                                                    orderPrice ?
                                                                    _.map(orderPrice, (priceItem, priceIndex) => {
                                                                        if(parseFloat(priceItem) > 0){
                                                                            return(
                                                                                <Box key={priceIndex}>
                                                                                    {priceDisplay(priceItem, priceIndex)}
                                                                                </Box>
                                                                            )
                                                                        }
                                                                    })
                                                                    : null
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                            <Typography style={{ flex: 1}} variant="caption">{ t('order.shippingFee') }:</Typography>
                                                            <Typography style={{ flex: 1, textAlign: 'right', fontWeight: 'bold' }}  variant="caption">
                                                            {
                                                                _.size(orderItem.order_shippings) > 0
                                                                ?
                                                                <>
                                                                {
                                                                    _.map(orderItem.order_shippings, (shippingItem, shippingIndex) => {
                                                                        let countShipping = false;
                                                                        _.map(shippingItem.order_shipping_details, shippingDetails => {
                                                                            _.map(orderItem.order_details, detailItem => {
                                                                                if(shippingDetails.order_detail_id === detailItem.id){
                                                                                    if(detailItem.status === 1){
                                                                                        countShipping = true;
                                                                                    }
                                                                                }
                                                                            })
                                                                        });
                                                                        if(countShipping){
                                                                            let prefix = i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en;
                                                                            let decimal = 2;
                                                                            if(shippingItem.wallet_id > 0){
                                                                                _.map(wallets, wallet => {
                                                                                    if(parseInt(wallet.id) === parseInt(shippingItem.wallet_id)){
                                                                                        let name = _.split(wallet.name, '|');
                                                                                        prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                                                                                        // decimal = wallet.decimal;
                                                                                    }
                                                                                })
                                                                            }
                                                                            if(parseFloat(shippingItem.fee).toFixed(decimal) > 0){
                                                                                return(
                                                                                    <Box key={shippingIndex}>
                                                                                        <NumberFormat value={ parseFloat(shippingItem.fee).toFixed(decimal) } decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={`${prefix} `} />
                                                                                    </Box>
                                                                                )
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                </>
                                                                :
                                                                t('order.freeShipping')
                                                            }
                                                            {/* {
                                                                parseFloat(orderItem['shipping_fee']).toFixed(2) > 0
                                                                ?
                                                                <NumberFormat value={ parseFloat(orderItem['shipping_fee']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ shippingWallet.prefix } `} />
                                                                :
                                                                t('order.freeShipping')
                                                            } */}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                : null }
                                            </Box>
                                            : null
                                        }
                                    </List>
                                )
                            }) }
                        </Grid>
                    </Grid>
                </Box>
                {
                    validBalance && ((parseFloat(totalPayment['cash'])).toFixed(2) > 0) ?
                    <Box style={{padding:13, paddingTop:0}}>
                    <Box style={{backgroundColor:'#fff', padding:10, borderRadius:15, boxShadow: '#d5d5d5 0px 0px 11px'}}>
                        {/* //////////  Payment Method ////////////// */}
                        <Box className={ styles.root } style={{padding:'0 0 20px', marginTop:20, borderBottom:'1px dashed #cfcfcf'}}>
                            <Grid container spacing={1} justify="center">
                                <Grid item xs={12} sm={12}>
                                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                                        <Typography variant="h6">{ t('payment.paymentMethod') }</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Grid container style={{ justifyContent: 'center' }} spacing={2}>
                                    { ((parseFloat(totalPayment['cash'])).toFixed(2) > 0) ?
                                        <>
                                            { PAYPAL_AVAILABLE ?
                                                <Grid item xs={6} sm={4} md={2} style={{ alignItems: 'center', justifyContent: 'center', overflow:'hidden' }}>
                                                    <PayPalButton
                                                        style={{ height: 55 }}
                                                        createOrder={ async (data, actions) => {
                                                            await requestPayment("paypal");
                                                            return actions.order.create({
                                                                purchase_units: [{
                                                                    amount: {
                                                                        currency_code: "USD",
                                                                        value: parseFloat(totalPayment['cash'])
                                                                    }
                                                                }],
                                                                application_context: {
                                                                    shipping_preference: "NO_SHIPPING"
                                                                }
                                                            })
                                                        }}
                                                        onApprove={(data, details) => {
                                                            paymentReceived("paypal", { data, details });
                                                        }}
                                                    />
                                                </Grid>
                                            : null }

                                            { UPAY_AVAILABLE ? 
                                                <Grid item xs={6} sm={4} md={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button fullWidth variant="contained" color="primary" style={{ padding: 10, height: 55, backgroundColor:'#fdfdfd', border:'1px solid #dadada' }} onClick={ () => { makePayment('upay') } }>
                                                        {/* <Typography variant="h6">{t('checkout.upayPayment')}</Typography> */}
                                                        <WebpImg containerStyle={{display:'flex', alignItems:'center'}} style={{ height: 40, maxWidth:'unset!important' }} alt="upay" 
                                                        src="/images/payment_logo/upay.png" />
                                                    </Button>
                                                    {
                                                        generatePaymentForm ?
                                                        <form ref={paymentForm} action={UPAY_URL} method="POST">
                                                            {
                                                                paymentInput ? _.map(paymentInput, (paymentValue, paymentIndex) => {
                                                                    return (
                                                                        <input hidden key={paymentIndex} name={paymentIndex} defaultValue={paymentValue} />
                                                                    )
                                                                }) : null
                                                            }
                                                        </form>
                                                        : null
                                                    }
                                                    {/* <Button fullWidth variant="outlined" color="primary" style={{ padding: 10 }} onClick={ () => { paymentForm.current.submit() } }>
                                                        <Typography variant="h6">Credit Card</Typography>
                                                    </Button> */}
                                                </Grid>
                                            : null }

                                            { MOBI_BOOST_AVAILABLE ?
                                                <Grid item xs={6} sm={4} md={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button fullWidth variant="contained" color="primary" style={{ padding: 10, height: 55, backgroundColor:'#ee2e24', border:'1px solid #cc2920' }} onClick={ () => makePayment('mobiBoost') }>
                                                        {/* <Typography variant="h6">{t('checkout.mobiBoost')}</Typography> */}
                                                        <WebpImg containerStyle={{display:'flex', alignItems:'center'}} style={{ height: 40, maxWidth:'unset!important' }} alt="upay" 
                                                        src="/images/payment_logo/boost.png" />
                                                    </Button>
                                                </Grid>
                                            : null }

                                            { MOBI_CREDITCARD_AVAILABLE ? 
                                                <Grid item xs={6} sm={4} md={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button fullWidth variant="contained" color="primary" style={{ padding: 10, height: 55 }} onClick={ () => { setShowCreditCard(true); setShowFpx(false) } }>
                                                        <Typography variant="h6">{t('checkout.mobiCreditCard')}</Typography>
                                                    </Button>
                                                </Grid>
                                            : null }
                                            
                                            { MOBI_FPX_AVAILABLE ?
                                                <Grid item xs={6} sm={4} md={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button fullWidth variant="contained" color="primary" style={{ padding: 10, height: 55, backgroundColor:'#fff', border:'1px solid #d0d0d0' }} onClick={ () => { setShowFpx(true); setShowCreditCard(false) } }>
                                                        {/* <Typography variant="h6">{t('checkout.mobiFPX')}</Typography> */}
                                                        <WebpImg containerStyle={{display:'flex', alignItems:'center'}} style={{ height: 40, maxWidth:'unset!important' }} alt="upay" 
                                                        src="/images/payment_logo/fpx.jpg" />
                                                    </Button>
                                                </Grid>
                                            : null }

                                            { BIGOPAY_AVAILABLE ?
                                                <Grid item xs={6} sm={4} md={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button fullWidth variant="contained" color="primary" style={{ padding: 10, height: 55 }} onClick={ () => makePayment('bigopay') }>
                                                        <Typography variant="h6">{t('checkout.bigopay')}</Typography>
                                                    </Button>
                                                    <form ref={bigopayPaymentForm} action={bigopayUrl} method="POST">
                                                        <input hidden name="session_id" defaultValue={bigoSessionId} />
                                                    </form>
                                                </Grid>
                                            : null }

                                            { STRIPE_AVAILABLE ?
                                                <Grid item xs={6} sm={4} md={2}  style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button fullWidth variant="contained" color="primary" style={{ padding: 10, height: 55, backgroundColor:'#02a9f3', border:'1px solid #d0d0d0' }} onClick={ () => { setShowFpx(false); setShowCreditCard(false); setShowStripe(true) } }>
                                                        {/* <Typography variant="h6" style={{ fontWeight: 'bold' }}>{t('checkout.stripe')}</Typography> */}
                                                        <WebpImg containerStyle={{display:'flex', alignItems:'center'}} style={{ height: 40, maxWidth:'unset!important' }} alt="upay" 
                                                        src="/images/payment_logo/stripe.jpg" />
                                                    </Button>
                                                </Grid>
                                            : null }

                                            { IPAYMU_AVAILABLE ?
                                                <Grid item xs={6} sm={4} md={2}  style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button fullWidth variant="contained" color="primary" style={{ padding: 10, height: 55, backgroundColor:'#fff', border:'1px solid #d0d0d0' }} onClick={ () => makePayment('ipaymu') }>
                                                        {/* <Typography variant="h6" style={{ fontWeight: 'bold' }}>{t('checkout.ipaymu')}</Typography> */}
                                                        <WebpImg containerStyle={{display:'flex', alignItems:'center'}}  alt="upay" 
                                                        src="/images/payment_logo/ipaymu.png" />
                                                    </Button>
                                                    <form ref={ipaymuPaymentForm} action={ipaymuUrl} method="GET">
                                                    </form>
                                                </Grid>
                                            : null }

                                            { IPAY88_AVAILABLE ? 
                                                <Grid item xs={6} sm={4} md={2}  style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button fullWidth variant="contained" color="primary" style={{ padding: 10, height: 55, backgroundColor:'#f26722', border:'1px solid #d0d0d0' }} onClick={ () => makePayment('ipay88') }>
                                                        {/* <Typography variant="h6" style={{ fontWeight: 'bold' }}>{t('checkout.ipay88')}</Typography> */}
                                                        <WebpImg containerStyle={{display:'flex', alignItems:'center'}} style={{ height: 40, maxWidth:'unset!important' }} alt="upay" 
                                                        src="/images/payment_logo/ipay88.png" />
                                                    </Button>
                                                    <form ref={ipay88PaymentForm} action={ipay88Url} method="POST">
                                                        { ipay88PaymentInput ? 
                                                            _.map(ipay88PaymentInput, (paymentValue, paymentIndex) => {
                                                                return (
                                                                    <input hidden key={paymentIndex} name={paymentIndex} defaultValue={paymentValue} />
                                                                )
                                                            }) 
                                                        : null }
                                                    </form>
                                                </Grid>
                                            : null }
                                            
                                            { GKASH_AVAILABLE ? 
                                                <Grid item xs={6} sm={4} md={2}  style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button fullWidth variant="contained" color="primary" style={{ padding: 10, height: 55, backgroundColor:'#f26722', border:'1px solid #d0d0d0' }} onClick={ () => makePayment('gkash') }>
                                                        {/* <WebpImg containerStyle={{display:'flex', alignItems:'center'}} style={{ height: 40, maxWidth:'unset!important' }} src="/images/payment_logo/gkash.png" /> */}
                                                        Gkash
                                                    </Button>
                                                    <form ref={gkashPaymentForm} action={gkashUrl} method="POST">
                                                        { gkashPaymentInput ? 
                                                            _.map(gkashPaymentInput, (paymentValue, paymentIndex) => {
                                                                return (
                                                                    <input hidden key={paymentIndex} name={paymentIndex} defaultValue={paymentValue} />
                                                                )
                                                            }) 
                                                        : null }
                                                    </form>
                                                </Grid>
                                            : null }

                                            {REVENUEMONSTER_AVAILABLE ?
                                                <Grid item xs={6} sm={4} md={3} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button fullWidth variant="contained"  style={{ padding: 10, height: 55, backgroundColor:'#fff', border:'1px solid red', color:'red' }} onClick={() => makePayment('revenuemonster')}>
                                                        {t('payment.systemWallet')}
                                                    </Button>
                                                </Grid>
                                            : null}
                                            
                                            { showFpx ?
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                        <Zoom in={showFpx} style={{ transitionDelay: '300ms' }}>
                                                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                                <FormControl component="fieldset" fullWidth variant="filled">
                                                                    <FormLabel component="label" style={{ fontWeight: 'bold', color: '#000' }}>{t('payment.bankType')}</FormLabel>
                                                                    <RadioGroup row style={{ justifyContent: 'center' }}>
                                                                        { _.size(bankList) > 0 ?
                                                                            _.map(bankList, (bankTypeItem, bankTypeId) => {
                                                                                return (
                                                                                    <FormControlLabel 
                                                                                        key={bankTypeId}
                                                                                        value="end" 
                                                                                        control={
                                                                                            <Radio
                                                                                                checked={fpxState.bankType === bankTypeId}
                                                                                                onChange={() => {
                                                                                                    setFpxState({ bankType: bankTypeId, bank: bankTypeItem.list[0].BankCode })
                                                                                                } }
                                                                                                value={bankTypeId}
                                                                                                color="primary"
                                                                                            />
                                                                                        } 
                                                                                        label={ bankTypeItem.name } 
                                                                                    />
                                                                                )
                                                                            })
                                                                        : null }
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                        </Zoom>
                                                        <Zoom in={showFpx} style={{ transitionDelay: '400ms' }}>
                                                            <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
                                                            { _.size(bankList) > 0 ?
                                                                    <FormControl component="fieldset" fullWidth variant="filled" style={{ justifyContent: 'center' }}>
                                                                        <FormLabel component="label" style={{ fontWeight: 'bold', color: '#000' }}>{t('payment.bank')}</FormLabel>
                                                                        <Select
                                                                            value={fpxState.bank}
                                                                            variant="outlined"
                                                                            style={{ marginTop: 5 }}
                                                                            onChange={(event) => setFpxState({ ...fpxState, bank: event.target.value })}
                                                                        >
                                                                            {
                                                                                _.map(bankList[`${fpxState.bankType}`]['list'], bankItem => {
                                                                                    return <MenuItem key={bankItem.ID} value={bankItem.BankCode}>{ bankItem.BankDisplayName }</MenuItem>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </FormControl>
                                                            : null }
                                                            </Grid>
                                                        </Zoom>
                                                        <Zoom in={showFpx} style={{ transitionDelay: '500ms' }}>
                                                            <Grid item xs={4} sm={12} style={{ textAlign: 'center' }}>
                                                                <Button 
                                                                    variant="contained" 
                                                                    disabled={fpxState.bankType && fpxState.bank ? false : true }
                                                                    onClick={ () => makePayment('mobiFpx') }
                                                                    style={{ maxWidth: 300 }}
                                                                    fullWidth
                                                                >
                                                                    <Typography variant="button">{ t('payment.pay') }</Typography>
                                                                </Button>
                                                                <form ref={mobiFpxPaymentForm} action={mobiFpxUrl} method="POST">
                                                                    { fpxPaymentInput ? 
                                                                        _.map(fpxPaymentInput, (paymentValue, paymentIndex) => {
                                                                            return (
                                                                                <input hidden key={paymentIndex} name={paymentIndex} defaultValue={paymentValue} />
                                                                            )
                                                                        }) 
                                                                    : null }
                                                                </form>
                                                            </Grid>
                                                        </Zoom>
                                                    </Grid>
                                                </Grid>
                                            : null }
                                                
                                            { showCreditCard ?
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                        <Zoom in={showCreditCard}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                                                <Cards
                                                                    cvc={cardState.cvc}
                                                                    expiry={cardState.expiry}
                                                                    focused={cardState.focus}
                                                                    name={cardState.name}
                                                                    number={cardState.number}
                                                                    placeholders={{ name: t('payment.cardName') }}
                                                                    locale={{ valid: t('payment.cardExpiry') }}
                                                                />
                                                                <form ref={mobiCreditCardPaymentForm} action={mobiCreditCardUrl} method="POST">
                                                                </form>
                                                            </Grid>
                                                        </Zoom>
                                                        <Zoom in={showCreditCard} style={{ transitionDelay: '300ms' }}>
                                                            <Grid item xs={12} sm={12} md={5} lg={4} style={{ textAlign: 'center' }}>
                                                                <TextField 
                                                                    variant="outlined" 
                                                                    placeholder={'xxxx-xxxx-xxxx-xxxx'}
                                                                    label={t('payment.cardNumber')}
                                                                    autoComplete="cc-number"
                                                                    onChange={handleCardChange}
                                                                    onFocus={(e) => {
                                                                        setCardState({ ...cardState, focus: 'number' })
                                                                    }}
                                                                    value={cardState.mapNumber}
                                                                    size="small"
                                                                    classes={{ root: styles.cardInputStyle }}
                                                                    inputProps={{ maxLength: 19, minLength: 19, name: 'number' }}
                                                                    fullWidth
                                                                />
                                                                <TextField 
                                                                    variant="outlined" 
                                                                    label={t('payment.cardName')}
                                                                    placeholder={t('payment.cardName')}
                                                                    autoComplete="cc-name"
                                                                    onChange={handleCardChange}
                                                                    onFocus={(e) => {
                                                                        setCardState({ ...cardState, focus: 'name' })
                                                                    }}
                                                                    value={cardState.name}
                                                                    size="small"
                                                                    classes={{ root: styles.cardInputStyle }}
                                                                    inputProps={{  name: 'name' }}
                                                                    fullWidth
                                                                />
                                                                <Box display="flex" flexDirection="row" style={{ justifyContent: 'center' }}>
                                                                    <TextField 
                                                                        variant="outlined" 
                                                                        placeholder={'xx/xx'}
                                                                        label={t('payment.cardExpiry')}
                                                                        autoComplete="cc-exp"
                                                                        onChange={handleCardChange}
                                                                        onFocus={(e) => {
                                                                            setCardState({ ...cardState, focus: 'expiry' })
                                                                        }}
                                                                        value={cardState.mapExpiry}
                                                                        size="small"
                                                                        style={{ margin: 5, width: 200 }}
                                                                        inputProps={{ maxLength: 5, minLength: 5, name: 'expiry' }}
                                                                    />
                                                                    <TextField 
                                                                        variant="outlined" 
                                                                        placeholder={'xxx'}
                                                                        label={t('payment.cardCvc')}
                                                                        autoComplete="cc-csc"
                                                                        onChange={handleCardChange}
                                                                        onFocus={(e) => {
                                                                            setCardState({ ...cardState, focus: 'cvc' })
                                                                        }}
                                                                        value={cardState.cvc}
                                                                        size="small"
                                                                        style={{ margin: 5, width: 90 }}
                                                                        inputProps={{ maxLength: 3, minLength: 3, name: 'cvc' }}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        </Zoom>
                                                        <Zoom in={showCreditCard} style={{ transitionDelay: '500ms' }}>
                                                            <Grid item xs={12} sm={8} md={2} lg={1} style={{ textAlign: 'center' }}>
                                                                <Button 
                                                                    variant="contained" 
                                                                    disabled={cardState.number && cardState.name && cardState.expiry && cardState.cvc ? false : true }
                                                                    onClick={ () => makePayment('mobiCreditCard') }
                                                                    style={{ maxWidth: 300 }}
                                                                    fullWidth
                                                                >
                                                                    <Typography variant="button">{ t('payment.pay') }</Typography>
                                                                </Button>
                                                            </Grid>
                                                        </Zoom>
                                                    </Grid>
                                                </Grid>
                                            : null }

                                            {/* { showStripe ?
                                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Grid item xs={12} style={{ maxWidth: 500, textAlign: 'center' }}>
                                                        <fieldset className="FormGroup">
                                                            <div className="FormRow">
                                                                <CardElement id="card-element" options={CARD_OPTIONS} onChange={handleStripeChange} />
                                                            </div>
                                                        </fieldset>
                                                        <Button 
                                                            variant="contained" 
                                                            // disabled={cardState.number && cardState.name && cardState.expiry && cardState.cvc ? false : true }
                                                            onClick={ () => makePayment('stripe') }
                                                            style={{ maxWidth: 300 }}
                                                            fullWidth
                                                        >
                                                            <Typography variant="button">{ t('payment.pay') }</Typography>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            : null } */}
                                        </>
                                    : null }
                                </Grid>
                            </Grid>
                            </Grid>
                        </Box>

                        {/* //////////  SubTotal ////////////// */}
                        <Box className={ styles.root } style={{padding:'20px 0 0'}}>
                            <Grid container spacing={1} justify="center" style={{padding:'0 20px'}}>
                                <Grid item xs={12} sm={12}>
                                    {/* <Grid container style={{ justifyContent: 'center' }} spacing={2}>
                                        <Grid item xs={6} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography style={{ flex: 1 }} variant="h6" style={{color:'#a2a2a2'}}>{ t('payment.subtotal') }:</Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'right' }}>
                                            <Typography style={{ flex: 1 }} variant="h6" color="primary">
                                                <NumberFormat value={ parseFloat(totalPrice['cash']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ _.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : '' } `} />
                                            </Typography>
                                        </Grid>
                                    </Grid> */}

                                    <Box style={{display:'flex', alignItems:'center', justifyContent:'flex-end', width:'100%'}}>
                                        <Typography variant="h6" style={{color:'#a2a2a2', fontSize:16}}>{ t('payment.subtotal') } : </Typography>
                                        <Typography variant="h6" color="primary" style={{minWidth:140, textAlign:'right', fontSize:16}}>
                                            <NumberFormat value={ parseFloat(totalPrice['cash']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ _.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : '' } `} />
                                        </Typography>
                                    </Box>
                                </Grid>
                                {
                                    totalShippingPrice['cash'] > 0 ?
                                    <Grid item xs={12} sm={12}>
                                        <Box style={{display:'flex', alignItems:'center', justifyContent:'flex-end', width:'100%'}}>
                                            <Typography variant="h6" style={{color:'#a2a2a2', fontSize:16}}>{ t('order.shippingFee') } : </Typography>
                                            <Typography variant="h6" color="primary" style={{minWidth:140, textAlign:'right', fontSize:16}}>
                                                {
                                                    parseFloat(totalShippingPrice['cash']).toFixed(2) > 0 ?
                                                    <NumberFormat value={ parseFloat(totalShippingPrice['cash']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ _.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : '' } `} />
                                                    :
                                                    t('order.freeShipping')
                                                }
                                            </Typography>
                                        </Box>
                                        {/* <Grid container style={{ justifyContent: 'center' }} spacing={2}>
                                            <Grid item xs={6} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <Typography style={{ flex: 1 }} variant="h6" style={{color:'#a2a2a2'}}>{ t('order.shippingFee') }:</Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'right' }}>
                                            <Typography style={{ flex: 1 }} variant="h6" color="primary">
                                                    <NumberFormat value={ parseFloat(totalShippingPrice).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ _.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : '' } `} />
                                                </Typography>
                                            </Grid>
                                        </Grid> */}
                                    </Grid>
                                    : null
                                }
                                <Grid item xs={12} sm={12}>
                                    <Box style={{display:'flex', alignItems:'center', justifyContent:'flex-end', width:'100%'}}>
                                        <Typography variant="h6" style={{color:'#a2a2a2', fontSize:16}}>{ t('checkout.totalPayment') } : </Typography>
                                        <Typography variant="h6" color="primary" style={{minWidth:140, textAlign:'right', fontSize:16}}>
                                            <NumberFormat value={ (parseFloat(totalPayment['cash'])).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ _.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : '' } `} />
                                        </Typography>
                                    </Box>

                                    {/* <Grid container style={{ justifyContent: 'center' }} spacing={2}>
                                        <Grid item xs={6} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography style={{ flex: 1 }} variant="h6" style={{color:'#a2a2a2'}}>{ t('checkout.totalPayment') }:</Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'right' }}>
                                        <Typography style={{ flex: 1 }} variant="h6" color="primary">
                                                <NumberFormat value={ (parseFloat(totalPrice['cash']) + parseFloat(totalShippingPrice)).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ _.size(currencyData) ? (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) : '' } `} />
                                            </Typography>
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    </Box>
                    : 
                    null
                }
                {
                    validBalance && !((parseFloat(totalPayment['cash'])).toFixed(2) > 0) ?
                    <Box className={ styles.root } style={{backgroundColor:'#fff', padding:30, borderRadius:15, boxShadow: ' 0px 0px 20px #d1d1d1'}}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="h6">{ t('payment.paymentMethod') }</Typography>
                        </Box>
                        <Button variant="outlined" style={{margin:10}} onClick={() => walletPayment()}>{t('payment.systemWallet')}</Button>
                        <Divider style={{margin:5}} />
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'flex-end', width:'100%'}}>
                            <Typography variant="h6" style={{color:'#a2a2a2', fontSize:16}}>{ t('checkout.totalPayment') } : </Typography>
                            <Typography variant="h6" color="primary" style={{minWidth:140, textAlign:'right', fontSize:16}}>
                            {
                                totalPayment ?
                                _.map(totalPayment, (priceItem, priceIndex) => {
                                    if(parseFloat(priceItem) > 0){
                                        return(
                                            <Box key={priceIndex}>
                                                {priceDisplay(priceItem, priceIndex)}
                                            </Box>
                                        )
                                    }
                                })
                                : null
                            }
                            </Typography>
                        </Box>
                    </Box>
                    : null
                }
            </Box>
        )
    }

    return (
        <Box className={styles.root2} >
            {/* <Box style={{ height: 50, backgroundColor:theme.palette.primary.main, display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', paddingTop:10}}>
                <Link underline='none' onClick={() => history.goBack()} component="button" >
                    <FiChevronLeft style={{color:theme.palette.white.main, marginLeft:15}}/>
                </Link>
                <Typography style={{ textAlign: 'center', color: theme.palette.white.main, width:'100%', marginLeft:-34, pointerEvents: "none" }}>{t('title.payment')}</Typography>
            </Box> */}
            <TitleBar  title={t('title.payment')} displayInfo={false} back />
            <Box paddingBottom={5}>
                <Container fixed className={ styles.breadcrumbRoot }>
                    { renderDisplay() }
                </Container>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '30px 0 10px 0',
    },
    priceStyle: {
        fontSize: 16,
        color: theme.palette.secondary.main,
    },
    itemBoxStyle: {  
        marginBottom: 20, 
        borderRadius: 20, 
        borderColor: theme.palette.gray.border,
        overflow: 'hidden'
    },
    titleBoxStyle: {
        position: 'absolute',
        zIndex: 1,
        right: 0,
        left: 0,
        margin: 'auto',
        textAlign: 'center',
        width: '350px',
        maxWidth: '75%',
        top: '-5px',
        borderRadius: '48px',
        padding: '5px 0',
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    textline2: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow:'hidden',
    },
    cardInputStyle: {
        margin: 5,
        width: 300
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    }
}));

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#c4f0ff',
            color: '#fff',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
            color: '#fce883',
            },
            '::placeholder': {
            color: '#87bbfd',
            },
        },
        invalid: {
            iconColor: '#ffc7ee',
            color: '#ffc7ee',
        },
    },
};