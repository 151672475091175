import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, useTheme, Typography, Box, Link } from '@material-ui/core';
import TitleBar from './Layouts/TitleBar';

import { currencyFormat } from '../helper/Tools';
import { getUrl } from '../helper/ApiAction';
import { storeWalletBalance } from '../actions';
import useNotificationLoading from '../helper/useNotificationLoading';

export default function Wallet() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { walletBalance, walletList, accessToken } = useSelector(state => ({
        walletBalance: state.user.walletBalance,
        walletList: state.wallet.walletList,
        accessToken: state.general.accessToken
    }));

    const isMountedRef = useRef(null);

    const [commissionSummary, setCommissionSummary] = useState([]);

    useEffect(() => {
        isMountedRef.current = true;
        if(accessToken ) {
            getUrl('wallets/balance').then(response => {
                if(response.status && isMountedRef.current) {
                    dispatch(storeWalletBalance(response.data));
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })

            getUrl('commissions/summary').then(response => {
                if(response.status && isMountedRef.current) {
                    setCommissionSummary(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [dispatch, addAlert, accessToken])

    return (

        <Box paddingBottom={5} style={{ background: theme.palette.white.mobileBkg, padding:'0 0 80px', minHeight: '100%'}}>{/* className={styles.root} style={{ padding: '0 0 80px', }} */}
            <TitleBar title={ t('title.wallet') } height={99} displayQr/>

            <Box minHeight={500}>
                { _.map(walletBalance, walletItem => {
                    return <WalletComponent key={walletItem.id} data={walletItem} walletList={walletList}/>
                })}

                { _.size(commissionSummary) ?
                    <Box padding={2}>
                        <Box padding={2} borderRadius={15} boxShadow='0 0 8px 0 #b9b9b9' style={{ backgroundColor: theme.palette.common.white }}>
                            <Box justifyContent="space-between" display="flex" alignItems="center">
                                <Typography style={{ color: theme.palette.secondary.main, fontSize: 30, fontWeight: 'bold' }}>{t('commission.bonusSummary')}</Typography>
                                <Link underline="none" to={`/bonus`} component={RouterLink} style={{ marginRight: 5 }}>
                                    <Typography style={{ textDecoration: "underline" }}>{t('commission.seeMore')}</Typography>
                                </Link>
                            </Box>
                            { _.map(commissionSummary.bonus, bonusItem => {
                                    let bonusName = _.split(bonusItem.name, '|');
                                    bonusName = i18n.language === "cn" && bonusName[1] ? bonusName[1] : bonusName[0];
                                    let summary = _.find(commissionSummary.summary,{bonus_id: bonusItem.id});
                                    let amount = "0.00";
                                    if(summary) amount = Number(summary.amount).toFixed(2);
                                    return (
                                        <Box key={bonusItem.id} justifyContent="space-between" display="flex" alignItems="center" padding={1}>
                                            <Typography variant="body1">{bonusName}</Typography>
                                            <Typography variant="body1">{amount}</Typography>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                    : null
                }
            </Box>
        </Box>
    );
}

const WalletComponent = (props) => {
    const { data, walletList } = props;
    // console.log("data", data);

    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const existWallet = _.find(walletList, { id: data.id });
    let allowTransfer = false;
    let allowConvert = false;
    let allowWithdraw = false;
    if(existWallet) {
        allowTransfer = existWallet.allow_transfer;
        allowConvert = existWallet.allow_convert;
        allowWithdraw = existWallet.allow_withdraw;
    }
    const balance = currencyFormat((data.balance * 100) / 100);
    
    return (
        <Box padding={2}>
            <Box padding={2} borderRadius={15} boxShadow='0 0 8px 0 #b9b9b9' style={{ backgroundColor: theme.palette.common.white }}>
                <Typography style={{ color: theme.palette.secondary.main, fontSize: 30, fontWeight: 'bold', width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis',overflow: 'hidden' }}>{ balance }</Typography>
                <Typography style={{ color: theme.palette.silver.text, fontWeight: 'bold' }}>{ data.wallet_name }</Typography>
                {/* <Typography style={{ color: theme.palette.silver.text, fontSize:14 }}>description adipiscing elit, sed diam </Typography> */}
                <Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center" paddingY={2} style={{ width: '100%' }}>
                    { allowTransfer ?
                        <Link underline='none' to={`/transfer/${data.code}`} component={RouterLink} style={{ margin: 5 }}>
                            <Typography classes={{ root: classes.walletButtonStyle }}>{t('wallet.transfer')}</Typography> 
                        </Link>
                    : null }
                    { allowConvert ?
                        <Link underline='none' to={`/convert/${data.code}`} component={RouterLink} style={{ margin: 5 }}>
                            <Typography classes={{ root: classes.walletButtonStyle }}>{t('wallet.convert')}</Typography> 
                        </Link>
                    : null }
                    <Link underline='none' to={`/transaction/${data.code}`} component={RouterLink} style={{ margin: 5 }}>
                        <Typography classes={{ root: classes.walletButtonStyle }}>{t('wallet.transaction')}</Typography>
                    </Link>
                    { allowWithdraw ?
                        <>
                            <Link underline='none' to={`/withdrawal/${data.code}`} component={RouterLink} style={{ margin: 5 }}>
                                <Typography classes={{ root: classes.walletButtonStyle }}>{t('wallet.withdrawal')}</Typography> 
                            </Link>
                            <Link underline='none' to={`/withdrawal-history/${data.code}`} component={RouterLink} style={{ margin: 5 }}>
                                <Typography classes={{ root: classes.walletButtonStyle }}>{t('title.withdrawalHistory')}</Typography> 
                            </Link>
                        </>
                    : null }
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',       
    },
    walletButtonStyle: { 
        border: '1px solid #696969', 
        borderRadius: 48, 
        padding:'5px 10px', 
        color: theme.palette.silver.text, 
        fontSize:14, 
        minWidth:75, 
        textAlign:'center' 
    }
}));
