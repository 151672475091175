import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Divider, TextField, Button, Grid, Box, Typography, Link, MenuItem, FormControlLabel, Checkbox, FormControl, FormLabel,  Radio, RadioGroup, FormHelperText } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { postUrl, getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';

import TitleBarWhite from './Layouts/TitleBarWhite';

const INITIAL_ACC_TYPE='newAcc';

function useInput({ type, label, helperText, val }) {
    const [value, setValue] = useState(val?val:"");
    const [error, setErrorValue] = useState("");
    const input =
        <Grid container spacing={3} justify="center">
            <Grid item xs={12}>
                <TextField fullWidth label={label} error={error ? true : false} helperText={error} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} />
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </Grid>
        </Grid>;
    return [value, input, setErrorValue, setValue];
}

function useSelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid container spacing={3} justify="center">
            <Grid item xs={12}>
                <TextField
                    select
                    label={label}
                    value={value}
                    onChange={({ target }) => setValue(target.value)}
                    variant="outlined"
                    fullWidth
                    error={error ? true : false}
                    helperText={error}
                >
                    {data.map((option, key) => (
                        <MenuItem key={key} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    return [value, select, setErrorValue, setValue];
}

function useCheckbox({ label, val, note = '' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const checkbox =
        <Grid item xs={12} sm={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={e => setValue(e.target.checked)}
                        color="primary"
                    />
                }
                label={label}
            />
            <Typography variant="body2" style={{ color: "#808080" }}>{note}</Typography>
        </Grid>;
    return [value, checkbox, setErrorValue];
}


export default function Recruit() {

    const styles = useStyles();
    const theme = useTheme();
    const { username } = useSelector(state => state.user);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    const query = useQuery();
    // const encoded_ref_id = query.get('r');
    const queryPlacement = query.get('placement') ? query.get('placement') : "";
    const queryPosition = query.get('position') ? query.get('position') : 0;
    const isMountedRef = useRef(null);
    // const [refId, setRefId] = useState("");
    const [state, setState]=useState({
        referral:username,
        referralErr:'',
    });
    const [accType, setAccType]=useState(INITIAL_ACC_TYPE);
    const { fr_id } = useSelector(state => state.user);

    const nationality = [{value: "MY", label: "Malaysia"}, {value: "SG", label: "Singapore"}];
    const placementPosition = [{ value: 0, label: t("register.placementPosition.no") }, { value: 1, label: t("register.placementPosition.left") }, { value: 2, label: t("register.placementPosition.right") }];
    const [name, nameInput, nameValidate] = useInput({ type: "text", label: t('register.name') });
    const [email, emailInput, emailValidate] = useInput({ type: "text", label: t('register.email') });
    const [mobile, mobileInput, mobileValidate] = useInput({ type: "text", label: t('register.mobile') });
    const [nric, nricInput, nricValidate] = useInput({ type: "text", label: t('register.nric_passport') });
    const [usernameField, usernameInput, usernameValidate] = useInput({ type: "text", label: t('register.username') });
    // const [referral, referralInput, referralValidate, setReferral] = useInput({ type: "text", label: t('register.referral') });
    const [placement, placementInput, placementValidate] = useInput({ type: "text", label: t('register.placement'), val: queryPlacement});
    const [position, positionInput, positionValidate] = useSelect({ data: placementPosition, label: t('register.position'), val: queryPosition });
    const [autoPlacement, autoPlacementInput, autoPlacementValidate] = useCheckbox({ label: t('register.autoPlacement'), val: false/* query.get('placement')? false:true */ });
    const [password, passwordInput, passwordValidate] = useInput({ type: "password", label: t('register.password'), helperText: t('profile.passwordValidation') });
    const [passwordConfirmation, passwordConfirmationInput, passwordConfirmationValidate] = useInput({ type: "password", label: t('register.passwordConfirmation') });
    const [country, countryInput, countryValidate] = useSelect({ data:nationality, label: t('register.nationality'), val: "" });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const validate = { name: nameValidate, email: emailValidate, mobile: mobileValidate, nric: nricValidate, password: passwordValidate, password_confirmation: passwordConfirmationValidate, username: usernameValidate, placement: placementValidate, position: positionValidate, autoPlacement: autoPlacementValidate, country: countryValidate };

    const handleSubmit = (evt) => {
        let postData={};
        setLoading(true);
        evt.preventDefault();

        setErrorMessage("");
        _.map(validate, (valid) => {
            valid("");
        })
        setState({ ...state,referralErr:'' });

        if(accType ==="subAcc"){
             postData = {
                acc_type:accType,
                username: usernameField,
                // referral: state.referral,
                // placement: autoPlacement ? '' : placement,
                // position: autoPlacement ? null : (position > 0 ? position : null),
                // auto_placement: autoPlacement ? 1 : 0,
                password,
                password_confirmation: passwordConfirmation
            }
        }else{
             postData = {
                acc_type: accType,
                name,
                email,
                mobile,
                nric,
                username: usernameField,
                referral: state.referral,
                placement: autoPlacement ? '' : placement,
                position: autoPlacement ? null : (position > 0 ? position : null),
                auto_placement: autoPlacement ? 1 : 0,
                password,
                password_confirmation: passwordConfirmation,
                country
            }
        }

        // if(username){
        //     postData.referral = state.referral;
        // }else{
        //     postData.referral = referral;
        // }

        // if (refId) {
        //     postData.ref_id = refId;
        // } else {
        //     postData.referral = referral;
        // }

        postUrl(`register`, postData)
            .then((response) => {
                // console.log(response);
                let { status, message, error } = response;
                if (status === 0) {
                    setLoading(false);
                    setErrorMessage(message);
                    _.map(error, (value, key) => {

                        if(key  =="referral"){
                            setState({ ...state,referralErr:value[0] });
                        }else{
                            validate[key](value[0]);
                        }


                    })
                } else {
                    setLoading(false);
                    setSuccessMessage(message);

                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        setState({ ...state, [name]: event.target.value });
    }

    const handleAccTypeChange =event =>{
        const type =event.target.value;
        setAccType(type);
        setErrorMessage("");
        _.map(validate, (valid) => {
            valid("");
        })
        setState({ ...state,referralErr:'' });
    }

    // useEffect(() => {
    //     isMountedRef.current = true;
    //     if (encoded_ref_id) {
    //         getUrl(`ref_username/${encoded_ref_id}`).then(response => {
    //             if (isMountedRef.current) {
    //                 if (response.data.ref_id) {
    //                     setRefId(response.data.ref_id);
    //                     setReferral(response.data.ref_name);
    //                 }
    //             }

    //         }).catch(error => {
    //             // addAlert(JSON.stringify(error.message));
    //         });
    //     }
    //     return () => { isMountedRef.current = false };
    // }, [encoded_ref_id]);

    return (
        <Box className={styles.root} >
            {/* <TitleBar title={t('profile.recruit')} displayInfo={false} back/> */}
            <div style={{position:'fixed', width:600, maxWidth:'100%', zIndex:2}}>
                <TitleBarWhite height={0} title={t('profile.recruit')} displayInfo={false} back />
            </div>

            <Grid style={{ maxWidth: '1790px', margin: '0 auto',}}>
                <Grid container spacing={1} justify="center" style={{ minHeight: 450 }}>
                    <Grid item xs={12} className={styles.root} >
                        <Card style={{borderRadius: 15, marginBottom: 10, marginTop: 15, background:'transparent', boxShadow:'none' }}>
                            {/* <CardHeader
                                title={t('title.register')}
                                titleTypographyProps={{ variant: 'subtitle1', color: 'primary' }}
                            />
                            <Divider /> */}
                            <CardContent style={{ padding: '40px 20px 20px', borderRadius: 0 }}>
                                <div style={{paddingBottom:15}}>
                                <Box style={{ color: theme.palette.primary.main, paddingBottom:5 }}>
                                    <Typography variant="h6">
                                        {t('profile.recruit')}
                                    </Typography>
                                    {/* <Typography variant="caption" color="textSecondary">
                                            {t('profile.bankInfoSubtitle')}
                                        </Typography> */}
                                </Box>
                                <Divider variant="middle" light style={{margin:0}}/>
                                </div>
                                {
                                    successMessage ?
                                        <Grid container spacing={2} justify="center" align="center">
                                            <Grid item xs={12}>
                                                <CheckCircleOutlineIcon style={{ color: 'green' }} fontSize="large" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {/* <Typography variant="h4" style={{ textAlign: 'center' }}>{successMessage}</Typography> */}
                                                {
                                                    accType ==='subAcc'?
                                                    <Typography variant="h4" style={{ textAlign: 'center' }}>{t('recruit.subAccCreateSuccess')}</Typography>
                                                    :
                                                    <Typography variant="h4" style={{ textAlign: 'center' }}>{t('register.recruitSuccessMsg')}</Typography>
                                                }
                                                
                                            </Grid>
                                        </Grid>
                                        :
                                        <Box>
                                            <Typography style={{ fontSize: 12, color: 'red', textAlign: 'center' }}>{errorMessage}</Typography>
                                            <form onSubmit={handleSubmit}>
                                                {/* <Grid container spacing={3} justify="center">
                                                    <Grid item xs={12}> 
                                                        <FormControl component="fieldset">
                                                            <FormLabel compoent="acctype">
                                                            <Typography variant="body1">{t('recruit.accTitle')}</Typography>
                                                            </FormLabel>
                                                            <RadioGroup row defaultValue={INITIAL_ACC_TYPE}  onChange={handleAccTypeChange}>
                                                                <FormControlLabel  value="newAcc" control={<Radio color="primary"  checkedIcon={<CheckCircle color="primary" />}/>} label={t('recruit.newAcc')}  />
                                                                {
                                                                    fr_id !=null && fr_id?
                                                                    null:
                                                                     <FormControlLabel  value="subAcc" control={<Radio color="primary" checkedIcon={<CheckCircle color="primary" />}/>} label={t('recruit.subAcc')}  />
                                                                }
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid> */}
                                                {
                                                    accType === "subAcc" ?
                                                        <>
                                                            {usernameInput}
                                                            {passwordInput}
                                                            {passwordConfirmationInput}
                                                        </>
                                                        :
                                                        <>
                                                            {nameInput}
                                                            {emailInput}
                                                            {mobileInput}
                                                            {nricInput}
                                                            {usernameInput}
                                                            <Grid container spacing={3} justify="center">
                                                                <Grid item xs={12}>
                                                                    <TextField fullWidth label={t('register.referral')} variant="outlined" value={state.referral} type="text" inputProps={{ name: 'referral' }} onChange={handleChange} error={state.referralErr ? true : false} helperText={state.referralErr} />
                                                                </Grid>
                                                            </Grid>
                                                            {false && autoPlacementInput}
                                                            {
                                                                autoPlacement ? null :
                                                                    <>
                                                                        {placementInput}
                                                                        {positionInput}
                                                                    </>
                                                            }
                                                            {countryInput}
                                                            {passwordInput}
                                                            {passwordConfirmationInput}
                                                        </>
                                                }
                                                <div style={{marginTop:25, display:'flex', justifyContent:'center'}}>
                                                    <Button type="submit" fullWidth variant="contained"  style={{width: 167, padding:'8px 9px', maxWidth: '80%', borderRadius: 48, color: '#fff'}} color="secondary" size="large">{t('button.register')}</Button>
                                                </div>
                                            </form>
                                        </Box>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Box>

    )
}

const useStyles = makeStyles(theme => ({
    root: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg, 
        padding:'0 0 80px',
        minHeight: '100%'
    },
}));