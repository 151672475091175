import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from '../helper/Tools';
import { getUrl, postUrl, logoutUser } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, IconButton, Button, Box, Typography, Avatar, Grid } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import WebpImg from './Layouts/WebpImg';

import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function AccountLinkVerify() {
    const { t } = useTranslation();

    const { name, username, id } = useSelector(state => state.user);
    const { accessToken } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();

    const query = useQuery();
    const hash = query.get('h');
    const source = query.get('source');
    const source_user_id = query.get('uid');
    const source_username = query.get('username');
    const type = query.get('type');

    const styles = useStyles();
    const [state, setState] = useState({
        showPassword: false,
        username: '',
        password: ''
    });
    const [continueDialogOpen, setContinueDialogOpen] = useState(false);
    const [verifyOpen, setVerifyOpen] = useState(false);
    const [redirectDialogOpen, setRedirectDialogOpen] = useState(false);
    const [redirectTimer, setRedirectTimer] = useState(false);
    const [redirectSecond, setRedirectSecond] = useState(3);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [redirectMsg, setRedirectMsg] = useState('');

    useEffect(() => {
        if(type && source && source_user_id && source_username && hash){
            if(type==='link'||type==='unlink'){
                if(accessToken){
                    // user already login, ask continue as XXX
                    setContinueDialogOpen(true);
                    setVerifyOpen(false);
                }else{
                    setVerifyOpen(true);
                }
            }
        }
    }, [accessToken, type, source, source_user_id, source_username, hash]);

    useEffect(() => {
        if(redirectTimer && redirectUrl && redirectSecond>0){
            let seconds = redirectSecond;
            const intervalId = setInterval(() => {
                seconds = seconds - 1;
                setRedirectSecond(seconds);
                if (seconds == 0) { 
                    clearInterval(intervalId);
                    // console.log('window.location.href', redirectUrl);
                    window.location.href = redirectUrl;
                }
            }, 1000)
            return () => clearInterval(intervalId); //This is important
        }
    }, [redirectTimer, redirectUrl])
    
    const linkUser = () => {
        setLoading(true);
        setVerifyOpen(false);
        let params = {
            username: state.username,
            password: state.password,
            source: source,
            source_user_id: source_user_id,
            source_username: source_username,
            hash: hash
        }
        postUrl(`account-link/request-`+type, params).then(response => {
            setLoading(false);
            if(response.status){
                setRedirectTimer(true);
                setRedirectDialogOpen(true);
                setRedirectUrl(response.data);
            }else{
                if(response.data){
                    // link success, transfer ipoint fail
                    setRedirectDialogOpen(true);
                    setRedirectUrl(response.data);
                    setRedirectMsg(response.message);
                }else{
                    // link fail
                    setVerifyOpen(true);
                    if(response.error){
                        addAlert(JSON.stringify(_.map(response.error).join(' ')));
                    }else{
                        addAlert(JSON.stringify(response.message));
                    }
                }
            }
        }).catch(error => {
            setLoading(false);
            setVerifyOpen(true);
            addAlert(JSON.stringify(error.message));
        });
    }

    const dontContinueAs = () => {
        setContinueDialogOpen(false);
        logoutUser();
    }
    const continueAs = () => {
        setContinueDialogOpen(false);
        setLoading(true);
        let params = {
            username: username,
            id: id,
            source: source,
            source_user_id: source_user_id,
            source_username: source_username,
            hash: hash
        }
        postUrl(`account-link/request-`+type, params).then(response => {
            setLoading(false);
            if(response.status){
                setRedirectTimer(true);
                setRedirectDialogOpen(true);
                setRedirectUrl(response.data);
            }else{
                if(response.data){
                    // link success, transfer ipoint fail
                    setRedirectDialogOpen(true);
                    setRedirectUrl(response.data);
                    setRedirectMsg(response.message);
                }else{
                    // link fail
                    setContinueDialogOpen(true);
                    if(response.error){
                        addAlert(JSON.stringify(_.map(response.error).join(' ')));
                    }else{
                        addAlert(JSON.stringify(response.message));
                    }
                }
            }
        }).catch(error => {
            setContinueDialogOpen(true);
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const backToSource = () => {
        setLoading(true);
        setVerifyOpen(false);
        let params = {
            url_type: 'account_link',
            source: source,
        }
        getUrl(`account-link/get-url`, params).then(response => {
            setLoading(false);
            if(response.status === 1){
                // console.log('window.location.href');
                window.location.href = response.data;
            }else{
                setVerifyOpen(true);
                addAlert(response.data);
            }
        }).catch((error) => {
            setLoading(false);
            setVerifyOpen(true);
            addAlert(JSON.stringify(error.message));
        });
    }

    return (
        <Box display="flex" justifyContent="flex-end">
            <Dialog open={verifyOpen} >
                <DialogTitle id="scroll-dialog-title">
                    <center><WebpImg src="/images/logo.png" style={{ width:'20%', paddingLeft: 10, paddingRight: 10 }} alt="logo" /></center>
                    <Typography variant="h6" style={{textAlign: "center"}}>{t('accountLink.linkAccountTitle', {'type': t('accountLink.'+type), 'source': source, 'source_user_id': source_username})}</Typography>
                </DialogTitle>
                <DialogContent dividers={false}>
                    <TextField
                        label={ t('register.email')+'/'+t('register.username') }
                        value={state.username}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><PersonIcon /></InputAdornment>,
                        }}
                        variant="outlined"
                        autoComplete="email"
                        color='primary'
                        fullWidth={true}
                        className={ styles.paddingVertical10 }
                        onChange={({ target }) => setState({ ...state, username: target.value }) }
                    />
                    <TextField
                        label={ t('register.password') }
                        type={state.showPassword ? 'text' : 'password'}
                        value={state.password}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>,
                            endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault() } edge="end">
                                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                        variant="outlined"
                        color='primary'
                        fullWidth={true}
                        className={ styles.paddingVertical10 }
                        onChange={({ target }) => setState({ ...state, password: target.value }) }
                    />
                </DialogContent>
                <DialogActions style={{justifyContent: "center"}}>
                    <Button variant="contained" color="primary" size="large" style={{width: "30%"}} onClick={linkUser}>
                        { type==='link'?t('accountLink.link'):t('accountLink.unlink') }
                    </Button>
                    <Button variant="contained" size="large" style={{width: "30%"}} onClick={backToSource}>
                        {t('accountLink.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={continueDialogOpen} >
                <DialogTitle id="scroll-dialog-title">
                    <center><WebpImg src="/images/logo.png" style={{ width:'20%', paddingLeft: 10, paddingRight: 10 }} alt="logo" /></center>
                    <Typography variant="h6" style={{textAlign: "center"}}>{t('accountLink.linkAccountTitle', {'type': t('accountLink.'+type), 'source': source, 'source_user_id': source_username})}</Typography>
                </DialogTitle>
                <DialogContent dividers={false}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                            <Avatar><PersonIcon fontSize="small" /></Avatar>
                        </Grid>
                        <Grid item xs={10}>{name}</Grid>
                        <Grid item xs={12}>
                            <Typography>
                                {t('accountLink.continueAsContent', {'source': source, 'type': type})}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => continueAs()} color="primary">{t("accountLink.continueYes")}</Button>
                    <Button variant="contained" onClick={() => dontContinueAs()} color="primary">{t("accountLink.continueNo")}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={redirectDialogOpen} >
                <DialogTitle id="scroll-dialog-title">{t('accountLink.linkSuccess', {'source': source})}</DialogTitle>
                <DialogContent dividers={false}>
                    <Typography style={{textAlign: "center", margin: "30px"}}>
                        { redirectMsg ? redirectMsg : t('accountLink.redirectInSec', {second: redirectSecond}) }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => window.location.href = redirectUrl} color="primary">{t("accountLink.backToSource", {'source': source})}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    },
    paddingVertical10: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    containerStyle: {
        padding: 20,
    }
}));