export const ENV = 'liv'; // 'dev';
export const APP_NAME = 'AtomsWellness';
export const PROJECT_TYPE = 'mall'; // 'mall/catalog';

export const BASE_URL = ENV === 'liv' ? '' : '';

const BACKEND_URL_LIST = {
    liv: `https://api.atomswellness.com`,
    stage: `https://dapi.atomswellness.com`,
    dev: 'http://127.0.0.1:8000',
};

const LOCAL_URL_LIST = {
    liv: `https://www.atomswellness.com`,
    stage: `https://dmall.atomswellness.com`,
    dev: `http://localhost:3000`,
};

export const BACKEND_URL = BACKEND_URL_LIST[ENV];
export const LOCAL_URL = LOCAL_URL_LIST[ENV];

export const CLIENT_SECRET = 'EykDL0NJzr2GydtmeMnTC2DI5xjwRUAv6adPEBes';

export const API_URL = `${BACKEND_URL}/api/`;

export const EXTERNAL_MEMBER_SITE = ENV === 'liv' ? '' : '';
export const EXTERNAL_LOGIN = ENV === 'liv' ? '' : '';
export const EXTERNAL_ABOUTUS = ENV === 'liv' ? '' : '';

export const UPAY_URL = ENV === 'liv' ? '' : '';
export const PAYPAL_URL = ENV === 'liv' ? '' : '';
export const STRIPE_KEY = ENV === 'liv' ? '' : '';

export const CONTACT_US_EMAIL = 'info@atomswellness.com';

export const PUSHER_KEY = ENV === 'liv' ? '' : '';
export const PUSHER_CLUSTER = 'ap1';
export const PUSHER_AUTH = `${BACKEND_URL}/broadcasting/auth`;
export const PUSHER_MESSAGE_CHANNEL = 'chat';
export const PUSHER_MESSAGE_EVENT = 'MessageSent';

export const FACEBOOK_APPID = ""; // 1neway
export const DEFAULT_LANGUAGE = "en"; // "en"

export const LOGIN_SECRET_KEY = "";

export const ISHOPPING_LOGIN = ENV === 'liv' ? '' : '';
