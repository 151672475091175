import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { getUrl } from '../helper/ApiAction';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { storeWalletBalance } from '../actions';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
    // CircularProgress, 
    Card, CardContent, Button, Typography, Container, Grid, IconButton,
    // Avatar,  
    Link, Box, Hidden,
    //  List, ListItem, 
     CardMedia, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, 
    //  Badge 
    } from '@material-ui/core';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

// import AnnouncementIcon from '@material-ui/icons/Announcement';
import { RssFeedOutlined } from '@material-ui/icons';
import TextLoop from "react-text-loop";

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import {  EXTERNAL_ABOUTUS } from '../configs/Config'; //EXTERNAL_LOGIN
import useNotificationLoading from '../helper/useNotificationLoading';
import WebpImg from './Layouts/WebpImg';
import TitleBar from './Layouts/TitleBar';

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Hidden only='xs'>
            <div
                className={className}
                style={{ ...style, display: "block", backgroundColor: 'transparent', borderRadius: 30, paddingTop: 1 }}
                onClick={onClick}
            />
        </Hidden>
    );
}
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Hidden only='xs'>
            <div
                className={className}
                style={{ ...style, display: "block", backgroundColor: 'transparent', borderRadius: 30, paddingTop: 1 }}
                onClick={onClick}
            />
        </Hidden>
    );
}

export default function Home() {
    // const [categories, setCategories] = useState(null);
    const [recommend, setRecommend] = useState(null);
    // const [suggestion, setSuggestion] = useState(null);
    const [collections, setCollections] = useState(null);
    const [slideBanner, setSlideBanner] =useState(null);
    // const [advBanner, setAdvBanner] =useState(null);
    // const [iconImg , setIconImg ]=useState();
    const [announcements, setAnnouncements] = useState([]);
    const [viewAnnouncement, setViewAnnouncement] = useState({});
    const [unreadCount, setUnreadAnnouncementCount] = useState(0);
    // const [walletBalance, setWalletBalance] = useState({});
    // const [topSellers, setTopSellers]=useState(null);
    // const [premiumStores,setPremiumStores]=useState(null);
    // const [sideImg, setSideImg] = useState({
    //     category: '',
    //     wishlist: '',
    //     order: '',
    // });

    const dispatch = useDispatch();
    const { accessToken, walletBalance } = useSelector(state => ({
        accessToken: state.general.accessToken,
        walletBalance: state.user.walletBalance,
    }));

    const { name, username, email, mobile, rank } = useSelector(state => state.user);
    let history = useHistory();
    const { addAlert } = useNotificationLoading();

   
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const isMountedRef = useRef(null);

    useEffect(() => {
        // showLoading();
        isMountedRef.current = true;
        // getUrl('categories?level=1').then(categoryList => {
        //     if (isMountedRef.current) {
        //         setCategories(categoryList.data);
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // });

        getUrl('products', { exclude: 'collections' }).then(productList => {
            if (isMountedRef.current) {
                setRecommend(productList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        // getUrl('products').then(productList => {
        //     if (isMountedRef.current) {
        //         setSuggestion(productList.data);
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // });

        getUrl('collections')
        .then(collectionList => {
            if (isMountedRef.current) {
                setCollections(collectionList.data);
            }
            // console.log("collectionList", collectionList);
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })

        getUrl('sliderbanners')
        .then(slideBannerList =>{
            if(isMountedRef.current)
            {
                setSlideBanner(slideBannerList.data);
            }
            // console.log("slideBannerList", slideBannerList);
        }).catch(error =>
            { 
                addAlert(JSON.stringify(error.message));
            });

        // getUrl('filebanners')
        // .then(advBannerList =>{
        //     if(isMountedRef.current)
        //     {
        //         setAdvBanner(advBannerList.data);
        //     }
        //     // console.log("fileBannerList", advBannerList);
        // }).catch(error =>
        //     { 
        //         addAlert(JSON.stringify(error.message));
        //     });

        if(accessToken){
            getUrl('wallets/balance').then(response => {
                // console.log('wallets/balance repsonse', response);
                if(response.status && isMountedRef.current) {
                    dispatch(storeWalletBalance(response.data));
                    // setWalletBalance(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
            
            getUrl('member_announcements').then(res => {
                if (isMountedRef.current && res.status) {
                    setAnnouncements(res.data);
                    setUnreadAnnouncementCount(res.new_count);
                    let show  = { 0 :false};
                    let today = new Date();
                    
                    _.map(res.data, (row, index)=>{
                        if(row.popup && _.size(row['users']) === 0 && today >= new Date(row.popup_sdate) && today <= new Date(row.popup_edate) ) show[index] = true;
                    });
                    // console.log(show);
                    setViewAnnouncement({...viewAnnouncement, ...show});
                    
                }
            }).catch(error => {
                // addAlert(JSON.stringify(error.message));
            });
        }

        // getUrl('get_premium_stores')
        // .then(premiumStoreList =>{
        //     if(isMountedRef.current)
        //     {
        //         setPremiumStores(premiumStoreList.data);
        //     }
        // }).catch(error =>
        //     { 
        //         addAlert(JSON.stringify(error.message));
        //     });
        
        // getUrl('get_top_sellers')
        // .then(topSellerList =>{
        //     if(isMountedRef.current)
        //     {
        //         setTopSellers(topSellerList.data);
        //     }
        // }).catch(error =>
        //     { 
        //         addAlert(JSON.stringify(error.message));
        //     });
        // hideLoading();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (viewAnnouncement !== "") {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [viewAnnouncement]);

    const closeAnnouncement = (index) => {
        setViewAnnouncement({ ...viewAnnouncement, [index]: false });
        getUrl(`member_announcements/${announcements[index]['id']}`).then(result => {
            if (result.status === 1) {
                setUnreadAnnouncementCount(unreadCount - 1);
            }
        }).catch((error) => {
        });
    }

    // useEffect(() => {
    //     const defaultLang = i18n.language ? (_.includes(['en', 'cn'], i18n.language) ? i18n.language : 'en') : 'en';
    //     setSideImg({
    //         // category: `/images/side_image/btn-category-${defaultLang}.png`,
    //         wishlist: `/images/side_image/btn-wishlist-${defaultLang}.png`,
    //         order: `/images/side_image/btn-order-${defaultLang}.png`,
    //         daily: `/images/side_image/daily-${defaultLang}.png`,
    //         recommended: `/images/side_image/recommended-${defaultLang}.png`,
    //     })
    // }, [i18n.language])

    // useEffect(function getLandingIcon() {

    //     if (accessToken) {
    //         isMountedRef.current = true;
    //         getUrl('user/landingicon')
    //             .then(iconImage => {
    //                 if (isMountedRef.current) {
    //                     setIconImg(iconImage.data)
    //                 }
    //                 // console.log("iconImage", iconImage);
    //             }).catch(error => {
    //                 // addAlert(JSON.stringify(error.message));
    //             });
    //         // hideLoading();
    //         return () => { isMountedRef.current = false };
    //     }
    // }, []);
    

    const productItemCard = (product) => {
        // console.log("product", product);
        // let starter = rank ? ((rank === null || product.rank.code > rank.code) ? true : false) : true;
        // let bvDisplay = product.type === 'rank' ? (starter ? product.bv_starter : product.bv_repurchase) : product.bv;
        // let priceDisplay = product.type === 'rank' ? (starter ? product.price_starter : product.price_repurchase) : product.sell_price;
        let bvDisplay = product.bv;
        let priceDisplay = product.sell_price;

        let showRetail = false;
        let discountPercent = 0;
        if(parseFloat(product.retail_price) > 0 && parseFloat(product.retail_price) > parseFloat(priceDisplay)) {
            showRetail = true;
            discountPercent = ((parseFloat(product.retail_price) - parseFloat(priceDisplay)) / parseFloat(product.retail_price)) * 100;
            discountPercent = Math.round(discountPercent);
        }
        return(
            <Link underline='none' to={`/item/${product.id}`} component={RouterLink}>
                <Card variant="outlined" className={styles.productHover} style={{ borderRadius: '15px', height: '100%', position: 'relative' }}>
                    <CardMedia style={{ padding: 10, textAlign: "center" }}>
                        <WebpImg
                            containerStyle={{ height: 150, maxHeight: 150, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            src={product ? product.images_array[0] : ''}
                            style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 195, maxHeight:150, margin: "auto" }}
                            alt={product[`title_${i18n.language}`]}
                        />
                        {
                            product.user_wishlist ?
                            <FavoriteIcon fontSize="small" style={{ color: 'red', position: 'absolute', right: 10 }} />
                            : null
                        }
                    </CardMedia>
                    <CardContent style={{ padding: '10px', textAlign: "center" }}>
                        <Tooltip title={product[`title_${i18n.language}`]}>
                            <Typography variant="body2" color="textSecondary" noWrap style={{}}>
                                {product[`title_${i18n.language}`]}
                            </Typography>
                        </Tooltip>
                        <div style={{display:'flex', justifyContent:'space-around', alignItems:'center', marginTop:8}}>
                            {/* { _.size(product.ipoint) > 0 && parseFloat(product.ipoint[0].ipoint) > 0 ? 
                                <div style={{ marginLeft: 2, marginRight: 2, backgroundColor: theme.palette.primary.main, width:'49%', borderTopLeftRadius: 11, borderBottomRightRadius: 11, boxShadow:'2px 3px 2px 0 #ead4d4' }}>
                                    <Typography style={{ color: '#fff', fontSize: '.625rem', padding:'3px 5px' }} variant="body2">
                                        <NumberFormat value={parseFloat(product.ipoint[0].ipoint)} decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` IP`} />
                                    </Typography>
                                </div>
                            : null } */}
                            { _.size(bvDisplay) > 0 && parseFloat(bvDisplay) > 0 ? 
                                <div style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#fa9f2d', width:'49%', borderTopLeftRadius: 11, borderBottomRightRadius: 11, boxShadow:'2px 3px 2px 0 #ffe3bf' }}>
                                    <Typography style={{ color: '#fff', fontSize: '.625rem', padding:'3px 5px' }} variant="body2">
                                        <NumberFormat value={parseFloat(bvDisplay)} decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` BV`} />
                                    </Typography>
                                </div>
                            : null }
                        </div>
                        {
                            showRetail
                            ?
                            <Typography align="center" variant="body2" style={{ fontSize: 10, height:18, fontWeight: 'bold', textDecoration: 'line-through' }}>
                                <NumberFormat value={product.retail_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (product.currency_display_cn ? product.currency_display_cn : product.currency_display_en) : product.currency_display_en} `} />
                            </Typography>
                            :
                            <Typography style={{ height: 18 }}></Typography>
                        }
                        <Typography align="center" variant="body1" color="secondary" style={{ fontSize: 14, fontWeight: 'bold' }}>
                            <NumberFormat value={priceDisplay} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (product.currency_display_cn ? product.currency_display_cn : product.currency_display_en) : product.currency_display_en} `} />
                        </Typography>
                    </CardContent>
                    {
                        showRetail ?
                        <Box position="absolute" top={0} right={0} style={{ backgroundColor: 'rgba(255,212,36,.9)' }} padding={1}>
                            <Typography variant="caption" style={{ fontWeight: 'bold' }} color="error">{`${discountPercent}% ${t('general.discount')}`}</Typography>
                        </Box>
                        : null
                    }
                    {/* <Box display="flex" alignItems="center" paddingY={.5} marginBottom={1.5} justifyContent="center" style={{ width: '100%', backgroundColor:'#f75757' }}>
                    {
                        _.map(product.ipoint, (idata, key) => {
                            if(idata.warehouse_id === 0){
                                return (
                                    <Typography key={key} style={{ color: '#fff', fontSize:16 }} variant="body2">
                                        <NumberFormat value={ parseFloat(idata.ipoint) } decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` i-Point`} />
                                    </Typography>
                                )
                            }
                        })
                    }
                    </Box> */}
                </Card>
            </Link>
        )
    }

    return (
        <Box style={{ background: theme.palette.white.mobileBkg, minHeight: '100%' }}>
            
            { _.size(walletBalance) ? 
            <>
            <TitleBar height={141} title={t('title.home')} displayQr displayCart linkMall />
            <Box style={{ padding: '0 15px', marginTop: '-37px', marginBottom:28 }}>
                <Box className={styles.goldFrame}>
                    <Box className={styles.frameBkg} style={{ padding: 5 }}>
                    {/* '10px 15px 15px' */}
                        <Box className='cnyFrameTop'>
                        <Box className='cnyFrameBottom' style={{ display: 'flex', justifyContent: 'space-around',  padding:'13px 0 13px 0 ', position:'relative'}}>
                           {_.map(walletBalance, (walletItem, index) => {
                                let name =  _.split(walletItem.name, "|");
                                return(
                                    <React.Fragment key={index}>
                                    {/* {index > 1 ? <Divider orientation="vertical" flexItem style={{ backgroundColor: theme.palette.gray.text }} /> : null} */}
                                    <Box style={{ textAlign: 'center', color: theme.palette.primary.main,}}>
                                        <Typography style={{ fontSize: 21 }}><b>{Number.parseFloat(walletItem.balance).toFixed(2)}</b></Typography>
                                        <Typography style={{ fontSize: 12 }}>{(i18n.language === "cn" && name[1] ? name[1] : name[0])}</Typography>
                                    </Box>
                                    </React.Fragment>
                                )
                            })}

                            {/* <Box style={{position: 'absolute', right: 15, top: -24}}>
                                <img src='images/cny/drum.png' style={{width:120}}/>
                            </Box> */}
                        </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            </>
            : <div style={{backgroundColor: theme.palette.primary.main, display:'flex', justifyContent:'space-between', alignItems:'center', padding:'9px 15px 6px' }}>
                <Link underline='none' to="/" component={RouterLink} className={styles.iconStyle}>
                    <WebpImg src="/images/logo.png" style={{ height: 38, }} alt=" logo" />
                    {/* <Typography className={styles.title} variant="h6">
                            DEMO
                        </Typography> */}
                </Link>
                <Link underline='none' color="inherit" to="/login"  component={RouterLink}>
                    <div style={{backgroundColor:theme.palette.white.main, color:theme.palette.primary.main, borderRadius:5, padding:'5px 15px'}}>
                    {t('button.login')}
                    </div>
                </Link>
            </div>
            }

            { _.size(announcements) > 0 ?
                    <Box  paddingX={2} paddingY={1} marginTop={2} width="100%" style={{boxShadow:'inset 4px 20px 47px 0 #fff, inset 0 -5px 20px 0 #00000029', paddingBottom:16}}>
                    {/* <ChatBubbleIcon fontSize="small" /> */}
                    <Box display="flex" flexDirection="row" alignItems="center">
                    <img src="/images/general/annoucement.png" style={{width:30}}/>
                    <div style={{ overflow: 'hidden' }}>
                        <TextLoop interval={5000}>
                            { _.map(announcements, announcementItem => {
                                return (
                                    <Link key={announcementItem.id} to={`/announcement/${announcementItem.id}`} component={RouterLink}>
                                        <Typography noWrap variant="body2" className={styles.loopText} style={{color:'#494949', paddingLeft:20}}>
                                        { announcementItem[`title_${i18n.language}`] }
                                        </Typography>
                                    </Link>
                                )
                            })}
                        </TextLoop>
                    </div>
                    </Box>
                </Box>
            : null }

            <Container fixed className={styles.root} style={{padding:20}}>
                <Grid container spacing={1} justify="center" style={{padding:0, boxShadow:'0px 6px 11px 0 #00000026', borderRadius:15}}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <AutoplaySlider
                            play={true}
                            cancelOnInteraction={false}
                            interval={3000}
                            autoplay={true}
                            bullets={false}
                            // media={[
                            //     { source: '/images/slides/slide1.png', },
                            // ]}
                            // style={{ width: '100%' }}
                            organicArrows={false}
                        >
                             {
                                _.size(slideBanner) > 0 && slideBanner !== null ?
                                    _.map(slideBanner, (slideBannerLists) => {
                                        return (
                                            <div className="awssld__content" key={slideBannerLists.id} >
                                                <WebpImg src={slideBannerLists.file_name} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%' }} />
                                            </div>
                                        )
                                    }):
                                    <div>
                                    </div>
                            } 
                            {/* <div className="awssld__content">
                                <WebpImg src={`/images/cny/cnybanner-${i18n.language}.png`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%' }} />
                            </div> */}
                            {/* <div className="awssld__content">
                                <WebpImg src={`/images/slides/mistore-online-${i18n.language}.png`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%' }} />
                            </div> */}
                            {/* <div className="awssld__content">
                                <WebpImg src={`/images/slides/iphone-${i18n.language}.png`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%' }} />
                            </div>
                            <div className="awssld__content">
                                <WebpImg src={`/images/slides/banner.jpg`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%', }} />
                            </div>
                            <div className="awssld__content">
                                <WebpImg src={`/images/slides/banner.jpg`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%', }} />
                            </div>*/} 
                        </AutoplaySlider>
                    </Grid>
                </Grid>

                {/* <div style={{padding:'40px 0 20px'}}>
                    <div>
                        <Typography variant="h5" style={{ fontWeight: 'bold', color:theme.palette.primary.main }}>New Event</Typography>
                        <Link underline='none' to={(accessToken ? "/eventPhone" : "/login")} component={RouterLink} className={styles.iconStyle}>
                        <WebpImg src={`/images/slides/iphone13-${i18n.language}.png`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%', borderRadius:15, boxShadow:'rgb(67 36 14 / 32%) 2px 3px 8px 0px' }} />
                        </Link>
                    </div>
                </div> */}
                {/* <div style={{textAlign:'center', marginTop:'15px'}}>
                    All Payment Must Be Make Payable To : OTTO SCIENCE HOLDINGS SDN BHD
                </div> */}
                {
                    _.size(collections) > 0 ?
                        _.map(collections, collectionItem => {
                            if(_.size(collectionItem.product_ids_array) > 0) {
                                return (
                                    <Grid key={collectionItem.id} container spacing={1} justify="center">
                                        <Grid item xs={12} sm={12} style={{ padding: "0px 15px", textAlign: 'center' }}>
                                            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" paddingTop={2} paddingRight={1}>
                                                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                                {/* <img src='images/cny/title-icon.png' style={{width:48, marginRight:0}}/> */}
                                                <Typography variant="h5" style={{ fontWeight: 'bold', color:theme.palette.primary.main }}>{collectionItem[`title_${i18n.language}`]}</Typography>
                                                </Box>
                                                <Link underline='none' color="inherit" to={`/collection/${collectionItem.id}`} component={RouterLink}>
                                                    <Typography color="primary" style={{ fontSize: 14, flex: 1 }}>{ `${t('shop.seeAll')} >` }</Typography>
                                                </Link>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Slider
                                                infinite={true}
                                                speed={500}
                                                slidesToShow={3}
                                                slidesToScroll={3}
                                                autoplay={true}
                                                pauseOnHover={true}
                                                responsive={[
                                                    { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 2, infinite: true, dots: false } },
                                                    { breakpoint: 992, settings: { slidesToShow: 3, slidesToScroll: 1, initialSlide: 2 } },
                                                    { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 1, initialSlide: 2 } },
                                                    // { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
                                                ]}
                                                nextArrow={<NextArrow />}
                                                prevArrow={<PrevArrow />}
                                                // style={{ marginLeft: -5 }}
                                            >
                                                {
                                                    _.map(_.slice(collectionItem.product_ids_array, 0, 6), (product) => {
                                                        return (
                                                            <Grid item xs={12} key={product.id} className={styles.cardContentRootStyle}>
                                                                {productItemCard(product)}
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        })
                    : null
                }
                <Box style={{backgroundColor:'transparent', height:15, width:'100%', height: 9, width: '100%', marginBottom:10, boxShadow:'0px 7px 15px 0 #22222226'}} />
                <Box style={{padding:'0 15px'}}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" paddingRight={1} paddingTop={2}>
                        
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            {/* <img src='images/cny/title-icon.png' style={{ width: 48, marginRight: 0 }} /> */}
                            <Typography variant="h5" style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{t('home.dailyRecommend')}</Typography>
                        </Box>
                        <Link underline='none' color="inherit" to={`/category/all`} component={RouterLink}>
                            <Typography color="primary" style={{ fontSize: 14, flex: 1 }}>{`${t('shop.seeAll')} >`}</Typography>
                        </Link>
                    </Box>
                </Box>
                <Grid container spacing={1} justify="center" style={{paddingLeft:13, paddingRight:13}}>
                    {/* <Hidden smDown>
                        <Grid item xs={6} sm={4} md={2} className={styles.cardContentRootStyle}>
                            <Box style={{ padding: '5px 0', height: '100%' }}>
                                <Box style={{ height: '100%', background: '#fecc3e', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '15px' }}>
                                    <Card style={{ marginBottom: 10, borderRadius: 15, maxWidth: '93%', backgroundColor: 'transparent', boxShadow: 'none' }}>
                                        <CardMedia style={{ height: '100%' }}>
                                            <WebpImg src={`/images/side_image/daily-${i18n.language}.png`} />
                                        </CardMedia>
                                    </Card>
                                </Box>
                            </Box>
                        </Grid>
                    </Hidden> */}
                    {/* <Grid component={Box}  width={1}> px={{ xs: 0, sm: 4 }} */}
                            {
                                _.map(recommend, (product) => {
                                    return (
                                        <Grid item xs={6} sm={4} key={product.id} className={styles.cardContentRootStyle}>
                                            {productItemCard(product)}
                                        </Grid>
                                    )
                                })
                            }
                    {/* </Grid> */}
                </Grid>
                {
                    _.size(announcements) ? 
                    _.map(announcements, (row, index) => (
                        row.popup && new Date() >= new Date(row.popup_sdate) && new Date() <= new Date(row.popup_edate) ? 
                        <Dialog
                            open={viewAnnouncement[index] ? true : false}
                            onClose={() => closeAnnouncement(index)}
                            scroll="body"
                            aria-labelledby="view Announcement"
                            aria-describedby="view Announcement detail"
                            key={index}
                        >
                            <DialogTitle id={`scroll-dialog-title${index}`}>{row[`title_${i18n.language}`]}</DialogTitle>
                            <DialogContent dividers={false}>
                                <div
                                    id="viewAnnouncement"
                                    ref={descriptionElementRef}
                                    tabIndex={-1}
                                    
                                >
                                    {row['image'] ?
                                        // <img src={announcements[viewAnnouncement]['image']['file_name']} style={{ maxWidth: "80vw"}}></img>
                                        <WebpImg containerStyle={{ maxWidth: "80vw", display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={row['image']['file_name']} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', maxWidth: "80vw" }} alt="announcement" />
                                        : null
                                    }
                                    <div dangerouslySetInnerHTML={{ __html: row['content'][`${i18n.language}`] }} />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => closeAnnouncement(index)} color="primary">{t("button.close")}</Button>
                            </DialogActions>
                        </Dialog>
                        : null
                    ))
                    : null
                }
            </Container>
            <div style={{textAlign:'center'}}>License by MI</div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    cardContentRootStyle: {
        padding: '10px !important',
    },
    cardRootStyle: {
        margin: '0px 1px 0px 1px'
    },
    buttonFont: {
        fontSize: '10px',
    },
    buttonStyle: {
        margin: '5px',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    listItemStyle: {
        justifyContent: 'center',
        padding: '5px 0px 5px 0px',
    },
    cardStyle: {
        // minHeight: 380,
        borderRadius: 0
    },
    overlay: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        color: 'black',
        backgroundColor: 'white'
    },
    goldFrame: {
        boxShadow: 'rgb(0 0 0 / 12%) -2px 1px 20px 0',
        // padding: 5,
        // borderRadius: 15,
        borderRadius: 8,
        background: '#fff',
        // background: 'rgb(168,120,33)',
        // background: 'linear-gradient(90deg, rgba(168,120,33,1) 0%, rgba(252,242,169,1) 19.59%, rgba(255,196,79,1) 50%, rgba(252,237,166,1) 80.42%, rgba(184,140,54,1) 100%)'
    },
    frameBkg: {
        // background: 'background: rgb(209,170,105)',
        // background: 'linear-gradient(180deg, rgba(209,170,105,1) 0.81%, rgba(234,205,158,1) 21.88%, rgba(233,204,155,1) 77.03%, rgba(225,185,110,1) 100%)',
        // background: 'linear-gradient(180deg, #050a12 0.81%, #182a49 21.88%, #182a49 77.03%, #0b162a 100%)',
        // borderRadius: 11,
        // background: '#fff',
    },
    loopText: {
        width: '85vw',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    productHover:{
        top: 0,
        transition: '0.5s',
        "&:hover":{
            boxShadow:'0 0 19px 0 #00000029',
            top: -5,
        }
    }
}));