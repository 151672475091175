import React, { useEffect, useState } from 'react';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Box, BottomNavigationAction, BottomNavigation, Link, Typography } from '@material-ui/core';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { API } from '../../configs/AxiosConfig';
import { changeLanguage as reduxChangeLanguage } from '../../actions';


import { Link as RouterLink } from 'react-router-dom';
import { AccountTreeOutlined, AccountBalanceWalletOutlined } from '@material-ui/icons';
import { FiUser, FiHome, FiFileText } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import theme from '../../Theme';

const HIDE_COMPONENT_PAGE = ["cart", "checkout", "payment","chat", "customerservice"];

export default function Footer() {
	const classes = useStyles();

	const [value, setValue] = React.useState(0);
	const { accessToken } = useSelector(state => state.general);

	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		if (_.includes(['en', 'cn'], i18n.language) === false) {
			changeLanguage('en');
		}
		API.defaults.headers.common['Language'] = i18n.language === 'cn' ? 'zh-CN' : 'en';
		// eslint-disable-next-line
	}, [i18n.language, t]);

	const changeLanguage = lang => {
		i18n.changeLanguage(lang);
		dispatch(reduxChangeLanguage(lang));
	};

	const [currentPath, setCurrentPath] = useState();

	useEffect(() => {
		let active = true;

		if (active) {
			const path = _.split(location.pathname, '/');
			if (path) {
				setCurrentPath(path[1].toLowerCase());
				if(path[1].toLowerCase() === "home"){
					setValue(0);
				}
			}
		}
		return () => { active = false };
	}, [location.pathname])

	return (
		<div >
			{
				_.includes(['comingsoon'], currentPath) ?
					null
					:
					_.includes(HIDE_COMPONENT_PAGE, currentPath) === false ?
						<>
							<Box style={{ display: 'flex', justifyContent: 'space-around', width: '100%', backgroundColor:'#b12622', padding:'8px 0',    position: 'fixed', bottom: 0, width: 600, maxWidth: '100%',zIndex: 9 }} paddingBottom={0}>
								{/* <Link underline='none' component={RouterLink} to="/home">
									<Box style={{display:'flex', justifyContent:'center', alignItems:' center', flexDirection:'column'}}>
										<img src='/images/general/icon/cny-home.png' style={{ width: 45 }} />
										<Typography style={{color:'#ffd23d', fontSize:12, textTransform:'uppercase'}}>{t('title.home')}</Typography>
									</Box>
								</Link>

								<Link underline='none' component={RouterLink} to="/contract">
									<Box style={{display:'flex', justifyContent:'center', alignItems:' center', flexDirection:'column'}}>
										<img src='/images/general/icon/cny-list.png' style={{ width: 45 }} />
										<Typography style={{color:'#ffd23d', fontSize:12, textTransform:'uppercase'}}>{t('title.activity')}</Typography>
									</Box>
								</Link>

								<Link underline='none' component={RouterLink} to="/community/genealogySponsor">
									<Box style={{display:'flex', justifyContent:'center', alignItems:' center', flexDirection:'column'}}>
										<img src='/images/general/icon/cny-community.png' style={{ width: 45 }} />
										<Typography style={{color:'#ffd23d', fontSize:12, textTransform:'uppercase'}}>{t('title.group')}</Typography>
									</Box>
								</Link>

								<Link underline='none' component={RouterLink} to={(accessToken ? "/wallet" : "/login")}>
									<Box style={{display:'flex', justifyContent:'center', alignItems:' center', flexDirection:'column'}}>
										<img src='/images/general/icon/cny-wallet.png' style={{ width: 45 }} />
										<Typography style={{color:'#ffd23d', fontSize:12, textTransform:'uppercase'}}>{t('title.wallet')}</Typography>
									</Box>
								</Link>

								<Link underline='none' component={RouterLink} to={(accessToken ? "/setting" : "/login")}>
									<Box style={{display:'flex', justifyContent:'center', alignItems:' center', flexDirection:'column'}}>
										<img src='/images/general/icon/cny-setting.png' style={{ width: 45 }} />
										<Typography style={{color:'#ffd23d', fontSize:12, textTransform:'uppercase'}}>{t('title.profile')}</Typography>
									</Box>
								</Link> */}
								
								<ThemeProvider theme={bottomNavStyle}>
									<BottomNavigation
										value={value}
										onChange={(event, newValue) => {
											setValue(newValue);
										}}
										// showLabels
										className={classes.root}
										style={{ position: 'fixed', top: 'auto', bottom: 0, width: 600, maxWidth: '100%', boxShadow: '0 2px 8px 0 #0007', paddingTop: 10, boxShadow: 'rgb(0 0 0 / 8%) 2px -5px 10px 0px' }}
									>
										<BottomNavigationAction
											// to={(accessToken ? "/home" : "/login")}
											to="/home"
											component={RouterLink}
											label={t('title.home')}
											style={{ fontSize: 26 }}
											icon={<FiHome />}
										/>
										{/* <BottomNavigationAction
											to="/contract"
											disabled
											component={RouterLink}
											label={t('title.activity')}
											style={{ fontSize: 26 }}
											icon={<FiFileText />}
										/> */}
										<BottomNavigationAction
											to="/community/genealogySponsor"
											component={RouterLink}
											label={t('title.group')}
											style={{ fontSize: 26 }}
											icon={<AccountTreeOutlined />}
										/>
										<BottomNavigationAction
											to={(accessToken ? "/wallet" : "/login")}
											// disabled
											component={RouterLink}
											label={t('title.wallet')}
											style={{ fontSize: 26 }}
											icon={<AccountBalanceWalletOutlined />}
										/>
										<BottomNavigationAction
											to={(accessToken ? "/setting" : "/login")}
											component={RouterLink}
											label={t('title.profile')}
											style={{ fontSize: 26 }}
											icon={<FiUser />}
										/>
									</BottomNavigation>
								</ThemeProvider>
								{/* </div> */}
							</Box>
						</>
						: null
			}
		</div>
	)
}

const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	listItemStyle: {
		padding: '2px 0px 2px 0px',
	},
	footerFont: {
		fontSize: '13px',
		color: '#e53935',
	},
	iconStyle: {
		display: 'flex',
		alignItems: 'center',
		color: 'black'
	},
	appBar: {
		top: 'auto',
		bottom: 0,
	},

}));

const bottomNavStyle = createMuiTheme({
	overrides: {
		MuiBottomNavigation: {
			root: {
				backgroundColor: theme.palette.primary.main,
				zIndex: 3,
			},
		},
		MuiButtonBase: {
			root: {
				'&$disabled': {
					color: '#ffffff82'
				},
			},
		},
		MuiBottomNavigationAction: {
			root: {
				minWidth: 65,
				color: "#fff",
				"&$selected": {
					color: '#fff',
					fontSize: '0.75rem'
				},
			}
		}
	},
});