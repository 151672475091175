import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, Typography, Box, AppBar, Tabs, Tab, Divider } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import GenealogyPlacement from './GenealogyPlacement';
import GenealogySponsor from './GenealogySponsor';
import TitleBar from './Layouts/TitleBar';
import "./css/tree.css";

import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';

export default function Genealogy(props) {
    const [state, setState] = useState({
        pageIndex: null,
        subPage: 'genealogySponsor',
        totalDirectDownlines: 0,
        totalDownlines: 0
    });

    const { t } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let history = useHistory();
    let { page } = useParams();
    let username = useQuery().get('username');

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        if(page) {
            if(_.includes(['genealogySponsor', 'genealogyPlacement'], page)) {
                setState({ 
                    ...state, 
                    pageIndex: page === 'genealogyPlacement' ? 1 : 0,
                    subPage: page,
                })
            }
        }
    }, [page]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        if (state.pageIndex === 0) {
            getUrl(`genealogy/sponsor`).then(response => {
                if (response.status && isMountedRef.current) {
                    // setTree(response.data.tree_data);
                    setState({ ...state, totalDirectDownlines: response.data.total_direct_downlines, totalDownlines: response.data.total_downlines });
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        } else if (state.pageIndex === 1) {
            getUrl(`genealogy/placement`).then(response => {
                if (response.status && isMountedRef.current) {
                    // setData(response.data.tree_data);
                    setState({ ...state, totalDirectDownlines: response.data.total_direct_downlines, totalDownlines: response.data.total_downlines });
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        }

        return () => isMountedRef.current = false;
    }, [state.pageIndex]);

    const displayPage = () => {
        if(state.subPage =='genealogySponsor'){
            return <GenealogySponsor />
        } else if(state.subPage =='genealogyPlacement'){
            return <GenealogyPlacement />
        }
    }

    const handleChange = (event, newValue) => {
        history.push(`/community/${newValue === 1 ? 'genealogyPlacement' : 'genealogySponsor'}`);
    };

    return (
        <Box style={{ background: theme.palette.white.mobileBkg, padding: '0 0 80px', minHeight: '100%' }}>
            <TitleBar height={141} title={t('title.group')} displayQr />
            <Box style={{ padding: '0 15px', marginTop: '-37px' }}>
                <div className={styles.goldFrame}>
                    <div className={styles.frameBkg}>
                        <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Box style={{ textAlign: 'center', color: theme.palette.primary.main }}>
                                <Typography style={{ fontSize: 21 }}><b>{state.totalDirectDownlines}</b></Typography>
                                <Typography style={{ fontSize: 12 }}>{t('genealogy.totalDirectDownlines')}</Typography>
                            </Box>
                            <Divider orientation="vertical" flexItem style={{ backgroundColor: theme.palette.gray.text }} />
                            <Box style={{ textAlign: 'center', color: theme.palette.primary.main }}>
                                <Typography style={{ fontSize: 21 }}><b>{state.totalDownlines}</b></Typography>
                                <Typography style={{ fontSize: 12 }}>{t('genealogy.totalDownlines')}</Typography>
                            </Box>
                        </Box>
                    </div>
                </div>
            </Box>

            { !_.isNull(state.pageIndex) ? <>
                <AppBar position="static" color="default" style={{ backgroundColor: 'transparent', boxShadow: 'none', paddingTop: 20 }}>
                    <Tabs
                        value={state.pageIndex}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label={t('profile.sponsorGenealogy')} />
                        <Tab label={t('profile.placementGenealogy')} />
                    </Tabs>
                </AppBar>
                <div>
                    {/* /////////  Sponsor Genealogy /////////*/}
                    <TabPanel value={state.pageIndex} index={0} dir={theme.direction} style={{ padding: '0 0 24px' }}>
                        <Grid item xs={12} className={styles.root} >
                            { displayPage() }
                        </Grid>
                    </TabPanel>

                    {/* /////////  Placement Genealogy /////////*/}
                    <TabPanel value={state.pageIndex} index={1} dir={theme.direction}>
                        <Grid item xs={12} className={styles.root} >
                            { displayPage() }
                        </Grid>
                    </TabPanel>
                </div>
            </> : null }
        </Box>
    )
}


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    tree: {
        // height: 110,
        flexGrow: 1,
        // maxWidth: 400,
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelText: {
        fontWeight: 'inherit',
        marginLeft: 10,
        textTransform: "lowercase"
    },
    labelSubText: {
        marginLeft: 10,
        fontStyle: 'italic'
    },
    goldFrame: {
        boxShadow: 'rgb(0 0 0 / 32%) 0 0 5px 0',
        padding: 5,
        borderRadius: 15,
        background: '#fff',
        // background: 'rgb(168,120,33)',
        // background: 'linear-gradient(90deg, rgba(168,120,33,1) 0%, rgba(252,242,169,1) 19.59%, rgba(255,196,79,1) 50%, rgba(252,237,166,1) 80.42%, rgba(184,140,54,1) 100%)'
    },
    frameBkg: {
        // background: 'background: rgb(209,170,105)',
        // background: 'linear-gradient(180deg, rgba(209,170,105,1) 0.81%, rgba(234,205,158,1) 21.88%, rgba(233,204,155,1) 77.03%, rgba(225,185,110,1) 100%)',
        // background: 'linear-gradient(180deg, #050a12 0.81%, #182a49 21.88%, #182a49 77.03%, #0b162a 100%)',
        // borderRadius: 11,
        background: '#fff',
        padding: '10px 15px 15px'
    }
}));