import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Container, Box } from '@material-ui/core';
import TitleBarWhite from './Layouts/TitleBarWhite';


export default function TermOfUse() {
    const { t } = useTranslation();

    return (
        <Box>
            <div style={{position:'fixed', width:600, maxWidth:'100%'}}>
                <TitleBarWhite height={0} title={t('support.merchantServicesAgreement')} back  displayInfo={false} />
            </div>
            <div style={{ textAlign: 'center', padding: '60px 0 0' }}>
                <Typography style={{ fontSize: '28px', color: '#777' }}>{t('support.merchantServicesAgreement')}</Typography>
            </div>

            <div style={{ backgroundColor: '#fff', padding: '5px 0', color: '#666', lineHeight: '1.7' }}>
                <Container>
                    <p>This Merchant Services Agreement (the "Agreement") is a legal and binding agreement between you, the Merchant (as hereinafter defined) and Mose Net System Solution Sdn Bhd ("the Company").</p>
                    <p>By applying for the products and/or services through our online application process or otherwise or by using the services defined below, you acknowledge that you have read and agree to be bound by (and that your company will be bound by) all of the terms and conditions of this Agreement and all documents incorporated by reference. If you do not agree to be bound by the terms of this Agreement, please do not use the services..</p>
                    <p>The Company reserves the right to notify you from time to time of changes to the terms and conditions of this Agreement.</p>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Definitions</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>1.	In this Agreement, the following expressions shall have the respective meanings set out as follows (except to the extent that the context requires otherwise):
                            <p><b style={{ color: '#333' }}>"Agreement"</b> means this Agreement, including the Application Form, Fee Structure and the appendices attached hereto.</p>
                            <p><b style={{ color: '#333' }}>"Application Form" </b>means the Company's application form which is used by the Merchant to apply for Otto products or other relevant services. The application form shall include relevant information, including but not limited to, the Merchant's particulars, the products or services requested, the fee structure form ("Fee Structure") and any other related forms required by the Payment Gateway.</p>
                            <p><b style={{ color: '#333' }}>"Company" </b>means Mose Net System Solution Sdn Bhd (Company No. 943548-D), a company incorporated in Malaysia and providing an end-to-end e-commerce platform for retail businesses (hereinafter referred to as "Otto").</p>
                            <p><b style={{ color: '#333' }}>"Fees" </b>mean subscription fee and transaction fee set forth in the Application Form, Fee Structure and the appendices attached hereto.</p>

                            <p><b style={{ color: '#333' }}>"Marks" </b>mean the names, marks, designs, logos, signs, acronyms and other insignia (whether registered or unregistered) used or to be used by the Company in connection with the Products and/or Services including all variations thereof and amendments thereto from time to time.</p>
                            <p><b style={{ color: '#333' }}>"Merchant" </b>means the party which has signed up through the Company's Application Form to use the Products or relevant services as defined below.</p>
                            <p><b style={{ color: '#333' }}>"Products" </b>mean the Company's products and other related services.</p>
                            <p><b style={{ color: '#333' }}>"Regulatory Authority" </b>means any ministry or department of the Government of Malaysia and includes any statutory authority or body in Malaysia established with powers to regulate banking, credit or financial services and/or electronic transactions or communications.</p>
                            <p><b style={{ color: '#333' }}>"Services" </b>mean the range of services which the Merchant is authorised to use or to provide to the Merchant's users pursuant to this Agreement as may be authorised by the Company from time to time.</p>
                            <p><b style={{ color: '#333' }}>"Transaction(s)" </b>means transactions effected and services obtained by the Merchant's users.</p>
                        </li>
                        <li>2.	This Agreement shall apply to any and all Products and/or the Services that the Merchant subscribes from the Company. The Merchant agrees to abide by the additional terms and conditions set out in the Application Form, Fee Structure and the appendices attached to the Agreement, which are incorporated into this Agreement.</li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Payments</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            <p>3.	All Transactions shall be made in Malaysia Ringgit.</p>
                            <p>4.	The Merchant shall be liable to make payments due to the Company arising from this Agreement in accordance with the provisions herein.</p>
                            <p>5.	In consideration of subscribing and using the Company's Products and/or the Services, the Merchant shall pay the Company the applicable Fees and other charges at such time as may from time to time be stipulated by the Company as set forth in the Application Form or as otherwise provided by the Company in accordance with the terms of this Agreement.</p>
                            {/* <p>6.	Unless otherwise specified herein, the Products and/or the Services are for a periodically renewing subscription plan ("Pro") or a free version of the subscription plan with a number of limitations of use ("Lite"). The description of the subscription options for the Products and/or the Services are currently available on the website being Otto.link, mobile site and/or applications (the "Website/Apps"). The Pro subscription plan is for one-year initial term and renewable thereafter for successive one-year periods. Any renewal of the Company's Products and/or the Services is subject to the Company's then current terms and conditions, including, but not limited to, payment of all applicable fees at the time of Agreement.</p> */}
                            <p>6.	The Company may revise its applicable fees and other charges from time to time and will notify the Merchant in writing (which period of notification shall be determined by the Company at its sole discretion) of the date (the "Effective Date") when the new fees or charges become payable. The Merchant shall confirm in writing to the Company its acceptance or non-acceptance of the new fees or charges on or before the Effective Date. If the Company does not receive the Merchant's written non-acceptance of the new fees or charges within the aforesaid period on or before the Effective Date, the Merchant shall be deemed to have accepted the new fees or charges and shall be bound to pay the new fees or charges from the Effective Date. If the Merchant notifies the Company in writing on or before the Effective Date that it does not accept the new fees or charges, this Agreement shall be deemed terminated on the Effective Date but without prejudice to the antecedent rights or liabilities of the parties hereto.</p>
                            <p>7.	The Fees and charges payable by the Merchant under this Agreement are exclusive of any taxes, duties, fees or government levies which may be imposed now or hereafter.</p>
                            <p>8.	Prompt payment of the Fees and other charges shall be of the essence of this Agreement.</p>
                            <p>9.	All Fees and charges paid by the Merchant under this Agreement shall not be refundable in the event of termination of this Agreement howsoever caused.</p>
                            <p>10.	Upon the execution of this Agreement, the Merchant shall provide the Company with all necessary particulars of the account designated by the Merchant pursuant to Clause 10.</p>
                        </li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Undertakings of The Merchant</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>11.	The Merchant agrees and undertakes throughout the term of this Agreement that the Merchant shall:</li>
                        <ul style={{ listStyle: 'none' }}>
                            <li>
                                1.	have the full authority and power to enter into, execute and deliver this Agreement and to carry out and perform the Services contemplated under this Agreement;
                                <p>2.	not make any warranty or representation whatsoever in relation to the Products or the Services which may bind the Company or render the Company liable in any way whatsoever;</p>
                                <p>3.	comply with all applicable consumer, personal data protection and other laws and regulations with respect to (i) its use of the Products and/or Services; (ii) its dealings with the users; and (iii) its processing, use and disclosure of the information obtained from Transactions;</p>
                                <p>4.	shall ensure the safety and confidentiality of its login name and password and shall keep the Company fully indemnified from all liability, losses, damages, cost and expense incurred by the Merchant arising out of or in connection with the misuse of the same. The Merchant shall immediately notify the Company upon learning of any unauthorized use of its login name and/or password;</p>
                                <p>5.	not sell, assign, license, transfer or permit the use of the Company software or hardware by any party without the written permission of the Company;</p>
                                <p>6.	prior to using the Products or the Services, open and maintain at all times during the term of this Agreement, an account in its name with one of the local banks which shall be designated for purposes of clearing and settling Transactions handled by the Merchant; and</p>
                                <p>7.	inform the Company of any change in the particulars of the Merchant's designated bank account.</p>
                            </li>
                        </ul>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Undertakings of The Company</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            <p>12.	The Company agrees and undertakes that it shall use its best endeavours to provide to the Merchant the Products and/or the Services for which the Merchant subscribes and pays the applicable Fees.</p>
                            <p>13.	The Company makes no representations or warranties of any kind with respect to the systems operated by the Company or any software and hardware provided, or any part thereof, express or implied, and shall not be liable to the Merchant for any loss or damage howsoever caused and regardless of the form of loss or damage which may be suffered or incurred by the Merchant or any third party in connection with this Agreement including (without prejudice to the generality of the foregoing) any loss of profit in consequence of a breakdown in providing the Products and/or Services or part thereof.</p>
                            <p>14.	The Company reserves the right to suspend, limit certain functionalities (depending on the severity and at the sole discretion of the Company) of the Products and/or Services and take such action as may be expedient or necessary (for compliance with the laws imposed by the Regulatory Authorities or otherwise to mitigate its losses) due to the breach of any terms and conditions herein and/or in any event that the Company may deem it necessary.</p>
                        </li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Proprietary Rights</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            <p>15.	The Company hereby grants to the Merchant, for the term of this Agreement, a personal non-assignable non-exclusive license to use the Marks in connection only with its provision of the Products and/or the Services in accordance with such manner of use as may be stipulated or permitted by the Company from time to time and the Merchant agrees that it shall derive no title or interest in the Marks or any part thereof and shall not attain any goodwill in respect thereof.</p>
                            <p>16.	The Merchant hereby authorises and consents to the Company making reference to, using and reproducing the Merchant's name, trademarks, logos, signs, acronyms and other insignia in any material or medium for the advertisement, publicity and promotion of the Products and/or the Services provided that the Company shall not thereby or otherwise derive any title, interest, claim, right or goodwill in the same.</p>
                        </li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Prohibited Business or Activities of The Merchants</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>17.	The Merchant shall ensure that it does not involved or engaged in the following types of business or activities or the products sold to the Merchant's users shall not contain articles which are prohibited or restricted under any and all applicable laws and regulations, including products listed by in the Appendix 1 below, which may be updated by the Company from time to time and can be found on the Website/Apps.</li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Disputes</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            <p>18.	The Company shall not be involved in any dispute or claim that may arise between the Merchant's users and the Merchant.</p>
                            <p>19.	The parties hereto agree that in the event of a dispute or claim of whatever nature arising in respect of any Transaction, the records of the Transactions available from the Merchant and the Company shall be used as a reference and shall be the sole basis of settling the aforesaid dispute or claim. Where there are discrepancies between records of the Transactions recorded by the Company and the Merchant, the discrepancies shall be jointly investigated by the Company and the Merchant using information from both parties whenever appropriate, and proper adjustments (if any) shall be made.</p>
                            <p>20.	The Merchant agrees that where multiple or excess payments have been effected by or on behalf of the Merchant's users in respect of any Transaction, whether through the Products or the Services or through any other existing system of payment, the Merchant shall refund or reimburse such users for such excess payments made and shall keep the Company harmless from and indemnified against any claim related to such excess.</p>
                        </li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Indemnity</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>21.	The Merchant hereby agrees and undertakes to fully indemnify and keep the Company harmless from and against all actions, claims, costs (including legal costs on a full indemnity basis), losses, charges, expenses and damages which the Company may suffer or incur as a result of</li>
                        <ul style={{ listStyle: 'none' }}>
                            <li>
                                1.	the occurrence of any event under Clause 38;
                                <p>2.	any fraud, dishonesty or misconduct (criminal or otherwise) relating to the Transactions perpetrated by the Merchant, its servant, agent, employee or contractor or the fraud, dishonesty or misconduct (criminal or otherwise) perpetrated by a third party as a result of the negligence or default of the Merchant, its servant, agent, employee or contractor;</p>
                                <p>3.	The Merchant shall not hold the Company liable or responsible for any action, claim, cost, expense, damage and loss, including consequential loss or damage or loss of profit, which the Merchant may suffer or incur as a result of a breakdown in the provision of the Products and/or the Services or when the Products and/or the Services are not available for any reason whatsoever.</p>
                            </li>
                        </ul>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Disclosure</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>22.	The Merchant hereby agrees and consents to the disclosure and release by the Company of any information in the possession of the Company relating to the Merchant, the particulars of the Transactions or any designated account relating to the Transactions for the purpose of investigating any claim or dispute arising out of this Agreement or in connection with the Transactions under the provision of the Products and/or the Services which consent shall survive both the suspension of rights hereunder and the termination of this Agreement.</li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Notices</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>23.	Unless otherwise provided herein or agreed to in writing by the parties hereto, all notices or other communications to or upon the Merchant and the Company shall be sufficiently served if delivered at or sent by registered post by either party to the other at the address above stated or the address of the registered office recorded with the Companies Commission of Malaysia ("SSM") and shall be deemed to have been served in the case of a notice delivered by hand on the day of the delivery, in the case of a notice served by registered post on the day following that on which notice was posted and in the case of a notice served by email on the day of the email.</li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Assignment</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            <p>24.	This Agreement shall be binding upon the parties hereto and their respective successors and assigns and legal Representatives and shall not be construed so as to confer any benefit upon any other person except as expressly provided herein.</p>
                            <p>25.	The Merchant shall have no right to assign or transfer the Merchant's rights and obligations in this Agreement and shall remain fully liable for all of the Merchant's respective duties, liabilities and obligations hereunder.</p>
                            <p>26.	The Company shall be entitled to assign its rights and benefits and transfer its obligations under this Agreement at any time by giving the Merchant written notice thereof in which event the said assignment and transfer shall ipso facto take effect.</p>
                        </li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Liability to Continue</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>27.	Unless this Agreement is terminated in accordance with Clause 7, Clause 33 or Clause 36 herein, the liability of the Merchant under this Agreement shall continue notwithstanding any change in its constitution and in the case of a firm, any change in its constitution whether by withdrawal, retirement, expulsion, death or admission of any partner, amalgamation, dissolution or otherwise; in the case of a body of corporate, the winding-up whether voluntary or compulsory or any amalgamation, reconstruction or otherwise; and in the case of a statutory body, the amalgamation, merger, reconstruction, privatisation, dissolution or otherwise and this Agreement shall continue in full force and be binding on the entity formed or the party appointed to carry on the Merchant's business.</li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Relationship of The Parties</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>28.	This Agreement or anything herein contained shall not be construed as creating a partnership or joint venture. Each party will be responsible only for its obligations under this Agreement and neither party shall be authorised to represent or bind the other to third parties.</li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Severability</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>29.	If any provision herein is deemed by any tribunal or court of competent jurisdiction to be illegal, invalid or unenforceable under any applicable law or otherwise, it shall, to the extent required by such law and subject to the agreement of the Company, be severed from this Agreement and rendered ineffective so far as is possible without affecting the legality, validity and enforceability of the remaining provisions of this.</li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Indulgence</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>30.	The liability of the Merchant hereunder shall not be impaired or discharged by reason of any time or grace or other indulgence being granted by or with the consent of the Company or any forbearance by the Company to insist upon its strict rights hereunder. No right under this Agreement shall be deemed to have been waived except in writing signed by the parties hereto and waiver of any particular right in a particular instance shall not constitute or be deemed a waiver of any other right.</li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Force Majeure</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>31.	No failure or omission by any party to carry out its obligations or observe any of the stipulations or conditions of this Agreement shall give rise to any claims against the party in question or be deemed a breach of this Agreement if such failure or omission arises from a cause of force majeure, such as acts of God, war or warlike hostilities, civil commotion, riots, blockades, embargoes, sabotage, strikes, lockouts, shortage of material or labour, delay in deliveries from subcontractors or machine failure caused by force majeure, or any other event outside the control of the party in question.</li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Suspension Of Rights</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>32.	Without prejudice to any of the Company's rights at law and under this Agreement, in the event of a breach by the Merchant of any of the terms and conditions of this Agreement, the Company may at its sole discretion give immediate notice in writing to the Merchant to remedy the breach within one (1) month of the said notice and may during the notice period, suspend all rights of the Merchant under this Agreement, save for those rights necessary to enable the Merchant to remedy the breach. If the Merchant shall fail to remedy the breach within the aforesaid notice period, the Company shall have the right but not the obligation to extend the notice period (with or without suspension) or forthwith terminate this Agreement in accordance with Clause 38.</li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Termination of Agreement</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            <p>33.	This Agreement shall become effective from the date hereof and shall remain in force and effect until terminated in accordance with the terms of this Agreement.</p>
                            <p>34.	Either party to this Agreement may elect to terminate this Agreement by giving one (1) month's prior notice in writing to the other party of its intention to do so.</p>
                            <p>35.	Notwithstanding Clause 36 and Clause 37 above, the Company shall have the right (but not the obligation) at anytime to give immediate notice in writing to the Merchant to terminate this Agreement forthwith upon the happening of any one or more of the following events of default, whether or not such event is of a continuing nature:</p>
                        </li>
                        
                        <ul style={{ listStyle: 'none' }}>
                            <li>
                                1.	if in the sole opinion of the Company, the Merchant has breached any of the terms and conditions of this Agreement;
                                <p>2.	if the Merchant enters into liquidation, receivership, judicial management or otherwise compounds with its creditors or takes or suffers any similar action or occurrence in any jurisdiction;</p>
                                <p>3.	if the Merchant becomes insolvent or stops payment or ceases or threatens to cease to carry on its business or any part of its business;</p>
                                <p>4.	if any resolution is passed or steps taken by the Merchant or any other person to apply for judicial composition proceedings with its creditors or an order is made by any competent court for such proceedings or a receiver, judicial manager, administrator or other similar official is appointed in relation to the Merchant or any part of the assets or undertakings of the Merchant or encumbrancer taking possession of any part of the assets or undertakings of the Merchant or a distress or execution or other process is being levied or enforced upon or sued out against any part of the assets or undertakings of the Merchant;</p>
                                <p>5.	if the Merchant is deceased or its partnership is dissolved;</p>
                                <p>6.	if the Merchant or any of its shareholders, partners, proprietors, officers, employees, agents or contractors is or is suspected by the Company to be involved in any fraudulent or unlawful activity whether or not relating to the Merchant's business;</p>
                                <p>7.	if the Merchant's business involves any trade or activity deemed undesirable by the Company (refers to Appendix 1 herein) or Regulatory Authority; or</p>
                                <p>8.	if there had been multiple complaints and disputes relating to the Merchant's goods or services by the Merchant's users.</p>
                            </li>
                        </ul>
                        <li>36.	In the event of termination of this Agreement for whatever reason:</li>
                        <ul style={{ listStyle: 'none' }}>
                            <li>
                                1.	the Merchant agrees and undertakes to return to the Company immediately all materials, books, records or otherwise pertaining to the provision of the Product and/or the Services where applicable, and shall not thereafter use the Marks or any part or derivatives thereof;
                                <p>2.	the Merchant agrees that the Fees are non-refundable; and</p>
                                <p>3.	any antecedent right and liability of either party shall not be thereby prejudiced or impaired.</p>
                            </li>
                        </ul>
                        <li>37.	In the event that the Company terminates this Agreement in accordance with the terms hereof, the Merchant acknowledges and agrees that no reason whatsoever needs to be communicated to the Merchant for such termination and the Company shall not be liable in any way for any loss or damage incurred or suffered by any party due to such termination.</li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Construction and Variation</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            <p>38.	The Application Form, Fee Structure, and appendices shall be deemed to form part of this Agreement and shall be constructed to be a basis on which the parties have entered into this Agreement.</p>
                            <p>39.	In the event that the provisions of this Agreement are inconsistent with or contradictory to the provisions in the Application Form, Fee Structure, and appendices, the terms contained in this Agreement shall prevail.</p>
                            <p>40.	This Agreement constitutes the entire agreement between the parties hereto and all prior arrangements, representations and undertakings are hereby superseded.</p>
                            <p>41.	No variation or amendment to any provision of this Agreement shall be made unless in writing and duly signed by authorised representatives of the parties hereto.</p>
                        </li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>Confidentiality</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>42.	"Confidential Information" means any confidential, trade secret or proprietary information (which may be business, financial or technical information) disclosed by one party to the other under this Agreement that is marked confidential or if disclosed orally designated as confidential at the time of disclosure or that should be reasonably understood to be confidential.</li>
                        <li>
                            <p  style={{marginBottom:'0'}}>43.	Each party agrees that:</p>
                            <ul style={{ listStyle: 'none' }}>
                            <li>
                                1.	Each party shall not disclose to any third party or use any Confidential Information disclosed to the party by the other except as expressly permitted  in this Agreement and for purposes of performing this Agreement, and
                                <p>2.	Shall take reasonable measures to maintain the confidentiality of all Confidential Information of the other party in its possession or control, which shall in no event be less than the measures it uses to maintain the confidentiality of its own proprietary information or Confidential Information of similar importance. Each party further agrees to use the other party's Confidential Information only for the purpose of its performance under this Agreement.</p>
                            </li>
                        </ul>
                        </li>
                        
                        <li>44.	The obligations set forth in Clause 46 above do not apply to information that</li>
                        <ul style={{ listStyle: 'none' }}>
                            <li>
                                1.	is in or enters the public domain without breach of this Agreement;
                                <p>2.	the receiving party lawfully receives from a third party without restriction on disclosure and without breach of a nondisclosure obligation;</p>
                                <p>3.	the receiving party knew prior to receiving such information from the disclosing party or develops independently without access or reference to the Confidential Information;</p>
                                <p>4.	is disclosed with the written approval of the disclosing party.</p>
                            </li>
                        </ul>
                        <li>45.	Notwithstanding the obligations set forth in Clause 46 above, each party may disclose Confidential Information of the other party:</li>
                        <ul style={{ listStyle: 'none' }}>
                            <li>
                                1.	to the extent required by a court of competent jurisdiction or other governmental authority or otherwise as required by law but only after alerting the other party of such disclosure requirement and, prior to any such disclosure, allowing (where practicable to do so) the other party a reasonable period of time within which to seek a protective order against the proposed disclosure, or
                                <p>2.	on a "need-to-know" basis under an obligation of confidentiality substantially similar in all material respects to those confidentiality obligations to its legal counsel, accountants, contractors, consultants, banks and other financing sources.</p>
                            </li>
                        </ul>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>GOVERNING LAW AND COMPLIANCE</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            46.	This Agreement shall be governed by and construed in all aspects in accordance with the laws of Malaysia and the parties hereto irrevocably submit to the exclusive jurisdiction of the courts of Malaysia.
                            <p>47.	The parties hereto each undertakes to comply with all laws and regulations as may be applicable to the performance of its obligations under this Agreement.</p>
                        </li>
                    </ul>

                    <p style={{marginTop:'40px'}}><b style={{ color: '#333' }}>APPENDIX 1</b></p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>•	Products that promote hatred, violence, racial or religious intolerance</li>
                        <li>•	Drugs and narcotics</li>
                        <li>•	Medicine and prescription based drugs</li>
                        <li>•	Human parts, organs and related products</li>
                        <li>•	Animals and animal parts and related products</li>
                        <li>•	Pornographic content, products and material</li>
                        <li>•	Weapons, guns, arms, firearms, military arms, ammunition and related products</li>
                        <li>•	Hazardous and dangerous material</li>
                        <li>•	Stocks, currencies and securities</li>
                        <li>•	Transfer from one person to another of personal credit and debit and any type of payment card details</li>
                        <li>•	Fake IDs</li>
                        <li>•	Counterfeit products</li>
                        <li>•	Software (unless owner of the software is authorized to do so).</li>
                    </ul>
                    <p>This list stated above is not exhaustive and Otto in its sole and exclusive discretion, reserves the right to impose additional restrictions and prohibitions.</p>

                </Container>
            </div>
        </Box>
    );
}
