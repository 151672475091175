import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Card, CardContent, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import WebpImg from './Layouts/WebpImg';

import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

export default function Notification(props) {

    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getUrl(`notifications?page=${page}`).then(response => {
            console.log("response", response);
            if (response.status) {
                if (isMountedRef.current) {
                    setNotifications(response.data.data);
                    setTotalPage(response.data.last_page);
                    setPage(response.data.current_page ? response.data.current_page : 1);
                    setLoading(false);
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, addAlert]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <Grid container spacing={1} justify="center" style={{ minHeight: 450, }}>
            <Grid item xs={12} className={styles.root} >
                {
                    _.size(notifications) > 0 ?
                        _.map(notifications, notificationItem => {
                            return (
                                <Link key={notificationItem.id} underline='none' color="inherit" to={notificationItem.type === 'order' ? `/account/order/${notificationItem.linking_array.orderNo}` : '#'} component={RouterLink}>
                                    <Card style={{ boxShadow: '0 0 6px #bbbbbb', borderRadius: 15, marginBottom: 10  }} variant="outlined">
                                        <CardContent style={{ borderRadius: 0 }}>
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                                                    {
                                                        notificationItem.type == 'order' ?
                                                        <WebpImg src={notificationItem.linking_array.orderImage} style={{ height: 40, width: 40, objectFit:'contain' }} alt={notificationItem.linking_array.orderNo}/> 
                                                        :
                                                        <NotificationsNoneIcon color="primary" />
                                                    }
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Typography variant="h6">{ notificationItem[`subject_${i18n.language}`] }</Typography>
                                                    <Typography variant="body2">{ notificationItem[`message_${i18n.language}`] }</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Link>
                            )
                        })
                    :
                    <Card style={{ boxShadow: '0 0 6px #bbbbbb', borderRadius: 15 }} variant="outlined">
                        <CardContent style={{ height: 400, borderRadius: 0 }}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <Typography variant="h5">
                                { t('notification.noNotification') }
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                }
                
            </Grid>
            <Grid container spacing={1} justify="center" >
                <Pagination count={totalPage} shape="rounded" page={page}
                    onChange={handleChange}
                    renderItem={(item) => (
                        <PaginationItem
                            component={RouterLink}
                            to={`/notifications?page=${item.page}`}
                            {...item}
                        />
                    )}
                />
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
      },
}));