import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from '../helper/useNotificationLoading';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Button, Grid, Box, Typography, Link, Modal, Backdrop, Fade, } from '@material-ui/core';

import TitleBarWhite from './Layouts/TitleBarWhite';
import WebpImg from './Layouts/WebpImg';

import { FiCopy } from 'react-icons/fi';



export default function Recruit() {

    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const shareLink = useRef(null);
    const { id, max_rank } = useSelector(state => state.user);
    const { addAlert } = useNotificationLoading();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const copyShareLink = () => {
        if(max_rank.code>0){
            var textField = document.createElement('textarea');
            textField.innerText = `${window.location.origin}/register?r=${btoa(id)}`;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
    
            addAlert(t('profile.copySuccess'), "success");
        }
    };
    
    return (
        <Box className={styles.root} >
            {/* <TitleBar title={t('profile.recruit')} displayInfo={false} back/> */}
            <div style={{position:'fixed', width:600, maxWidth:'100%', zIndex:2}}>
                <TitleBarWhite height={0} title={t('profile.recruit')} displayInfo={false} back />
            </div>

            <Grid >
                <Container className={styles.containerPadding} >
                <div style={{ maxWidth: '1790px', margin: '0 auto', padding:'54px 20px 100px', position:'relative', background: 'linear-gradient(29deg, rgba(0,0,0,1) 44%, rgba(117,72,0,1) 100%)', position: 'absolute', width: '100%', left: 0, top: 0, minHeight: 'calc(100vh - 56px)', overflowY:'scroll'}}>
                    <div className={styles.prizePosition} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%',}}>
                        <center>
                            <WebpImg src={`/images/general/eventPromo/iphone.png`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: 283, }} containerStyle={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}} />
                        </center>
                        <div style={{ textAlign: 'center', color: '#fff', paddingTop:27}}>
                            <Typography style={{paddingBottom:15}}>{t('eventPromo.eventDate')}</Typography>
                            <Typography className={styles.titleFontSize}><b>{t('eventPromo.phoneTitle')}</b></Typography>
                            <Typography className={styles.descriptionFont}>{t('eventPromo.phoneDesc')}</Typography>
                        </div>
                        <div style={{paddingBottom:20, paddingTop:40}}>
                        <div style={{background: 'linear-gradient(159deg, rgba(238,178,36,1) 0%, rgba(226,159,35,1) 20%, rgba(241,237,101,1) 45%, rgba(255,255,255,1) 78%, rgba(212,139,34,1) 100%)', padding:2, width:350, maxWidth:'80%', margin:'0 auto', borderRadius:8}}>
                            <div style={{ color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 15, backgroundColor: '#000', borderRadius:8 }}>
                                <Typography style={{ textAlign: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', }} ref={shareLink} >{max_rank.code>0 ? `${window.location.origin}/register?r=${btoa(id)}`:t('eventPromo.activeUserOnly')}</Typography>
                                <FiCopy style={{fontSize:20}} onClick={copyShareLink} />
                            </div>
                        </div>
                        </div>
                        <div style={{ paddingBottom:25 }}>
                        {/* padding: '50px 0 25px' */}
                            <div style={{ background: 'linear-gradient(159deg, rgba(238,178,36,1) 0%, rgba(226,159,35,1) 20%, rgba(241,237,101,1) 45%, rgba(255,255,255,1) 78%, rgba(212,139,34,1) 100%)', padding: '10px 15px', width: 250, borderBottom:'6px solid #d0b44c', maxWidth: '75%', margin: '0 auto', borderRadius: 8 }} onClick={copyShareLink} >
                                <Typography style={{ textAlign: 'center', fontSize: 18 }}><b>{t('eventPromo.inviteNow')}</b></Typography>
                            </div>
                            <div style={{paddingTop:15}}>
                            <Typography type="button" onClick={handleOpen} style={{ textAlign: 'center', color:'#fff', fontSize:13, cursor:'pointer' }}>{t('eventPromo.tnc')}</Typography>
                            </div>
                        </div>
                        
                    </div>
                    {/* <div style={{ bottom: 20, position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', left: 0,}}>
                            
                            
                        </div> */}
      <Modal
        className={styles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={styles.paper}>
            <div style={{textAlign:"center"}}>
            <h2 style={{marginBottom:0}}>{t('eventPromo.tncTitle')}</h2>
                <p style={{marginTop:5}}>
                    <b>{t('eventPromo.tncDes')}</b>
                </p>
            </div>
                <p>{t('eventPromo.tncDes1')}</p>
                <p>{t('eventPromo.tncDes2')}</p>
                <p>{t('eventPromo.tncDes3')}</p>
                <p>{t('eventPromo.tncDes4')}</p>
            <p><i><i style={{paddingTop:5}}>{t('eventPromo.tncDes5')}</i></i></p>
          </div>
        </Fade>
      </Modal>
      
                </div>
                        
                </Container>
            </Grid>
        </Box>

    )
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        borderRadius:15,
        boxShadow: theme.shadows[5],
        padding: 20,
        width: 480,
        maxWidth: '80%',
        margin: '0px auto',
      },

    root: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg, 
        padding:'0 0 80px',
        minHeight: '100%'
    },

    containerPadding: {
        
        [theme.breakpoints.down('xs')]: {
            padding:'80px 20px 0'
        },
        [theme.breakpoints.up('sm')]: {
            padding:'80px 40px 0'
        },    
    },

    // prizePosition: {
    //     position: 'absolute',
    //     left: 0,
    //     width:'100%',    
    // },
    titleFontSize:{
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
        },
    },
    descriptionFont:{
        fontSize: 14,
        maxWidth: '83%',
        margin: '0px auto',
        color: '#dedede',
        width: 350,
    },
}));