import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, Box, Paper, Typography, InputAdornment, TextField, useTheme, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TitleBar from './Layouts/TitleBar';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat } from '../helper/Tools';
import { storeWalletBalance } from '../actions';

// const DEFAULT_ERROR_FIELD = { amount: "", recipient: "", remark: "", password: "" };
const DEFAULT_ERROR_FIELD = { amount: "", recipient: "", remark: "", security_password: "" };

export default function Transfer() {
    const [walletInfo, setWalletInfo] = useState([]);
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [state, setState] = useState({ amount: "", recipient: "", remark: "", security_password: "", dateTime: "", id: "" });
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);
    const [recipientList, setRecipientList] = useState([]);
    const [transferStatus, setTransferStatus] = useState("insert"); // insert -> review -> result
    const [toWalletId, setToWalletId] = useState(0);

    const classes = useStyles();
    const { t } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
    }));
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    const history = useHistory();
    const dispatch = useDispatch();

    const isMountedRef = useRef(null);

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        setUserWalletInfo(existWalletBalance);
        if(!existWallet || existWallet.allowTransfer === false) {
            history.goBack();
        }
    }, [wallet, walletBalance]);

    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(walletInfo) > 0) {
            getUrl('wallets/rules').then(response => {
                // console.log("wallets/rules response", response)
                if(response.data && isMountedRef.current) {
                    let selectedWalletRule = [];
                    _.map(response.data.other, (o, i) => {
                        if(i === String(walletInfo.id)) {
                            selectedWalletRule = o;
                        }
                    });
                    // console.log("selectedWalletRule", selectedWalletRule)

                    let selectedWalletCovertList = [];
                    _.map(selectedWalletRule, walletId => {
                        const selectedWalletInfo = _.find(walletList, { id: walletId });
                        selectedWalletCovertList.push(selectedWalletInfo);
                    })
                    // setConvertWalletList(selectedWalletCovertList);
                    // console.log("selectedWalletCovertList", selectedWalletCovertList)
                    if(selectedWalletCovertList.length===1){
                        setToWalletId(selectedWalletCovertList[0].id);
                        // console.log("setToWalletId", selectedWalletCovertList[0].id)
                    }else{
                        setToWalletId(walletInfo.id);
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        }

        return () => isMountedRef.current = false;
    }, [walletInfo, walletList, addAlert]);

    // useEffect(() => {
    //     isMountedRef.current = true;
    //     getUrl('bind_account_accross').then(response => {
    //         if(response.data && isMountedRef.current) {
    //             setRecipientList(response.data);
    //         }
    //     }).catch(error => {
    //         addAlert(JSON.stringify(error.message));
    //     })

    //     return () => isMountedRef.current = false;
    // }, [addAlert]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if(name === 'amount') {
            if(parseFloat(value) > 0) {
                const patt = /^\d+\.{0,1}\d{0,2}$/;
                newValue = patt.test(newValue) ? newValue : state.amount;
            }
        }
        setState({ ...state, [name]: newValue });
    }

    const reviewTransfer = () => {
        let error = false;
        let newErrorField = errorField;
        _.map(["amount", "recipient"], field => {
            if(_.size(state[field]) === 0) {
                error = true;
                newErrorField[field] = t('transfer.isRequired', { field: t(`transfer.${field}`) });
            } else {
                newErrorField[field] = "";
            }
        })
        setErrorField(errorField => ({ ...errorField, newErrorField }));
        if(!error) {
            setTransferStatus('review')
        }
    }
    
    const transferUser = () => {
        const { recipient, amount, remark, security_password } = state;
        // const { recipient, amount, remark, password } = state;
        const transferData = {
            source: 'local',
            login_method: recipient,
            // to_wallet_id: walletInfo.id,
            to_wallet_id: toWalletId,
            amount,
            remark,
            // password,
            security_password,
        };

        postUrl(`wallets/${walletInfo.id}/transfer`, transferData).then(response => {
            console.log("response", response)
            if(response.status) {
                const { created_at, transaction_id, data } = response;
                setTransferStatus('result');
                setState({ ...state, id: transaction_id, dateTime: created_at });
                setErrorField(DEFAULT_ERROR_FIELD);
                refetchWallet();
            } else {
                if(response.errors) {
                    let newErrorField = errorField;
                    _.map(response.errors, (errorMsg, errorField) => {
                        newErrorField[errorField] = errorMsg[0];
                    })
                    setErrorField(newErrorField);
                }
                if(response.data) {
                    addAlert(response.data);
                }
                setTransferStatus('insert')
            }
        }).catch(error => {
            setTransferStatus('insert')
            addAlert(JSON.stringify(error.message));
        })
    }
    
    const refetchWallet = () => {
        getUrl('wallets/balance').then(response => {
            if(response.status) {
                dispatch(storeWalletBalance(response.data));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    return (
        <Box className={classes.root} style={{ background: theme.palette.white.mobileBkg, padding:'0 0 80px', minHeight: '100%'}}>
            <TitleBar title={ t('title.walletTransfer', { wallet: walletInfo.wallet_name }) } back>
                <Box display="flex" alignItems="center" justifyContent="center" height="56%">
                    {/* <Box className={classes.rankWrapperStyle}>
                        <Typography className={ classes.rankTitleStyle }>{ _.size(walletInfo) > 0 ? walletInfo.wallet_name[0] : '-' }</Typography>
                    </Box> */}
                    <Box display="flex" flexDirection="column">
                        <Typography className={classes.balanceStyle}>{ currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1) } <span style={{ fontSize: 16 }}>{ _.size(walletInfo) > 0 ? walletInfo.wallet_name : '-' }</span></Typography>
                        <Typography className={ classes.infoStyle }>{ t('transfer.transferableBalance') }</Typography>
                    </Box>
                </Box>
            </TitleBar>
            <Paper elevation={3} classes={{ root: classes.paperContainer }}>
                { transferStatus === 'result' ?
                    <Box className={classes.boxContainer}>
                        <Typography variant="h6" style={{ color: theme.palette.secondary.main }}>{ t('transfer.successfullyTransfer') }</Typography>
                        <Typography variant="h6" style={{ ...styles.inputPropsStyle, color: theme.palette.secondary.main }}>
                            { `${ currencyFormat(state.amount) } ${_.size(walletInfo) ? walletInfo.wallet_name : ''}`}
                        </Typography>
                        <Box paddingY={2} >
                            <Typography variant="h6" style={{ color: theme.palette.silver.text, fontSize: 16 }}>{ t('transfer.transferTo') }</Typography>
                            <Typography variant="h6" style={{ ...styles.inputPropsStyle, fontSize: 20 }}>{ state.recipient }</Typography>
                        </Box>
                        <Box paddingY={2} paddingX={1} display="flex" flexDirection="column" width="100%">
                            <Typography variant="h6" style={{ fontSize: 12 }}>{ t('transfer.remark') }</Typography>
                            <Typography variant="h6" style={{ fontSize: 12, wordWrap: "break-word" }}>{ state.remark }</Typography>
                        </Box>
                        <Box paddingY={2} paddingX={1} display="flex" width="100%" flexDirection="row" borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                            <Box flex={1}>
                                <Typography variant="h6" style={{ fontSize: 12 }}>{ t('transfer.dateTime') }</Typography>
                                <Typography variant="h6" style={{ fontSize: 12 }}>{ t('transfer.idNo') }</Typography>
                            </Box>
                            <Box flex={2}>
                                <Typography variant="h6" style={{ fontSize: 12 }}>{ state.dateTime }</Typography>
                                <Typography variant="h6" style={{ fontSize: 12 }}>{ state.id }</Typography>
                            </Box>
                        </Box>
                    </Box>
                : <Box className={classes.boxContainer}>
                    <Typography variant="h6" style={{ color: theme.palette.secondary.main, padding: 15 }}>{ t('title.walletTransfer', { wallet: walletInfo.wallet_name }) }</Typography>
                    {/* <TextField 
                        type="text"
                        inputProps={{ style: { ...styles.inputPropsStyle, color: theme.palette.gold.bg }, inputMode: 'numeric' }} 
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><span style={{ color: theme.palette.gold.bg }}>{ _.size(walletInfo) ? walletInfo.wallet_name : '' }</span></InputAdornment>,
                            disableUnderline: true,
                            readOnly: transferStatus === 'review' ? true : false,
                        }}
                        placeholder={"0.00"}
                        value={state.amount}
                        name="amount"          
                        onChange={ handleChange }   
                        classes={{
                            root: classes.amountFieldRoot
                        }}
                        error={ _.size(errorField.amount) ? true : false }
                        helperText={ errorField.amount }
                        FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                    /> */}
                    <Box borderTop={.5} borderColor={theme.palette.silver.bg} paddingY={2} paddingX={2} width="100%">
                        {/* <Autocomplete
                            id="user-to-transfer"
                            options={recipientList}
                            getOptionLabel={(option) => option.username ? option.username : "" }
                            fullWidth
                            onChange={(event, newValue) => {
                                console.log("newValue", newValue);
                                setState({ ...state, 'recipient': newValue ? newValue.username : "" });
                            }}
                            inputValue={state.recipient}
                            onInputChange={(event, newInputValue) => {
                                setState({ ...state, 'recipient': newInputValue });
                            }}
                            renderInput={(params) => (
                                <TextField 
                                    { ...params } 
                                    label={ t('transfer.transferTo') }
                                    variant="outlined" 
                                    classes={{
                                        root: classes.textFieldRoot
                                    }}
                                    error={ _.size(errorField.recipient) ? true : false }
                                    helperText={ errorField.recipient }
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                />
                            )}
                        /> */}
                        <TextField
                            label={t('transfer.transferTo')}
                            variant="outlined"
                            type="text"
                            name="recipient"
                            classes={{
                                root: classes.textFieldRoot
                            }}
                            error={_.size(errorField.recipient) ? true : false}
                            helperText={errorField.recipient}
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            fullWidth
                            value={state.recipient}
                            onChange={ handleChange }
                            InputProps={{ readOnly: transferStatus === 'review' ? true : false }}
                        />

                        <TextField 
                            type="text"
                            variant="outlined"
                            value={state.amount}
                            name="amount"          
                            onChange={ handleChange }   
                            classes={{ root: classes.textFieldRoot }}
                            InputProps={{ readOnly: transferStatus === 'review' ? true : false }}
                            label={ t('transfer.amount') }
                            fullWidth
                            error={ _.size(errorField.amount) ? true : false }
                            helperText={ errorField.amount }
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                        />

                        <TextField 
                            type="text"
                            variant="outlined"
                            value={state.remark}
                            name="remark"          
                            onChange={ handleChange }   
                            classes={{
                                root: classes.textFieldRoot
                            }}
                            InputProps={{
                                readOnly: transferStatus === 'review' ? true : false,
                            }}
                            label={ t('transfer.remark') }
                            error={ _.size(errorField.remark) ? true : false }
                            helperText={ errorField.remark }
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            fullWidth
                            multiline
                            rowsMax={5}
                        />
                        {/* <TextField 
                            type="password"
                            variant="outlined"
                            value={state.password}
                            name="password"          
                            onChange={ handleChange }   
                            classes={{
                                root: classes.textFieldRoot,
                            }}
                            InputProps={{
                                readOnly: transferStatus === 'review' ? true : false,
                            }}
                            label={ t('transfer.password') }
                            error={ _.size(errorField.password) ? true : false }
                            helperText={ errorField.password }
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            fullWidth
                        /> */}

                        <TextField 
                            type="password"
                            variant="outlined"
                            value={state.security_password}
                            name="security_password"          
                            onChange={ handleChange }   
                            classes={{
                                root: classes.textFieldRoot,
                            }}
                            InputProps={{
                                readOnly: transferStatus === 'review' ? true : false,
                            }}
                            label={ t('transfer.security_password') }
                            error={ _.size(errorField.security_password) ? true : false }
                            helperText={ errorField.security_password }
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            fullWidth
                        />
                    </Box>
                </Box> }
                <Box className={classes.boxContainer}>
                    { transferStatus === 'insert' && <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ reviewTransfer }>
                        { t('transfer.transferNow') }
                    </Button> }
                    { transferStatus === 'review' && <Box display="flex" width="100%" flexDirection="row" justifyContent="space-around">
                        <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ () => setTransferStatus('insert') }>
                            { t('transfer.changeDetail') }
                        </Button>
                        <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ transferUser }>
                            { t('transfer.confirmTransfer') }
                        </Button>
                    </Box> }
                    { transferStatus === 'result' && <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ () => history.push('/wallet') }>
                        { t('transfer.complete') }
                    </Button> }
                </Box>
            </Paper>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: -58,
        padding: 10,
        borderRadius: 20
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 15,
    },
    buttonRoot: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 20,
        marginTop: 15, 
        marginBottom: 30
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    balanceStyle: {
        fontSize: 30, 
        color: "white",//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textAlign:'center',
        textTransform:'uppercase'
    },
    infoStyle: {
        color: theme.palette.secondary.white, 
        fontSize: 11, 
        letterSpacing: 1 
    },
}));

const styles = {
    inputPropsStyle: { 
        textAlign: 'center', 
        fontSize: 36, 
        fontWeight: 'bold', 
    }
}