import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Typography, Grid, Box, Divider, List, ListItem, Snackbar,Card ,CardContent, FormHelperText, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import _ from 'lodash';
import { putUrl } from '../helper/ApiAction';

import TitleBarWhite from './Layouts/TitleBarWhite';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import useNotificationLoading from '../helper/useNotificationLoading';

const INITIAL_PASSWORD_STATE = { old_password: '', password: '', password_confirmation: '' };

export default function ProfilePassword() {
    const { t } = useTranslation();

    const { setLoading } = useNotificationLoading();
    const theme = useTheme();

    const [state, setState] = useState({
        modalShow: false,
        mode: null,
        deleteAddressId: null,
        editAddressId: null,
        snackbarShow: false,
        deleteAddressShow: false,
        error: false,
        message: '',
    });

    const [passwordFormState, setPasswordFormState] = useState(INITIAL_PASSWORD_STATE);
    const [errorFormState, setErrorFormState] = useState({
        old_password: '', password: '', password_confirmation: '',
    })
    
    const styles = useStyles();

    const handlePasswordChange = (event) => {
        const name = event.target.name;
        setPasswordFormState({ ...passwordFormState, [name]: event.target.value });
    }

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };

    const updatePassword = () => {
        setLoading(true);
        setErrorFormState({});
        const { old_password, password, password_confirmation } = passwordFormState;
        if (!old_password || !password || !password_confirmation) {
            setLoading(false);
            setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequired') })
        } else {
            putUrl('user/password', passwordFormState).then(result => {
                if (result.error) {
                    setLoading(false);
                    setState(state => ({ ...state, snackbarShow: true, error: true, message: result.message }));
                    let allErrorField = [];
                    _.map(result.error, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setErrorFormState(allErrorField);
                } else {
                    setLoading(false);
                    setState(state => ({ ...state, snackbarShow: true, error: false, message: result.message }));
                    setPasswordFormState(INITIAL_PASSWORD_STATE);
                    setErrorFormState({});
                }
            })
        }
    }

    return (
        <Box className={styles.root} style={{ background: theme.palette.white.mobileBkg, padding:'0 0 80px', minHeight: '100%' }}>
            {/* <TitleBar title={t('profile.changePassword')} displayInfo={false} back /> */}
            <div style={{position:'fixed', width:600, maxWidth:'100%', zIndex:2}}>
                <TitleBarWhite height={0} title={t('profile.changePassword')} displayInfo={false} back />
            </div>

        <Grid container spacing={1} justify="center" style={{padding:'40px 15px 0'}}>
            <Grid item xs={12}>

                <List className={ styles.listStyle } style={{ boxShadow:'none', backgroundColor:'transparent' }}>
                    <ListItem style={{display:'flex', justifyContent:'space-between', alignItem:'center'}}>
                        <Box style={{color:theme.palette.primary.main}}>
                            <Typography variant="h6">
                                { t('profile.changePassword') }
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                { t('profile.passwordSubtitle') }
                            </Typography>
                        </Box>
                        
                    </ListItem>

                    <Divider variant="middle" light />
                    <ListItem className={ styles.listPaddingVertical }>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} sm={3}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="caption" color="textSecondary">{ t('profile.currentPassword') }</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8}>
                                <TextField 
                                    error={errorFormState.old_password ? true : false}
                                    helperText={errorFormState.old_password}
                                    fullWidth={true} 
                                    variant="outlined" 
                                    size="small"
                                    type={passwordShown ? "text" : "password"}
                                    inputProps={{ name: 'old_password' }} 
                                    onChange={handlePasswordChange}
                                    value={passwordFormState.old_password}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={togglePassword} onMouseDown={(event) => event.preventDefault() } edge="end">{passwordShown ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem className={ styles.listPaddingVertical }>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} sm={3}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="caption" color="textSecondary">{ t('profile.newPassword') }</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8}>
                                <TextField 
                                    error={errorFormState.password ? true : false}
                                    helperText={errorFormState.password}
                                    fullWidth={true} 
                                    variant="outlined" 
                                    size="small"
                                    type={passwordShown ? "text" : "password"}
                                    inputProps={{ name: 'password' }} 
                                    onChange={handlePasswordChange}
                                    value={passwordFormState.password}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={togglePassword} onMouseDown={(event) => event.preventDefault() } edge="end">{passwordShown ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                                <FormHelperText style={{color:'textSecondary'}}>{t('profile.passwordValidation')}</FormHelperText>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem className={ styles.listPaddingVertical }>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} sm={3}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="caption" color="textSecondary">{ t('profile.confirmPassword') }</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8}>
                                <TextField 
                                    error={errorFormState.password_confirmation ? true : false}
                                    helperText={errorFormState.password_confirmation}
                                    fullWidth={true} 
                                    variant="outlined" 
                                    size="small"
                                    type={passwordShown ? "text" : "password"}
                                    inputProps={{ name: 'password_confirmation' }} 
                                    onChange={handlePasswordChange}
                                    value={passwordFormState.password_confirmation}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={togglePassword} onMouseDown={(event) => event.preventDefault() } edge="end">{passwordShown ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Box style={{display:'flex', justifyContent:'center', alignItems:'center', paddingTop:30}}>
                    <Button variant="contained" onClick={updatePassword} style={{width: 167, padding:'8px 9px', maxWidth: '80%', borderRadius: 48, }} color="secondary">{ t('button.confirm') }</Button>
                    </Box>
                </List>
                <Snackbar
                    open={state.snackbarShow}
                    autoHideDuration={2000}
                    onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                        <CardContent style={{ padding: 10 }}>
                            <Typography style={{ color: 'white' }}>{state.message}</Typography>
                        </CardContent>
                    </Card>
                </Snackbar>
          
            </Grid>
        </Grid>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
}));