import React, { useEffect, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Avatar, Grid, Button, Typography, Link, Box, Divider, List, ListItem, Chip, Card, CardContent, AppBar, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, TextField, Switch } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import SwipeableViews from 'react-swipeable-views';

import StarsIcon from '@material-ui/icons/Stars';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import StorefrontIcon from '@material-ui/icons/Storefront';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import CancelIcon from '@material-ui/icons/Cancel';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import _ from 'lodash';

import { getUrl, putUrl, postUrl } from '../helper/ApiAction'; //, deleteUrl
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';
import WebpImg from './Layouts/WebpImg';
import { Rating } from '@material-ui/lab';
import TabPanel from './Layouts/TabPanel';
// import { Truck } from 'react-feather';
import { Helmet } from "react-helmet";

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
    },
    2: {
        icon: <SentimentSatisfiedIcon />,
    },
    3: {
        icon: <SentimentVerySatisfiedIcon />,
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

const trackButtonEn =
    `        
        <input type="text" id="TrackNo" maxLength="50" style="padding:5px"  placeholder="Enter tracking no..." >
        <input type="button" value="TRACK" onClick="inputTrack()" style="
        padding: 7px;
        background-color: #f7952a;
        color: #ffffff;
        border: none;
        border-radius: 5px;
    " >
    `
const trackButtonCn =
    `        
        <input type="text" id="TrackNo" maxLength="50" style="padding:5px"  placeholder="输入追踪号码..." >
        <input type="button" value="追踪" onClick="inputTrack()" style="
        padding: 7px;
        background-color: #f7952a;
        color: #ffffff;
        border: none;
        border-radius: 5px;
    " >
    `

export default function Order(props) {
    const { t, i18n } = props;
    const styles = useStyles();
    const { walletList:wallets } = useSelector(state => state.wallet);
    const query = useQuery();
    const tabType = query.get('type');

    const [toPay, setToPay] = useState([]);
    const [paid, setPaid] = useState([]);
    const [delivered, setDelivered] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [refunded, setRefunded] = useState([]);

    const tabTypeToIndex = {
        new : 0,
        paid: 1,
        delivered: 2,
        completed: 3, 
        refunded: 4
    };

    const [state, setState] = useState({
        tabIndex: tabTypeToIndex[tabType] ? tabTypeToIndex[tabType] : 0,
        rateDialog: false,
        rateOrderId: null,
        page: 1,
        totalPage: 0,
        total: 0
    });
    const [orderStatus, setOrderStatus] = useState([]);

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [currencyData, setCurrencyData] = useState(null);
    const [refreshData, setRefreshData] = useState(false);

    const [rateOrder, setRateOrder] = useState([]);
    const [shopRate, setShopRate] = useState(0);
    const [shopReview, setShopReview] = useState();
    const [shopInfo, setShopInfo] = useState({});
    const [anonymous, setAnonymous] = useState(false);
    const [rate, setRate] = useState();
    const [review, setReview] = useState();
    const [rateError, setRateError] = useState();
    const [reviewError, setReviewError] = useState();
    const [cancelOrderDialog, setCancelOrderDialog] = useState({
        open: false,
        order_no: '',
        remark: ''
    });
    const [shippingWalletId, setShippingWalletId] = useState([]);

    const location = useLocation();
    let history = useHistory();
    
    const theme = useTheme();

    // console.log("rate", rate);
    // console.log("review", review);

    useEffect(() => {
        if (location.state && location.state.error_msg) {
            addAlert(location.state.error_msg);
            history.replace({ ...history, state: [] });
        }

        isMountedRef.current = true;
        let type = '';

        if (state.tabIndex === 0 || tabType === 'new') {
            type = 'new';
        } else if (state.tabIndex === 1 || tabType === 'paid') {
            type = 'paid';
        } else if (state.tabIndex === 2 || tabType === 'delivered') {
            type = 'delivered';
        } else if (state.tabIndex === 3 || tabType === 'completed') {
            type = 'completed';
        } else if (state.tabIndex === 4 || tabType === 'refunded') {
            type = 'refunded';
        }

        getUrl(`orders?type=${type}`)
            .then(orderList => {
                if (isMountedRef.current) {
                    setCurrencyData(orderList.currency_data);
                    // console.log("orderList", orderList.data)
                    if (orderList.data) {
                        if (state.tabIndex === 0) {
                            setToPay(orderList.data.data);
                        } else if (state.tabIndex === 1) {
                            setPaid(orderList.data.data);
                        } else if (state.tabIndex === 2) {
                            setDelivered(orderList.data.data);
                        } else if (state.tabIndex === 3) {
                            setCompleted(orderList.data.data);
                        } else if (state.tabIndex === 4) {
                            setRefunded(orderList.data.data);
                        }
                    }
                    setState(state => ({ ...state, totalPage: orderList.data.last_page, total: orderList.data.total }));

                    setOrderStatus(orderList.order_status);
                    
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
            
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [state.tabIndex, addAlert, refreshData]);

    useEffect(() => {
        getUrl('shipping_fee/wallet').then(response => {
            if(response.status){
                if(_.size(response.data) > 0){
                    setShippingWalletId(response.data.id);
                }else{
                    setShippingWalletId('cash');
                }
            }else{
                addAlert(JSON.stringify(response.data));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }, [])

    useEffect(() => {
        if (tabType) {
            let newTabIndex = tabTypeToIndex[tabType] ? tabTypeToIndex[tabType] : 0;
            setState(state => ({ ...state, tabIndex: newTabIndex, page: 1, totalPage: 0, total: 0 }));
        }
    }, [tabType])

    const handleChange = (event, newValue) => {
        setState({ ...state, tabIndex: newValue });

        let type = "new";
        if (newValue === 1) {
            type = 'paid';
        } else if (newValue === 2) {
            type = 'delivered';
        } else if (newValue === 3) {
            type = 'completed';
        } else if (newValue === 4) {
            type = 'refunded';
        }
        history.push(`/order?type=${type}`);
    };

    const handleChangeIndex = index => {
        setState({ ...state, tabIndex: parseInt(index) });

        let type = "new";
        if (parseInt(index) === 1) {
            type = 'paid';
        } else if (parseInt(index) === 2) {
            type = 'delivered';
        } else if (parseInt(index) === 3) {
            type = 'completed';
        } else if (parseInt(index) === 4) {
            type = 'refunded';
        }
        history.push(`/order?type=${type}`);
    };

    const handlePaginationChange = (event, value) => {
        setLoading(true);
        getUrl(`orders?type=${tabType}&page=${value}`).then(orderList => {
            if (isMountedRef.current) {
                setCurrencyData(orderList.currency_data);
                // console.log("orderList", orderList.data)
                if (orderList.data) {
                    if (state.tabIndex === 0) {
                        setToPay(orderList.data.data);
                    } else if (state.tabIndex === 1) {
                        setPaid(orderList.data.data);
                    } else if (state.tabIndex === 2) {
                        setDelivered(orderList.data.data);
                    } else if (state.tabIndex === 3) {
                        setCompleted(orderList.data.data);
                    } else if (state.tabIndex === 4) {
                        setRefunded(orderList.data.data);
                    }
                }
                setLoading(false);
                setOrderStatus(orderList.order_status);
                setState(state => ({ ...state, page: value, totalPage: orderList.data.last_page, total: orderList.data.total }));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    };

    const confirmCompleted = (orderNo) => {
        setLoading(true);
        putUrl(`orders/${orderNo}`)
            .then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert(t('order.confirmCompletedSuccess'), 'success');
                    setRefreshData(!refreshData);
                    history.push(`/order?type=completed`);
                } else {
                    addAlert(response.data);
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }

    const handleCancelOrderOpen = (orderNo) => {
        setCancelOrderDialog({
            open: true,
            order_no: orderNo
        });
    }

    const handleCancelOrderClose = () => {
        setCancelOrderDialog({
            open: false,
            order_no: '',
            remark: ''
        });
    }

    const cancelOrder = () => {
        setLoading(true);
        setCancelOrderDialog({ ...cancelOrderDialog, open: false });
        const updateData = {
            remark: cancelOrderDialog.remark
        };
        postUrl(`orders/${cancelOrderDialog.order_no}/cancel_order`, updateData)
        .then(response => {
            setLoading(false);
            if(response.status){
                addAlert(t('order.cancelSuccess'), 'success');
                setRefreshData(!refreshData);
                handleCancelOrderClose();
            }else{
                setCancelOrderDialog({ ...cancelOrderDialog, open: true });
                addAlert(response.data);
            }
        }).catch(error => {
            setLoading(false);
            setCancelOrderDialog({ ...cancelOrderDialog, open: true });
            addAlert(JSON.stringify(error.message));
        });
    }
    
    const generateStatusIcon = status => {
        if (status === 10 || status === 60) {
            //return
            return <CancelIcon fontSize="default" color="secondary" style={{ padding: 2 }} />
        } else if (status === 20) {
            // new
            return <FiberNewIcon fontSize="default" color="secondary" style={{ padding: 2 }} />
        } else if (status === 30) {
            // processing
            return <MoveToInboxIcon fontSize="default" color="secondary" style={{ padding: 2 }} />
        } else if (status === 35 || status === 40) {
            // delivered
            return <LocalShippingIcon fontSize="default" color="secondary" style={{ padding: 2 }} />
        } else if (status === 50) {
            // completed
            return <AssignmentTurnedInIcon fontSize="default" color="secondary" style={{ padding: 2 }} />
        }
    }

    const rateProduct = order_no => {
        const toRateOrder = _.find(completed, { 'order_no': order_no });
        if (_.size(toRateOrder) > 0) {
            setShopInfo(toRateOrder.merchant_company);
        }
        _.map(toRateOrder.order_details, orderItem => {
            // console.log("orderItem", orderItem)
            setRate(rate => ({ ...rate, [orderItem.id]: 0 }))
            setReview(review => ({ ...review, [orderItem.id]: '' }))
            setReviewError(reviewError => ({ ...reviewError, [orderItem.id]: '' }))
        })
        setRateOrder(toRateOrder);
        setState({ ...state, rateDialog: true, rateOrderId: order_no })
    }

    const submitRating = () => {
        const postParam = {
            shop_rate: shopRate,
            shop_review: shopReview,
            anonymous,
            rate,
            review
        };
        // console.log("postParam", postParam)
        putUrl(`orders/review/${rateOrder.order_no}`, postParam).then(response => {
            if (response.status) {
                // console.log("response", response);
                // addAlert(t('order.confirmCompletedSuccess'), 'success');
                // setRefreshData(!refreshData);
                // history.push(`/order?type=completed`);
                addAlert(t('order.ratingComplete'), 'success');
                getUrl(`orders?type=completed`)
                    .then(orderList => {
                        if (orderList.data) {
                            setCompleted(orderList.data.data);
                        }
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                setState({ ...state, rateDialog: false, rateOrderId: null });
                setRateOrder();
                setRate();
                setReview();
                setRateError();
                setReviewError();
            } else {
                if (_.size(response.error) > 0) {
                    _.map(response.error, (errorItem, errorIndex) => {
                        if (_.includes(['shop_rate', 'shop_review'], errorIndex)) {
                            setRateError(rateError => ({ ...rateError, [errorIndex]: errorItem }));
                        } else {
                            const errorKey = _.split(errorIndex, ".");
                            if (_.size(errorKey) > 0) {
                                if (errorKey[0] === 'rate') {
                                    setRateError(rateError => ({ ...rateError, [errorKey[1]]: errorItem }))
                                } else if (errorKey[0] === 'review') {
                                    setReviewError(reviewError => ({ ...reviewError, [errorKey[1]]: errorItem }));
                                }
                            }
                        }
                    })
                }
                // addAlert(123);
            }
        })
            .catch(error => {
                addAlert(JSON.stringify(error.message));
            });
    }

    const priceDisplay = (paymentItem, paymentIndex, orderDetails) => {
        let prefix = '';
        let decimal = 2;
        if(paymentIndex === 'multi_wallet'){
            let multiPricing = orderDetails ? JSON.parse(orderDetails.multi_pricing) : "";
            if(multiPricing){
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
            }else{
                prefix = "Other wallets";
            }
        }else
        if(paymentIndex !== 'cash' && !isNaN(paymentIndex)){
            _.map(wallets, wallet => {
                if(parseInt(wallet.id) === parseInt(paymentIndex)){
                    let name = _.split(wallet.name, '|');
                    prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                    // decimal = wallet.decimal;
                }
            })
        }else if(paymentIndex === 'cash'){
            prefix = i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en;
        }else{
            prefix = paymentIndex;
        }
        return (
            <NumberFormat value={parseFloat(paymentItem).toFixed(decimal)} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={prefix ? prefix+' ' : ''} />
        )
    }

    const generateListing = passOrder => {
        return (
            <Box>
                {
                    _.size(passOrder) > 0
                    ?
                    _.map(passOrder, (orderItem, orderIndex) => {
                        let totalPrice = {};

                        if (_.size(orderItem.order_shippings) > 0) {
                            _.map(orderItem.order_shippings, shippingValue => {
                                let countShipping = false;
                                if(orderItem.status === 60){
                                    countShipping = true;
                                }else{
                                    _.map(shippingValue.order_shipping_details, shipingDetail => {
                                        _.map(orderItem.order_details, orderDetails => {
                                            if(orderDetails.id === shipingDetail.order_detail_id){
                                                if(orderDetails.status){
                                                    countShipping = true; 
                                                }
                                            }
                                        })
                                    })
                                }
                                if(countShipping){
                                    if (shippingValue.channel === 'self' || shippingValue.channel === 'mall') {
                                        let wid = shippingWalletId;
                                        if(shippingValue.wallet_id > 0){
                                            wid = shippingValue.wallet_id;
                                        }
                                        if (_.isUndefined(totalPrice[wid])) {
                                            totalPrice[wid] = 0;
                                        }
                                        totalPrice[wid] += parseFloat(shippingValue.fee);
                                    }
                                }
                            })
                        }
                        let rateDone = false;

                        _.map(orderItem.order_details, (orderDetailsItem) => {
                            if (_.size(orderDetailsItem.product_review) > 0) {
                                rateDone = true;
                            }
                        });

                        return (
                            <List key={orderIndex} className={styles.listStyle} style={{ boxShadow: '0 0 6px #bbbbbb', borderRadius: 15 }}>
                                <ListItem>
                                    <Grid container>
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center">
                                                <Avatar variant="rounded" className={styles.small}>
                                                    <StorefrontIcon fontSize="default" className={styles.user} />
                                                </Avatar>
                                                {/* <Link underline='none' color="inherit" to={`/shop/${orderItem.merchant_company.slug}`} component={RouterLink}> */}
                                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                                        {orderItem.merchant_company[`shop_name_${i18n.language}`]}
                                                    </Typography>
                                                {/* </Link> */}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                                {
                                                    rateDone ?
                                                        <StarsIcon fontSize="default" color="secondary" style={{ padding: 2 }} />
                                                        : generateStatusIcon(orderItem.status)
                                                }
                                                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                                    {
                                                        rateDone ?
                                                            t('order.rated')
                                                            : orderItem.status_display
                                                    }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Divider variant="middle" />
                                <Link underline='none' color="inherit" to={`/order/${orderItem.order_no}`} component={RouterLink}>
                                    {
                                        _.map(orderItem.order_details, (orderDetailsItem) => {
                                            if ((orderItem.status === 60 || orderDetailsItem.status) && _.size(orderDetailsItem.payment) > 0) {
                                                _.map(orderDetailsItem.payment, (paymentItem, paymentIndex) => {
                                                    if(paymentIndex ==="multi_wallet"){
                                                        let multiP = JSON.parse(orderDetailsItem.multi_pricing);
                                                        let name = _.split(multiP.multi_wallet_detail.pricing_name, '|');
                                                        paymentIndex = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                                                    }
                                                    if (_.isUndefined(totalPrice[paymentIndex])) {
                                                        totalPrice[paymentIndex] = 0;
                                                    }
                                                    totalPrice[paymentIndex] += parseFloat(paymentItem);
                                                })
                                            }

                                            return (
                                                <Box key={orderDetailsItem.id}>
                                                    <ListItem>
                                                        <Grid container>
                                                            <Grid item xs={3} sm={2}>
                                                                <Box>
                                                                    {/* <Link underline='none' color="inherit" to={`/order/${orderItem.order_no}`} component={RouterLink}> */}
                                                                    <WebpImg style={{ height: '100%', width: '100%' }} alt="cart product" src={orderDetailsItem.image} />
                                                                    {/* </Link> */}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={9} sm={10} style={{ paddingLeft: 10 }}>
                                                                <Grid item xs={12} style={{ lineHeight: '17px' }}>
                                                                    {/* <Link underline='none' color="inherit" to={`/order/${orderItem.order_no}`} component={RouterLink}> */}
                                                                    <Typography variant="caption">{orderDetailsItem.title_display}</Typography>
                                                                    {/* </Link> */}
                                                                </Grid>

                                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Grid style={{ marginRight: 10 }}>
                                                                        {
                                                                            _.map(orderDetailsItem.product_attribute_display, (variantItem, variantIndex) => {
                                                                                if (_.includes(['color', 'size'], variantIndex)) {
                                                                                    return (
                                                                                        <Chip key={variantItem} size="small" style={{ margin: 5, borderColor: '#999', color: '#888' }} label={<Typography variant="caption" style={{ fontSize: 10 }}>{variantItem}</Typography>} variant="outlined" color="secondary" />
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                </Box>

                                                                <Grid style={{ textAlign:'right' }}>
                                                                    <Typography variant="caption">x{orderDetailsItem.quantity}</Typography>
                                                                    {
                                                                        _.size(orderDetailsItem.payment) > 0
                                                                        ?
                                                                        _.map(orderDetailsItem.payment, (paymentItem, paymentIndex) => {
                                                                            if(parseFloat(paymentItem) > 0){
                                                                                return (
                                                                                    <Box key={paymentIndex}>
                                                                                        <Typography variant="caption" className={styles.priceStyle} style={{ fontSize: 14 }}>
                                                                                            {priceDisplay(paymentItem, paymentIndex, orderDetailsItem)}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                );
                                                                            }
                                                                        })
                                                                        :
                                                                        <Typography variant="caption">
                                                                            <NumberFormat className={styles.priceStyle} style={{ fontSize: 14 }} value={orderDetailsItem.price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} />
                                                                        </Typography>
                                                                    }
                                                                    { 
                                                                        orderDetailsItem.status
                                                                        ?
                                                                        null
                                                                        :
                                                                        <Typography variant="caption" style={{ padding: "5px", borderRadius: "10px", border: "1px solid grey", color: "grey"}}>
                                                                            {t('order.productCancelled')}
                                                                        </Typography>
                                                                    }
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </ListItem>
                                                    <Divider variant="middle" />
                                                </Box>
                                            )
                                        })
                                    }
                                </Link>

                                <ListItem>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', fontSize: 12 }}>
                                                    {t('order.orderTotal')}:
                                                    {
                                                        _.size(totalPrice)
                                                        ?
                                                        _.map(totalPrice, (priceItem, priceIndex) => {
                                                            if(parseFloat(priceItem) > 0){
                                                                return (
                                                                    <Box key={priceIndex} className={styles.priceStyle} style={{ paddingLeft: 8, fontSize: 12 }}>
                                                                        {priceDisplay(priceItem, priceIndex)}
                                                                    </Box>
                                                                )
                                                            }
                                                        })
                                                        :
                                                        null
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: 8, display: 'flex', justifyContent: 'center' }}>
                                            {
                                                orderItem.status === parseInt(orderStatus['new']) ?
                                                    <Button size="large" variant="contained" color="secondary" className={styles.buttonStyle} onClick={() => history.push(`/payment/${encodeURIComponent(orderItem.purchase_no)}`)}>
                                                        {/* <Link underline='none' color="inherit" to={`/payment/${encodeURIComponent(orderItem.purchase_no)}`} component={RouterLink}> */}
                                                        <Typography variant="caption">{t('button.payNow')}</Typography>
                                                        {/* </Link> */}
                                                    </Button>
                                                    : null
                                                }
                                                <Button size="large" variant="outlined" color="secondary" className={ styles.buttonStyle } onClick={ () => history.push(`/order/${orderItem.order_no}`) }>
                                                    {/* <Link underline='none' color="inherit" to={`/order/${orderItem.order_no}`} component={RouterLink}> */}
                                                        <Typography variant="caption">
                                                            { t('order.viewOrder') }
                                                        </Typography>
                                                    {/* </Link> */}
                                                </Button>
                                                {
                                                    (orderItem.status === parseInt(orderStatus['shipping']) || orderItem.status === parseInt(orderStatus['delivered'])) ?
                                                    <Button size="large" variant="contained" color="secondary" className={ styles.buttonStyle } onClick={ () => confirmCompleted(orderItem.order_no)}>
                                                        <Typography variant="caption">
                                                            {t('order.confirmCompleted')}
                                                        </Typography>
                                                    </Button>
                                                    : null
                                            }
                                            {
                                                orderItem.status === parseInt(orderStatus['completed']) && !rateDone ?
                                                    <Button size="large" variant="contained" color="secondary" className={styles.buttonStyle} onClick={() => rateProduct(orderItem.order_no)}>
                                                        <Typography variant="caption">
                                                            {t('order.rateProduct')}
                                                        </Typography>
                                                    </Button>
                                                    : null
                                            }
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </List>
                        )
                    })
                    :
                    <Card style={{ borderRadius: 0 }} elevation={0}>
                        <CardContent style={{ height: 400, borderRadius: 0 }}>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                                <WebpImg src="/images/no_data/noorder.png" style={{ width: 180 }} alt="" />
                                <Typography variant="h5" style={{ textAlign: "center", paddingLeft: 44 }}>{t('order.noOrder')}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                }
                {state.total > 0 &&
                    <Grid container spacing={1} justify="center" >
                        <Pagination count={state.totalPage} shape="rounded" page={state.page}
                            onChange={handlePaginationChange}
                            renderItem={(item) => (
                                <PaginationItem
                                    {...item}
                                />
                            )}
                        />
                    </Grid>
                }
            </Box>
        )
    }

    return (
        <Box>
            <AppBar position="static" color="inherit" elevation={0} style={{ backgroundColor: "transparent" }}>
                <Tabs
                    value={state.tabIndex}
                    onChange={handleChange}
                    // variant="fullWidth"
                    scrollButtons="on"
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="scrollable"
                    fullWidth
                >
                    <Tab style={{ fontSize: '13px' }} label={t('order.toPay')} />
                    <Tab style={{ fontSize: '13px' }} label={t('order.paid')} />
                    <Tab style={{ fontSize: '13px' }} label={t('order.delivered')} />
                    <Tab style={{ fontSize: '13px' }} label={t('order.completed')} />
                    <Tab style={{ fontSize: '13px' }} label={t('order.refunded')} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis='x'
                index={state.tabIndex}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={state.tabIndex} index={0} style={{ backgroundColor: 'transparent' }}>
                    <Grid container spacing={1} justify="center" style={{ minHeight: 450 }}>
                        {/* <Grid item xs={12}>
                            {
                                i18n.language === 'cn'?
                                <div dangerouslySetInnerHTML={{ __html: trackButtonCn }} style={{float: "right" }} />
                                :
                                <div dangerouslySetInnerHTML={{ __html: trackButtonEn }} style={{float: "right" }} />
                            }
                        </Grid> */}
                        <Grid item xs={12}>
                            {
                                _.size(toPay) > 0 ? _.map(toPay, (purchaseItem, purchaseIndex) => {
                                    // console.log("purchaseItem", purchaseItem);
                                    let totalPrice = {};
                                    let showOrder = true;

                                    // hide payment failed order
                                    if (_.size(purchaseItem) > 0) {
                                        _.map(purchaseItem, (orderItem) => {
                                            if (_.size(orderItem.payments) > 0 && orderItem.status) {
                                                _.map(orderItem.payments, paymentItem => {
                                                    if (paymentItem.status === parseInt(orderStatus['returned']) || paymentItem.status === parseInt(orderStatus['new'])) {
                                                        showOrder = false
                                                    }
                                                })
                                            }
                                        })
                                    }

                                    if (showOrder) {
                                        return (
                                            <Card className={styles.cardStyle} key={purchaseIndex} elevation={0} style={{ boxShadow: '0 0 6px #bbbbbb', borderRadius: 15 }}>
                                                <CardContent style={{ padding: 0 }}>
                                                    {
                                                        _.size(purchaseItem) > 0 ? _.map(purchaseItem, (orderItem) => {
                                                            if (_.size(orderItem.order_shippings) > 0) {
                                                                _.map(orderItem.order_shippings, shippingValue => {
                                                                    let countShipping = false;
                                                                    _.map(shippingValue.order_shipping_details, shipingDetail => {
                                                                        _.map(orderItem.order_details, orderDetails => {
                                                                            if(orderDetails.id === shipingDetail.order_detail_id){
                                                                                if(orderDetails.status){
                                                                                    countShipping = true; 
                                                                                }
                                                                            }
                                                                        })
                                                                    })
                                                                    if(countShipping){
                                                                        if (shippingValue.channel === 'self' || shippingValue.channel === 'mall') {
                                                                            let wid = shippingWalletId;
                                                                            if(shippingValue.wallet_id > 0){
                                                                                wid = shippingValue.wallet_id;
                                                                            }
                                                                            if (_.isUndefined(totalPrice[wid])) {
                                                                                totalPrice[wid] = 0;
                                                                            }
                                                                            totalPrice[wid] += parseFloat(shippingValue.fee);
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            return (
                                                                <List key={orderItem.id}>
                                                                    <ListItem>
                                                                        <Grid container>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <Box display="flex" alignItems="center">
                                                                                    <Avatar variant="rounded" className={styles.small}>
                                                                                        <StorefrontIcon fontSize="default" className={styles.user} />
                                                                                    </Avatar>
                                                                                    {/* <Link underline='none' color="inherit" to={`/shop/${orderItem.merchant_company.slug}`} component={RouterLink}> */}
                                                                                        <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                                                                            {orderItem.merchant_company[`shop_name_${i18n.language}`]}
                                                                                        </Typography>
                                                                                    {/* </Link> */}
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <Box display="flex" alignItems="center" justifyContent="flex-end">
                                                                                    {generateStatusIcon(orderItem.status)}
                                                                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                                                                        {orderItem.status_display}
                                                                                    </Typography>
                                                                                    {
                                                                                        orderItem.status === 20
                                                                                        ?
                                                                                            <Button variant="outlined" color="secondary" style={{ borderRadius: "20px"}} className={ styles.buttonStyle } onClick={ () => handleCancelOrderOpen(orderItem.order_no) }>
                                                                                                <Typography variant="caption">
                                                                                                    { t('order.cancelOrder') }
                                                                                                </Typography>
                                                                                            </Button>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </ListItem>
                                                                    <Divider variant="middle" />
                                                                    {
                                                                        _.map(orderItem.order_details, (orderDetailsItem) => {
                                                                            if (_.size(orderDetailsItem.payment) > 0 && orderDetailsItem.status) {
                                                                                _.map(orderDetailsItem.payment, (paymentItem, paymentIndex) => {
                                                                                    if(paymentIndex ==="multi_wallet"){
                                                                                        let multiP = JSON.parse(orderDetailsItem.multi_pricing);
                                                                                        paymentIndex = multiP.multi_wallet_detail.pricing_name;
                                                                                    }
                                                                                    if (_.isUndefined(totalPrice[paymentIndex])) {
                                                                                        totalPrice[paymentIndex] = 0;
                                                                                    }
                                                                                    totalPrice[paymentIndex] += parseFloat(paymentItem);
                                                                                })
                                                                            }
                                                                            return (
                                                                                <Box key={orderDetailsItem.id}>
                                                                                    <ListItem>
                                                                                        <Grid container>
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Box>
                                                                                                    <Link underline='none' color="inherit" to={`/order/${orderItem.order_no}`} component={RouterLink}>
                                                                                                        <WebpImg style={{ height: '100%', width: '100%' }} alt="cart product" src={orderDetailsItem.image} />
                                                                                                    </Link>
                                                                                                </Box>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9} style={{ paddingLeft: 10}}>
                                                                                                <Grid item xs={12}>
                                                                                                    <Link underline='none' color="inherit" to={`/order/${orderItem.order_no}`} component={RouterLink}><Typography variant="caption">{orderDetailsItem.title_display}</Typography></Link>
                                                                                                </Grid>
                                                                                                
                                                                                                    {
                                                                                                        _.map(orderDetailsItem.product_attribute_display, (variantItem, variantIndex) => {
                                                                                                            if (_.includes(['color', 'size'], variantIndex)) {
                                                                                                                return (
                                                                                                                    <Grid item xs={12} key={variantItem}>
                                                                                                                        <Chip size="small" style={{ margin: 5 }} label={<Typography variant="caption" style={{ fontSize: 10 }}>{variantItem}</Typography>} variant="outlined" color="secondary" />
                                                                                                                    </Grid>
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                
                                                                                                <Grid item xs={12} style={{textAlign:'right'}}>
                                                                                                <Typography variant="caption" style={{paddingLeft:8}}>x{orderDetailsItem.quantity}</Typography>
                                                                                                    {
                                                                                                        _.size(orderDetailsItem.payment) > 0
                                                                                                        ?
                                                                                                        _.map(orderDetailsItem.payment, (paymentItem, paymentIndex) => {
                                                                                                            if(parseFloat(paymentItem) > 0){
                                                                                                                return (
                                                                                                                    <Box key={paymentIndex}>
                                                                                                                        <Typography variant="caption" className={styles.priceStyle}>
                                                                                                                            {priceDisplay(paymentItem, paymentIndex, orderDetailsItem)}
                                                                                                                        </Typography>
                                                                                                                    </Box>
                                                                                                                );
                                                                                                            }
                                                                                                        })
                                                                                                        :
                                                                                                        <Typography variant="caption">
                                                                                                            <NumberFormat className={styles.priceStyle} value={orderDetailsItem.price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} />
                                                                                                        </Typography>
                                                                                                    }
                                                                                                    
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </ListItem>
                                                                                    <Divider variant="middle" />
                                                                                </Box>
                                                                            )
                                                                        })
                                                                    }
                                                                    <Divider variant="middle" />
                                                                </List>
                                                            )
                                                        }) : null
                                                    }
                                                    <List>
                                                        <ListItem>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} style={{ padding: 5 }}>
                                                                    <Box style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                                                    <Typography style={{marginRight:5}}>{t('order.orderTotal')}: </Typography>
                                                                    {
                                                                        _.size(totalPrice) ?
                                                                            _.map(totalPrice, (priceItem, priceIndex) => {
                                                                                if(parseFloat(priceItem) > 0){
                                                                                    return (
                                                                                        <Box key={priceIndex} className={styles.priceStyle} style={{ paddingLeft: 8}}>
                                                                                            {priceDisplay(priceItem, priceIndex)}
                                                                                        </Box>
                                                                                    )
                                                                                }
                                                                            })
                                                                        : null
                                                                    }
                                                                    </Box>
                                                                </Grid>

                                                                <Grid item xs={12} style={{ textAlign: 'end', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                                                    <Button size="large" variant="contained" color="secondary" style={{border:'2px solid #65c8d0', boxShadow: '2px 2px 4px #bdbdbd', borderRadius: 5}} className={styles.buttonStyle}>
                                                                        <Link underline='none' color="inherit" to={`/payment/${encodeURIComponent(purchaseIndex)}`} component={RouterLink}>
                                                                            <Typography variant="caption">{t('button.payNow')}</Typography>
                                                                        </Link>
                                                                    </Button>

                                                                    {/* <Button size="large" variant="outlined" color="secondary" className={ styles.buttonStyle }>
                                                                        <Link underline='none' color="inherit" to={`/order/${purchaseIndex}`} component={RouterLink}>
                                                                            <Typography variant="caption">
                                                                                { t('order.viewOrder') }
                                                                            </Typography>
                                                                        </Link>
                                                                    </Button> */}
                                                                </Grid>


                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                </CardContent>
                                            </Card>

                                        )
                                    }

                                    return null
                                })
                                :
                                <Card style={{ borderRadius: 0 }} elevation={0}>
                                    <CardContent style={{ height: 400, borderRadius: 0 }}>
                                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                                            <WebpImg src="/images/no_data/noorder.png" style={{ width: 180 }} alt="" />
                                            <Typography variant="h5" style={{ textAlign: "center", paddingLeft: 44 }}>{t('order.noOrder')}</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            }
                            {state.total > 0 && 
                            <Grid container spacing={1} justify="center" >
                                <Pagination count={state.totalPage} shape="rounded" page={state.page}
                                    onChange={handlePaginationChange}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            {...item}
                                        />
                                    )}
                                />
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={state.tabIndex} index={1} style={{ backgroundColor: 'transparent' }}>
                    <Grid container spacing={1} justify="center" style={{ minHeight: 450 }}>
                        <Grid item xs={12}>
                        {
                                i18n.language === 'cn'?
                                <div dangerouslySetInnerHTML={{ __html: trackButtonCn }} style={{float: "right" }} />
                                :
                                <div dangerouslySetInnerHTML={{ __html: trackButtonEn }} style={{float: "right" }} />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {generateListing(paid)}
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={state.tabIndex} index={2} style={{ backgroundColor: 'transparent' }}>
                    <Grid container spacing={1} justify="center" style={{ minHeight: 450 }}>
                        <Grid item xs={12}>
                            {
                                i18n.language === 'cn'?
                                <div dangerouslySetInnerHTML={{ __html: trackButtonCn }} style={{float: "right" }} />
                                :
                                <div dangerouslySetInnerHTML={{ __html: trackButtonEn }} style={{float: "right" }} />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {generateListing(delivered)}
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={state.tabIndex} index={3} style={{ backgroundColor: 'transparent' }}>
                    <Grid container spacing={1} justify="center" style={{ minHeight: 450 }}>
                        {/* <Grid item xs={12}>
                            {
                                i18n.language === 'cn'?
                                <div dangerouslySetInnerHTML={{ __html: trackButtonCn }} style={{float: "right" }} />
                                :
                                <div dangerouslySetInnerHTML={{ __html: trackButtonEn }} style={{float: "right" }} />
                            }
                        </Grid> */}
                        <Grid item xs={12}>
                            {generateListing(completed)}
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={state.tabIndex} index={4} style={{ backgroundColor: 'transparent' }}>
                    <Grid container spacing={1} justify="center" style={{ minHeight: 450 }}>
                        {/* <Grid item xs={12}>
                            {
                                i18n.language === 'cn'?
                                <div dangerouslySetInnerHTML={{ __html: trackButtonCn }} style={{float: "right" }} />
                                :
                                <div dangerouslySetInnerHTML={{ __html: trackButtonEn }} style={{float: "right" }} />
                            }
                        </Grid> */}
                        <Grid item xs={12}>
                            {generateListing(refunded)}
                        </Grid>
                    </Grid>
                </TabPanel>
            </SwipeableViews>
            <Dialog
                open={state.rateDialog}
                onClose={() => {
                    setState({ ...state, rateDialog: false, rateOrderId: null });
                    setRateOrder();
                    setRate();
                    setShopRate();
                    setAnonymous(false);
                    setShopReview();
                    setReview();
                    setRateError();
                    setReviewError();
                }}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {t('order.rateProduct')}
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Box style={{ backgroundColor: theme.palette.primary.main }} paddingLeft={1} paddingRight={1} marginBottom={2} borderRadius={5}>
                            <Typography style={{ color: '#FFF', fontSize: 12 }}>{`${t('order.rateProductDesc')}`}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                            <Switch checked={anonymous} onChange={() => setAnonymous(!anonymous)} />
                            <Typography style={{ fontSize: 12 }}>{t('order.stayAnonymous')}</Typography>
                        </Box>
                        {
                            _.size(rateOrder) > 0 ?
                                <Box>
                                    {

                                        _.map(rateOrder.order_details, (orderDetailsItem) => {
                                            return (
                                                <Box key={orderDetailsItem.id}>
                                                    <ListItem>
                                                        <Grid container>
                                                            <Grid item xs={3} sm={2}>
                                                                <Box>
                                                                    <WebpImg style={{ height: '100%', width: '100%' }} alt="cart product" src={orderDetailsItem.image} />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={9} sm={10} style={{ paddingLeft: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="caption">{orderDetailsItem.title_display}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Rating
                                                                        name={`rating_${orderDetailsItem.id}`}
                                                                        defaultValue={rate[orderDetailsItem.id]}
                                                                        precision={1}
                                                                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                                                        onChange={(event, newValue) => {
                                                                            setRate(rate => ({ ...rate, [orderDetailsItem.id]: newValue }))
                                                                        }}
                                                                    />
                                                                    {
                                                                        _.has(rateError, orderDetailsItem.id) ?
                                                                            <Typography color="secondary" style={{ fontSize: 10 }}>{t('order.invalidRate')}</Typography>
                                                                            : null
                                                                    }
                                                                    <Typography></Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <TextField
                                                                        margin="dense"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        label={t('order.review')}
                                                                        placeholder={t('order.leaveReview')}
                                                                        type="text"
                                                                        inputProps={{ name: 'review', style: { fontSize: 12 } }}
                                                                        value={review[orderDetailsItem.id]}
                                                                        error={reviewError[orderDetailsItem.id] ? true : false}
                                                                        helperText={reviewError[orderDetailsItem.id] ? reviewError[orderDetailsItem.id] : ''}
                                                                        onChange={({ target }) => {
                                                                            // console.log("orderDetailsItem.id", orderDetailsItem.id)
                                                                            setReview({ ...review, [orderDetailsItem.id]: target.value });
                                                                        }}
                                                                        multiline
                                                                        rows={3}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                    <Divider variant="middle" />
                                                </Box>
                                            )
                                        })
                                    }
                                    <Box padding={2} display="flex" alignItems="center" flexDirection="column">
                                        {/* {console.log("shopInfo", shopInfo)} */}
                                        {/* <WebpImg src={rateOrder.order_details[0].merchant_company.} style={{ height: 56, width: 56 }} alt={`${shopInfo[`shop_name_${i18n.language}`]}`}/> */}
                                        <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>How would you like shop in {shopInfo[`shop_name_${i18n.language}`]} ?</Typography>
                                        <Rating
                                            name="shop_rating"
                                            defaultValue={shopRate}
                                            value={shopRate}
                                            max={3}
                                            precision={1}
                                            IconContainerComponent={IconContainer}
                                            onChange={(event, newValue) => {
                                                setShopRate(newValue)
                                            }}
                                        />
                                        {
                                            _.has(rateError, 'shop_rate') ?
                                                <Typography color="secondary" style={{ fontSize: 10 }}>{t('order.invalidRate')}</Typography>
                                                : null
                                        }
                                        <FormControl fullWidth variant="outlined">
                                            <TextField
                                                margin="dense"
                                                variant="outlined"
                                                fullWidth
                                                label={t('order.review')}
                                                placeholder={t('order.leaveReview')}
                                                type="text"
                                                inputProps={{ name: 'review', style: { fontSize: 12 } }}
                                                value={shopReview}
                                                error={reviewError['shop_review'] ? true : false}
                                                helperText={reviewError['shop_review'] ? reviewError['shop_review'] : ''}
                                                onChange={({ target }) => {
                                                    setShopReview(target.value);
                                                }}
                                                multiline
                                                rows={3}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>
                                : null
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => {
                            setState({ ...state, rateDialog: false, rateOrderId: null });
                            setRateOrder();
                            setRate();
                            setShopRate();
                            setShopReview();
                            setAnonymous(false);
                            setReview();
                            setRateError();
                            setReviewError();
                        }}
                        color="default"
                    >
                        <Typography variant="overline">{t('button.cancel')}</Typography>
                    </Button>
                    <Button color="secondary" onClick={submitRating}>
                        <Typography variant="overline">{t('button.confirm')}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={ cancelOrderDialog.open } onClose={ handleCancelOrderClose } >
                <DialogTitle>
                    { t('order.cancelOrder') }
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} justify="center" >
                        <Grid item xs={12}>
                            { t('order.cancelOrderReminder') }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true} 
                                variant="outlined" 
                                label={t('order.cancelOrderRemark')}
                                multiline
                                value={ cancelOrderDialog.remark }
                                onChange={ (event) => { setCancelOrderDialog({ ...cancelOrderDialog , remark: event.target.value }) }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" className={ styles.buttonStyle } onClick={ () => cancelOrder()} >
                        <Typography variant="overline">{ t('order.confirmCancelOrder') }</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            <Helmet>
                <script src="//www.tracking.my/track-button.js" async></script>
                <script type="text/javascript">
                    {`  function inputTrack() {
                            var num = document.getElementById("TrackNo").value;
                            if(num===""){
                            alert("Please enter tracking number");
                            return;
                            }
                            TrackButton.track({
                                tracking_no: num
                             });
                         }
                    `}
                </script>
            </Helmet>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    cardStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
        borderRadius: 0
    },
    paddingVerticalZero: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    chipLabel: {
        fontSize: 10
    },
    small: {
        borderRadius: 30,
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginLeft: 5,
        marginRight: 5,
    },
    user: {
        padding: 5,
        backgroundColor: theme.palette.secondary.main,
        color: "#fff"
    },
    priceStyle: {
        fontSize: 16,
        color: theme.palette.secondary.main,
    },
    totalPriceStyle: {
        fontSize: 30,
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        paddingLeft: 5
    },
    buttonStyle: {
        margin: 5,
        borderRadius: 0
    }
}));