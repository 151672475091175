import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useNotificationLoading from '../helper/useNotificationLoading';
import _ from 'lodash';

import { makeStyles, useTheme, createMuiTheme, ThemeProvider, FormControlLabel, FormControl, Radio, RadioGroup, Checkbox, Typography,  Avatar, Link, Box, Accordion, AccordionDetails, AccordionSummary  } from '@material-ui/core';
import { PersonAdd, PersonOutlineRounded, MeetingRoomOutlined, PublicOutlined, ExpandMoreOutlined, NotificationsNoneRounded,  LinkOutlined, ChevronRightSharp,CheckCircle, AccountBalanceOutlined, AssignmentInd, ContactMailOutlined, LocalMall, AssignmentRounded,HomeWorkRounded } from '@material-ui/icons';
import { FiChevronRight, FiKey } from 'react-icons/fi';

import { changeLanguage as reduxChangeLanguage } from '../actions';
import { getUrl, logoutUser } from '../helper/ApiAction';
import TitleBarWhite from './Layouts/TitleBarWhite';
import TitleBar from './Layouts/TitleBar';
import theme from '../Theme';
  
export default function Policies() {
    const { t, i18n } = useTranslation();
    const [valueLanguage, setLanguage] = useState(i18n.language);

    const { accessToken } = useSelector(state => state.general);
    const { fr_id } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = useStyles();
    const history = useHistory();

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    return (

        <Box paddingBottom={5} style={{background: theme.palette.white.mobileBkg, paddingBottom:80, minHeight: '100%' }}>{/* className={styles.root} style={{ padding: '0 0 80px',}} */}
            {/* <div style={{position:'fixed', width:600, maxWidth:'100%', zIndex:2}}>
                <TitleBarWhite height={0} title={t('support.policies')} displayCartTop back  displayInfo={false} />
            </div> */}
            <TitleBar height="99px" title={t('profile.settings')} displayQr displayCartTop back/>

                {/* //Support */}
                <Box style={{padding:'18px 15px 0', color:theme.palette.silver.text}}>
                    {/* <Typography style={{paddingBottom:15}}>{t('support.policies')}</Typography> */}
                    <Link underline='none' to="/termOfUse" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                {/* <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar> */}
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.termOfUse')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>

                    <Link underline='none' to="/refundAndReturnAgreement" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                {/* <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar> */}
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.refundAgreement')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>

                    <Link underline='none' to="/privacyAgreement" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                {/* <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar> */}
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.privacyAgreement')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>

                    <Link underline='none' to="/merchantServiceAgreement" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                {/* <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar> */}
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.merchantServicesAgreement')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
             
                </Box>
         
             
        </Box>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
       
    },
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
          backgroundColor: "transparent"
        }
      },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    iconColor: {
        padding: 5,
        // backgroundColor: theme.palette.profile.user,
        // backgroundColor:'#d7d7d7',
        backgroundColor:'#c9c9c9',
        color:"#fff"
    },
   

}));

