import React, { useState, useRef, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import _ from 'lodash';
import { postUrl } from '../helper/ApiAction';
import { APP_NAME } from '../configs/Config';
import useNotificationLoading from '../helper/useNotificationLoading';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TextField, Button, Typography, Grid, Link, Box, Paper, Divider } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import { FiChevronLeft } from 'react-icons/fi';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/login">
                { APP_NAME }
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

export default function PasswordForgot() {

    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const isMountedRef = useRef(null);
    // const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState([]);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');

    useEffect(() => {
        isMountedRef.current = true;

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const forgotPassword = () => {
        setLoading(true);

        postUrl(`forgot_password`, { email: email, username: username }).then(result => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error } = result;
                if (status === 1) {
                    addAlert(message, 'success', '', '');
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n" + value[0];
                        })
                        setInputErrors(error);
                    }
                    addAlert(message, 'error', '', '');
                }
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(error + "\n" + t('error.contactSupport'), 'error', '', '');
            }
        });
    }

    return (
        <Grid container component="main" className={classes.root} justify="center">
        <Box component={Paper} width="100%" style={{backgroundColor:theme.palette.white.mobileBkg, boxShadow:'none'}}>
            <Link underline='none' to="/login" component={RouterLink} >
                <Box style={{color: theme.palette.silver.text, paddingLeft:20, paddingTop:20, display:'flex', alignItems:'center'}}>
                    <FiChevronLeft style={{marginRight:10}}/>
                    <Typography>{t('button.back')}</Typography>
                </Box>
            </Link>
            <div className={classes.paper}>
                <Link color="inherit" href="/login">
                    <img src="/images/logo.png" style={{ height: 86 }} alt="" className={classes.logoImg} />
                </Link>
                    <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>
                        { t('forgotPassword.title')}
                    </Typography>
                    <Divider variant="middle" style={{ width: '100%', marginTop: 20, marginBottom: 20 }} />
                    <Typography variant="body2">
                        { t('forgotPassword.subtitle')}
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={ev => { ev.preventDefault(); forgotPassword() }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label={ t('forgotPassword.username')}
                            name="username"
                            value={username}
                            onChange={({ target }) => setUsername(target.value)}
                            autoComplete="username"
                            autoFocus
                            error={inputErrors.username ? true : false}
                        />
                        {inputErrors.username &&
                            <Typography color="error"><ErrorIcon style={{ verticalAlign: "middle" }} />{inputErrors.username}</Typography>
                        }
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={ t('forgotPassword.email')}
                            name="email"
                            value={email}
                            onChange={({ target }) => setEmail(target.value)}
                            autoComplete="email"
                            // autoFocus
                            error={inputErrors.email ? true : false}
                        />
                        <Typography variant="body2" >
                            {t('forgotPassword.helperText')}
                        </Typography>
                        {inputErrors.email &&
                            <Typography color="error"><ErrorIcon style={{ verticalAlign: "middle" }} />{inputErrors.email}</Typography>
                        }
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            style={{width:180, borderRadius:48}}
                        >
                            {t('button.reset')}
                        </Button>
                        </div>
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
                </Box>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoImg: {
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
    },
}));