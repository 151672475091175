import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useDispatch } from 'react-redux';
import { postUrl } from '../helper/ApiAction';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Container, Link, Box, TextField } from '@material-ui/core';
import 'react-awesome-slider/dist/styles.css';
import { Mail } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CONTACT_US_EMAIL } from '../configs/Config';
import useNotificationLoading from '../helper/useNotificationLoading';
import SendIcon from '@material-ui/icons/Send';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TitleBarWhite from './Layouts/TitleBarWhite';
import WebpImg from './Layouts/WebpImg';

function useInput({ type, label, multiline }) {
    const [value, setValue] = useState("");
    const [error, setErrorValue] = useState("");
    const input = 
    <TextField label={label} variant="outlined" error={error ? true : false}  helperText={error} value={value} onChange={e => setValue(e.target.value)} type={type} style={{ width: '100%', maxWidth: '100%', marginTop: '15px' }} multiline={multiline} />;
    return [value, input, setErrorValue];
}

export default function Home() {
    const { t } = useTranslation();
    const styles = useStyles();
    const classes = useStyles();
    // const dispatch = useDispatch();
    const { addAlert } = useNotificationLoading();
    // const [setMessageValue] = React.useState('Controlled');

    const [name, nameInput, nameValidate] = useInput({ type: "text", label: t('form.name'), multiline: false });
    const [email, emailInput, emailValidate] = useInput({ type: "text", label: t('form.email'), multiline: false });
    const [subject, subjectInput, subjectValidate] = useInput({ type: "text", label: t('form.subject'), multiline: false });
    const [message, messageInput, messageValidate] = useInput({ type: "text", label: t('form.message'), multiline: true });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const validate = { name: nameValidate, email: emailValidate, subject: subjectValidate, message: messageValidate };

    let history = useHistory();
    const { accessToken } = useSelector(state => state.general);

    // const handleChange = (event) => {
    //     setMessageValue(event.target.value);
    // };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        postUrl(`contactus`, {
            name,
            email,
            subject,
            message
        })
        .then((response) => {
            console.log(response);
            let {status, message, error} = response;
            if(status === 0){
                setErrorMessage(message);
                _.map(error, (value, key) => {
                    validate[key](value[0]);
                })
            }else{
                setSuccessMessage(message);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const chatWithAdmin = () => {
        postUrl('adminchats').then(response => {
            // console.log("adminchats",response);
            if (response.status) {
                history.push(`/customerservice`);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    return (
        <Box className={styles.root} style={{ padding: '0', paddingBottom: 40 }}>
             <div style={{position:'fixed', width:600, maxWidth:'100%', zIndex:2}}>
                <TitleBarWhite height={0} title={t('item.products')} displayCartTop back  displayInfo={false} />
            </div>
            <Box style={{  padding: '60px 15px',paddingBottom: 110 }}>
                <Typography style={{ fontSize: '30px', color: '#757575', textAlign:'center' }} >{t('form.getInTouch')}</Typography>
                <Typography style={{ fontSize: '15px', color: '#757575', textAlign:'center' }}>{t('contact.submessage')}</Typography>

                <Box style={{display:'flex', justifyContent:'space-around', alignItems:'center',width:'100%', paddingTop:30}}>
                <a className={styles.footerFont} style={{ fontSize: '13px', textDecoration: 'none' }} href={`mailto:${CONTACT_US_EMAIL}`}>
                        <Box>
                            <WebpImg src="/images/general/icon/email.png" style={{ height: 65, borderRadius: 18, boxShadow: '#00000036 4px 4px 17px 1px' }} alt=" icon" />
                            <Typography style={{ fontSize: 11, color: '#757575', textAlign: 'center' }}>{t('support.emailUs')}</Typography>
                        </Box>
                    </a>
                    
                    <Box>
                        <Link underline='none' to="/contactUs" component={RouterLink}>
                            <WebpImg src="/images/general/icon/call.png" style={{ height: 65, borderRadius: 18, boxShadow: '#00000036 4px 4px 17px 1px' }} alt=" message" />
                            <Typography style={{ fontSize: 11, color: '#757575', textAlign: 'center' }}>{t('contact.reachUs')}</Typography>
                        </Link>
                    </Box>
                    
                    {
                            accessToken?
                    <Box onClick={chatWithAdmin} style={{cursor:'pointer'}}>
                        <WebpImg src="/images/general/icon/livechat.png" style={{ height: 65, borderRadius:18, boxShadow:'#00000036 4px 4px 17px 1px' }} alt=" livechat" />
                        <Typography style={{fontSize:11, color:'#757575', textAlign:'center'}}>{t('support.liveChat')}</Typography>
                    </Box>
                    :
                    null
                }
                            
                </Box>
            </Box>

            <form onSubmit={handleSubmit} className={classes.root} noValidate autoComplete="off" style={{padding:'24px 0'}}>
            <Container style={{ marginTop: '-76px', width: 940, maxWidth: '100%' }}>
            { successMessage ? 
                        <Box style={{ padding: '15px 24px', backgroundColor: '#fff', borderRadius: '0 15px 15px 0' }}>
                            <Typography style={{paddingBottom:15, color:'#f7952a'}}>{t('support.dropUsAlLine')}</Typography>
                            <CheckCircleOutlineIcon style={{ color: 'green' }} fontSize="large" />
                            { successMessage }
                        </Box>
                        :
                        <Box style={{ padding: '15px 24px', backgroundColor: '#fff', borderRadius: '0 15px 15px 0' }}>
                            <Typography style={{paddingBottom:15, color:'#f7952a'}}>{t('support.dropUsALine')}</Typography>
                            <Typography style={{ fontSize: 12, color: 'red' }}>{ errorMessage }</Typography>
                            {nameInput}
                            {emailInput}
                            {subjectInput}
                            {messageInput}
                        
                        <div style={{ width: '100%', maxWidth: '100%', justifyContent:'flex-end', display:'flex' }}>
                            <Button type="submit" style={{ marginTop: '10px', padding: '11px 15px', backgroundColor: '#f7952a', color: '#fff', borderRadius: '48px', display:'flex', alignItems:'center', boxShadow:'6px 6px 20px 0 #f7952a6e'}}>
                                <Typography>{t('button.sendNow')}</Typography>
                                <SendIcon style={{ marginLeft: '15px', fontSize: '22px' }} />
                            </Button>
                        </div>
                        </Box>
                    }
            </Container>
            </form>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    cardContentRootStyle: {
        padding: '5px !important',
    },
    cardRootStyle: {
        margin: '0px 1px 0px 1px'
    },
    buttonFont: {
        fontSize: '10px',
    },
    buttonStyle: {
        margin: '5px',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    listItemStyle: {
        justifyContent: 'center',
        padding: '5px 0px 5px 0px',
    },
    cardStyle: {
        // minHeight: 380,
        borderRadius: 0
    },
    overlay: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        color: 'black',
        backgroundColor: 'white'
    }
}));