import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Card, CardContent, Breadcrumbs, Typography, Container, Grid, Link, CardMedia, Divider, List, ListItem, TextField, MenuItem, Hidden, ListItemText, Tooltip } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { getUrl } from '../helper/ApiAction';
import { useQuery } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';
import WebpImg from './Layouts/WebpImg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { FiChevronLeft } from 'react-icons/fi';

import { createMuiTheme, ThemeProvider, MuiThemeProvider } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';

export default function Categories() {
    const [breadcrumb, setCategoryBreadCrumb] = useState([]);
    const [categoryChilds, setCategoryChilds] = useState([]);
    const [categoryProducts, setCategoryProducts] = useState([]);
    const [categories, setCategories] = useState(null);
    const [category, setCategory] = useState('all');
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);
    const history = useHistory();
    const { rank } = useSelector(state => state.user);

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    let { id } = useParams();
    let query = useQuery();
    const pageNumber = query.get("page");

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        // if (id !== "all") {
        //     getUrl(`categories/${id}/breadcrumb`).then(response => {
        //         if (response) {
        //             if (isMountedRef.current) {
        //                 setCategoryBreadCrumb(response.data);
        //                 setCategory(response.data[0].id);
        //             }
        //         }
        //     }).catch(error => {
        //         addAlert(JSON.stringify(error.message));
        //     });


        //     getUrl(`categories/${id}`).then(response => {
        //         console.log(response);
        //         if (response) {
        //             setCategoryChilds(response.data);
        //         }
        //     }).catch(error => {
        //         addAlert(JSON.stringify(error.message));
        //     });
        // }

        // getUrl('categories?level=1').then(categoryList => {
        //     if (isMountedRef.current) {
        //         setCategories(categoryList.data);
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // });

        getUrl(`products?exclude=collections&cat=${id}&page=${pageNumber ? pageNumber : 1}`).then(response => {
            if (response) {
                if (isMountedRef.current) {
                    let { current_page, last_page } = response.data.meta;
                    setCategoryProducts(response.data.data);
                    setTotal(last_page);
                    setPage(current_page ? current_page : 1);
                    setLoading(false);
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert]);


    const handlePaginationChange = (event, value) => {
        // setPage(value);
        setLoading(true);
        getUrl(`products?cat=${id}&page=${value}`).then(response => {
            if (response) {
                let { current_page, last_page } = response.data.meta;
                setCategoryProducts(response.data.data);
                setTotal(last_page);
                setPage(current_page ? current_page : 1);
                setLoading(false);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    };

    const handleCategoryChange = (event) => {
        history.push(`/category/${event.target.value}?page=1`);
    };

    return (
        <Box className={styles.root2} >
            <Box style={{ height: 50, backgroundColor:theme.palette.primary.main, display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', }}>
                <Link underline='none' onClick={() => history.goBack()} component="button" style={{zIndex:3}} >
                    <FiChevronLeft style={{color:theme.palette.white.main, marginLeft:15, fontSize:21}}/>
                </Link>
                <Typography style={{ textAlign: 'center', color: theme.palette.white.main, width:'100%', marginLeft:-34 }}>{t('item.products')}</Typography>
            </Box>

            <Box >
                <Container fixed className={styles.breadcrumbRoot}>
                    {/* { console.log("breadcrumb", breadcrumb) }
                    <Box>
                        { _.map(breadcrumb, (breadcrumbItem) => {
                            return (
                                <WebpImg key={breadcrumbItem.id} src={`/images/categories/category_banner-${breadcrumbItem.id}.png`} style={{ width: 'auto', maxWidth: '100%', maxHeight: '360px', marginTop:15 }} alt="categorybanner" disableFallback={true} />
                            )
                        }) }
                    </Box> */}
                

                    <Grid container spacing={1} justify="center">
                    {/* All Payment Must Be Make Payable To : OTTO SCIENCE HOLDINGS SDN BHD */}
                        <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <Grid item xs={12} sm={12} style={{ padding: '0 30px' }}>
                                {/* <List>
                                    <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ fontSize: 12 }}>
                                            <Link underline='none' color="primary" style={{ fontSize: '18px' }} to={`/categorylist`} component={RouterLink}>
                                                { t('title.categories') }
                                            </Link>
                                            {
                                                _.map(breadcrumb, (breadcrumbItem) => {
                                                    return (
                                                        <Link key={breadcrumbItem.id} underline='none' color="primary" style={{ fontSize: '18px' }} to={`/category/${breadcrumbItem.id}`} component={RouterLink}>
                                                            { breadcrumbItem[`name_${i18n.language}`] }
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </Breadcrumbs>
                                    </ListItem>
                                </List> */}
                                {/* <Hidden mdUp>
                                    <TextField style={{marginTop:'20px'}}
                                        id="outlined-select-currency"
                                        select
                                        value={category}
                                        onChange={handleCategoryChange}
                                        // variant="outlined"
                                        fullWidth
                                    >
                                        <MenuItem key="all" value="all">
                                            {i18n.language === 'cn' ? "所有" : "All"}
                                        </MenuItem>
                                        {
                                            _.size(categories) > 0 && categories !== null ?
                                                _.map(categories, (category) => {
                                                    const category_translate = _.split(category.cat_name, '|');
                                                    return (
                                                        <MenuItem key={category.id} value={category.id}>
                                                            {i18n.language === 'cn' ? (category_translate[1] ? category_translate[1] : category_translate[0]) : category_translate[0]}
                                                        </MenuItem>
                                                    )
                                                })
                                                :
                                                <MenuItem key={0} value=""></MenuItem>
                                        }
                                    </TextField>
                                </Hidden> */}
                                {/* {category === "all" ? null :
                                    <List>
                                        <Hidden only={['xs']}>
                                            <Typography style={{fontSize:15, color:'#a0a0a0'}}>{t('shop.relatedCategories')}</Typography>
                                            <Divider />
                                        </Hidden>
                                        { _.size(categoryChilds) > 0 ?
                                            <Box style={{marginTop:'20px'}}>
                                                <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                    <Grid container>
                                                        { _.map(categoryChilds, value => {
                                                            let title_translate = _.split(value.cat_name, '|');
                                                            return (
                                                                <Grid item key={value.id} style={{border: '1px solid #b5b5b5', borderRadius: 10, padding: '4px 8px', marginRight:10 }}>
                                                                    <Link underline='none' color="primary" href={`/category/${value.id}`} style={{ fontSize: 14 }}>
                                                                        {i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}
                                                                    </Link>
                                                                </Grid>
                                                            )
                                                        }) }
                                                    </Grid>
                                                </ListItem>
                                            </Box>
                                        : null }
                                    </List>
                                } */}
                            </Grid>
                        </Box>
                    </Grid>
                </Container>
                <Container className={styles.root}>
                    <Grid container>
                        <Grid xs={12} md={12} lg={12} item container spacing={1} justify="center">
                            {categoryProducts.length > 0 ?
                                <Grid item xs={12} sm={12} style={{ padding: '0 20px' }}>
                                    
                                    <Grid container spacing={1}>
                                        {_.map(categoryProducts, (product) => {
                                            // let starter = rank ? ((rank === null || product.rank.code > rank.code) ? true : false) : true;
                                            // let bvDisplay = product.type === 'rank' ? (starter ? product.bv_starter : product.bv_repurchase) : product.bv;
                                            // let priceDisplay = product.type === 'rank' ? (starter ? product.price_starter : product.price_repurchase) : product.sell_price;
                                            let bvDisplay = product.bv;
                                            let priceDisplay = product.sell_price;

                                            let showRetail = false;
                                            let discountPercent = 0;
                                            if(parseFloat(product.retail_price) > 0 && parseFloat(product.retail_price) > parseFloat(priceDisplay)) {
                                                showRetail = true;
                                                discountPercent = ((parseFloat(product.retail_price) - parseFloat(priceDisplay)) / parseFloat(product.retail_price)) * 100;
                                                discountPercent = Math.round(discountPercent);
                                            }
                                            const title_translate = _.split(product.title, '|');
                                            return (
                                                <Grid item xs={6} sm={4}  key={product.id} className={styles.cardContentRootStyle}>
                                                    <Link underline='none' to={`/item/${product.id}`} component={RouterLink}>
                                                        <Card variant="outlined" style={{ borderRadius: '15px', position: 'relative' }}>
                                                            <CardMedia>
                                                                <WebpImg containerStyle={{ height: 150, maxHeight: 150, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={product ? product.images_array[0] : ''} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 150 }} alt="product" />
                                                                { product.user_wishlist ? 
                                                                    <FavoriteIcon fontSize="small" style={{ color: 'red', position: 'absolute', right: 10 }} /> 
                                                                : null }
                                                            </CardMedia>
                                                            <CardContent>
                                                                <MuiThemeProvider theme={tooltipStyle}>
                                                                    <div>
                                                                        <MuiThemeProvider theme={muiTheme}>
                                                                            <Tooltip  title={i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}>
                                                                                <Typography variant="body2" color="textSecondary" className='txt_truncate2' >
                                                                                    {i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}
                                                                                </Typography>
                                                                            </Tooltip>
                                                                        </MuiThemeProvider>
                                                                    </div>
                                                                </MuiThemeProvider>
                                                                { showRetail ?
                                                                    <Typography align="center" variant="body2" style={{ fontSize: 10, paddingTop:'12px',fontWeight: 'bold', textDecoration: 'line-through' }}>
                                                                        <NumberFormat value={ product.retail_price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ i18n.language === 'cn' ? (product.currency_display_cn ? product.currency_display_cn : product.currency_display_en) : product.currency_display_en } `} />
                                                                    </Typography>
                                                                : <Typography style={{ height: 18 }}></Typography> }
                                                                <Typography align="center" variant="body1" color="secondary" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                                                    <NumberFormat value={ priceDisplay } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ i18n.language === 'cn' ? (product.currency_display_cn ? product.currency_display_cn : product.currency_display_en) : product.currency_display_en } `} />
                                                                </Typography>
                                                            </CardContent>
                                                            { showRetail ?
                                                                <Box position="absolute" top={0} right={0} style={{ backgroundColor: 'rgba(255,212,36,.9)' }} padding={1}>
                                                                    <Typography variant="caption" style={{ fontWeight: 'bold' }} color="error">{`${discountPercent}% ${t('general.discount')}`}</Typography>
                                                                </Box>
                                                            : null }
                                                            {/* <Box display="flex" alignItems="center" paddingY={.5} marginBottom={1.5} justifyContent="center" style={{ width: '100%', backgroundColor:'#f75757' }}>
                                                                {
                                                                    _.map(product.ipoint, (idata, key) => {
                                                                        if(idata.warehouse_id === 0){
                                                                            return (
                                                                                <Typography key={key} style={{ color: '#fff', fontSize:16,  }} variant="body2"><NumberFormat value={ parseFloat(idata.ipoint) } decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` i-Point`} /></Typography>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </Box> */}
                                                        </Card>
                                                    </Link>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>

                                    <Grid container spacing={1} justify="center" style={{marginTop:15}} >
                                        <Pagination count={total} shape="rounded" page={page}
                                            onChange={handlePaginationChange}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    component={RouterLink}
                                                    to={`/category/${id}${item.page === 1 ? '' : `?page=${item.page}`}`}
                                                    {...item}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                <Grid item container justify="center"><h1>{t('product.noResult')}</h1></Grid>
                            }
                            <div style={{textAlign:'center', marginTop:'20px'}}>License by MI</div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        // backgroundColor: 'light',
    },
    root: {
        padding: '10px 0px 30px 0px',
    },
    cardContentRootStyle: {
        padding: '10px !important',
    },
    Tooltip:{
        fontSize:21
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    },
}));

const tooltipStyle = createMuiTheme();
const muiTheme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "15px",
                color: "#fff",
                backgroundColor: "#000000bf"
            }
        }
    }
});
