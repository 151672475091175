import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from 'react-router-dom';
import { TextField, Button, Typography, Grid, Box, Divider, List, ListItem, Link, Snackbar, Card, CardContent, FormHelperText, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import _ from 'lodash';
import { putUrl } from '../helper/ApiAction';
import { FiChevronLeft } from 'react-icons/fi';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const INITIAL_SECURITY_PASSWORD_STATE = { old_password: '', security_password: '', security_password_confirmation: '' };

export default function ProfileSecurityPassword() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const [securityPasswordFormState, setSecurityPasswordFormState] = useState(INITIAL_SECURITY_PASSWORD_STATE);
    const [errorFormState, setErrorFormState] = useState({
        old_password: '', password: '', password_confirmation: '',
    });
    const [state, setState] = useState({
        snackbarShow: false,
        error: false,
        message: '',
    });

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const handleSecurityPasswordChange = (event) => {
        const name = event.target.name;
        setSecurityPasswordFormState({ ...securityPasswordFormState, [name]: event.target.value });
    }

    const updateSecurityPassword = () => {
        const { old_password, security_password, security_password_confirmation } = securityPasswordFormState;
        if(!old_password || !security_password || !security_password_confirmation) {
            setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequired') })
        } else {
            putUrl('user/security_password', securityPasswordFormState).then(result => {
                if(result.error) {
                    setState( state => ({ ...state, snackbarShow: true, error: true, message: result.message }) );
                    let allErrorField = [];
                    _.map(result.error, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setErrorFormState(allErrorField);
                } else {
                    setState( state => ({ ...state, snackbarShow: true, error: false, message: result.message }) );
                    setSecurityPasswordFormState(INITIAL_SECURITY_PASSWORD_STATE);
                    setErrorFormState({});
                }
            })
        }
    }

    return (
        <Box className={styles.root} style={{ background: theme.palette.white.mobileBkg, padding:'0 0 80px', minHeight: '100%'}}>
            <Box style={{ height: 50, backgroundColor:theme.palette.primary.main, display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', paddingTop:10}}>
                <Link underline='none' to="/setting" component={RouterLink} style={{zIndex:3}} >
                    <FiChevronLeft style={{color:theme.palette.white.main, marginLeft:15, fontSize:21}}/>
                </Link>
                <Typography style={{ textAlign: 'center', color: theme.palette.white.main, width:'100%', marginLeft:-34 }}>{t('profile.changeSecurityPassword')}</Typography>
            </Box>

        <Grid container spacing={1} justify="center" style={{padding:'20px 15px 0'}}>
            <Grid item xs={12}>
                <List className={ styles.listStyle } style={{ boxShadow:'none', backgroundColor:'transparent' }}>
                    <ListItem style={{display:'flex', justifyContent:'space-between', alignItem:'center'}}>
                        <Box style={{color:theme.palette.secondary.main}}>
                            <Typography variant="h6">
                                { t('profile.changeSecurityPassword') }
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                { t('profile.securityPasswordSubtitle') }
                            </Typography>
                        </Box>
                    </ListItem>
                    <Divider variant="middle" light />
                    <ListItem className={ styles.listPaddingVertical }>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} sm={3}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="caption" color="textSecondary">{ t('profile.currentSecurityPassword') }</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8}>
                                <TextField 
                                    error={errorFormState.old_password ? true : false}
                                    helperText={errorFormState.old_password}
                                    fullWidth={true} 
                                    variant="outlined" 
                                    size="small"
                                    type={passwordShown ? "text" : "password"}
                                    inputProps={{ name: 'old_password' }} 
                                    onChange={handleSecurityPasswordChange}
                                    value={securityPasswordFormState.old_password}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={togglePassword} onMouseDown={(event) => event.preventDefault() } edge="end">{passwordShown ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                                <FormHelperText>{ t('profile.currentSecurityPasswordInfo') }</FormHelperText>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem className={ styles.listPaddingVertical }>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} sm={3}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="caption" color="textSecondary">{ t('profile.newSecurityPassword') }</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8}>
                                <TextField 
                                    error={errorFormState.security_password  ? true : false}
                                    helperText={errorFormState.security_password }
                                    fullWidth={true} 
                                    variant="outlined" 
                                    size="small"
                                    type={passwordShown ? "text" : "password"}
                                    inputProps={{ name: 'security_password' }} 
                                    onChange={handleSecurityPasswordChange}
                                    value={securityPasswordFormState.security_password}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={togglePassword} onMouseDown={(event) => event.preventDefault() } edge="end">{passwordShown ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem className={ styles.listPaddingVertical }>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} sm={3}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="caption" color="textSecondary">{ t('profile.confirmSecurityPassword') }</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8}>
                                <TextField 
                                    error={errorFormState.security_password_confirmation ? true : false}
                                    helperText={errorFormState.security_password_confirmation}
                                    fullWidth={true} 
                                    variant="outlined" 
                                    size="small"
                                    type={passwordShown ? "text" : "password"}
                                    inputProps={{ name: 'security_password_confirmation' }} 
                                    onChange={handleSecurityPasswordChange}
                                    value={securityPasswordFormState.security_password_confirmation}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={togglePassword} onMouseDown={(event) => event.preventDefault() } edge="end">{passwordShown ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Box style={{display:'flex', justifyContent:'center', alignItems:'center', paddingTop:30}}>
                    <Button variant="contained" onClick={updateSecurityPassword} style={{width:500, maxWidth:'80%'}} color="secondary">{ t('button.confirm') }</Button>
                    </Box>
                </List>
                <Snackbar
                    open={state.snackbarShow}
                    autoHideDuration={2000}
                    onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                        <CardContent style={{ padding: 10 }}>
                            <Typography style={{ color: 'white' }}>{state.message}</Typography>
                        </CardContent>
                    </Card>
                </Snackbar>
            </Grid>
        </Grid>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
}));