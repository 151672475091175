import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { storeProfile } from '../actions';

import { Link as RouterLink } from 'react-router-dom';
import { TextField, Button, Typography, Grid, Box, Divider, List, ListItem, Link, Snackbar, Card, CardContent, FormHelperText } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import _ from 'lodash';
import { putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import TitleBarWhite from './Layouts/TitleBarWhite';

export default function ProfileBankInfo() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { bank_account, bank_holder_name, bank_name, bank_swift} = useSelector(state => state.user);
    const [profileFormState, setProfileFormState] = useState({ bank_account, bank_holder_name, bank_name, bank_swift });
    const [errorFormState, setErrorFormState] = useState({
        bank_account: '',bank_info: '', bank_swift: '', bank_holder_name: ''
    });
    const [state, setState] = useState({
        snackbarShow: false,
        error: false,
        message: '',
    });

    const [disabledState, setDisabledState] = useState({
        bank_holder_name: false
    });

    const {  setLoading } = useNotificationLoading();

    useEffect(() => {
        if(bank_holder_name){
            setDisabledState({ ...disabledState, bank_holder_name: true });
        }
        // eslint-disable-next-line
    }, [bank_holder_name]);

    const handleInfoChange = (event) => {
        const name = event.target.name;
        setProfileFormState({ ...profileFormState, [name]: event.target.value });
    }

    const updateInfo = () => {
        setLoading(true);
        putUrl('user/bank', profileFormState).then(result => {
            if(result.error) {
                setLoading(false);
                setState( state => ({ ...state, snackbarShow: true, error: true, message: result.message }) );
                let allErrorField = [];
                _.map(result.error, (errorItem, errorField) => {
                    allErrorField[errorField] = errorItem[0];
                })
                setErrorFormState(allErrorField);
            } else {
                setLoading(false);
                setState( state => ({ ...state, snackbarShow: true, error: false, message: result.message }) );
                dispatch(storeProfile(result.data));
                setErrorFormState({});
            }
        })
    }

    return (
        <Box className={styles.root} style={{ padding: '0 0 80px',}}>
            {/* <TitleBar title={t('profile.editBankInfo')} displayInfo={false} back /> */}
            <div style={{position:'fixed', width:600, maxWidth:'100%', zIndex:2}}>
                <TitleBarWhite height={0} title={t('profile.changePassword')} displayInfo={false} back />
            </div>

        <Grid container spacing={1} justify="center" style={{padding:'40px 15px 0'}}>
            <Grid item xs={12}>
                <List className={ styles.listStyle } style={{ boxShadow:'none', backgroundColor:'transparent' }}>
                    <ListItem style={{display:'flex', justifyContent:'space-between', alignItem:'center'}}>
                        <Box style={{color:theme.palette.primary.main}}>
                            <Typography variant="h6">
                                { t('profile.bankInfo') }
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                { t('profile.bankInfoSubtitle') }
                            </Typography>
                        </Box>
                    </ListItem>
                    <Divider variant="middle" light />
                    <ListItem className={ styles.listPaddingVertical }>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} sm={3}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="caption" color="textSecondary">{ t('profile.bankName') }</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8}>
                                <TextField 
                                    error={errorFormState.bank_name ? true : false}
                                    helperText={errorFormState.bank_name}
                                    fullWidth={true} 
                                    variant="outlined" 
                                    size="small"
                                    inputProps={{ name: 'bank_name' }} 
                                    onChange={handleInfoChange}
                                    value={profileFormState.bank_name ?? ""}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem className={ styles.listPaddingVertical }>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} sm={3}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="caption" color="textSecondary">{ t('profile.bankHolderName') }</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8}>
                            <TextField 
                                    error={errorFormState.bank_holder_name ? true : false}
                                    helperText={errorFormState.bank_holder_name}
                                    fullWidth={true} 
                                    variant="outlined" 
                                    size="small"
                                    inputProps={{ name: 'bank_holder_name', readOnly: disabledState.bank_holder_name }}
                                    onChange={handleInfoChange}
                                    value={profileFormState.bank_holder_name ?? ""}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem className={ styles.listPaddingVertical }>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} sm={3}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="caption" color="textSecondary">{ t('profile.bankAccount') }</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8}>
                            <TextField 
                                    error={errorFormState.bank_account ? true : false}
                                    helperText={errorFormState.bank_account}
                                    fullWidth={true} 
                                    variant="outlined" 
                                    size="small"
                                    inputProps={{ name: 'bank_account' }} 
                                    onChange={handleInfoChange}
                                    value={profileFormState.bank_account ?? ""}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem className={ styles.listPaddingVertical }>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} sm={3}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="caption" color="textSecondary">{ t('profile.bankSwift') }</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8}>
                                <TextField 
                                    error={errorFormState.bank_swift ? true : false}
                                    helperText={errorFormState.bank_swift}
                                    fullWidth={true} 
                                    variant="outlined" 
                                    size="small"
                                    inputProps={{ name: 'bank_swift' }} 
                                    onChange={handleInfoChange}
                                    value={profileFormState.bank_swift ??""}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Box style={{display:'flex', justifyContent:'center', alignItems:'center', paddingTop:30}}>
                    <Button variant="contained" onClick={updateInfo}  style={{width: 167, padding:'8px 9px', maxWidth: '80%', borderRadius: 48, }} color="secondary">{ t('button.update') }</Button>
                    </Box>
                </List>
                <Snackbar
                    open={state.snackbarShow}
                    autoHideDuration={2000}
                    onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                        <CardContent style={{ padding: 10 }}>
                            <Typography style={{ color: 'white' }}>{state.message}</Typography>
                        </CardContent>
                    </Card>
                </Snackbar>
            </Grid>
        </Grid>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
}));